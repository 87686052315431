import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Client from "./Navs/Index";

import AgentDashboard from "./Navs/AgentDashboard";
import Agent from "./Main/agent/index";
import NewAgent from "./Main/agent/newAgent";
import subAccount from "./Main/subaccount/index";
import newSubAccount from "./Main/subaccount/newSubAccount";

import Campains from "./Main/Campaign/Campains";
import Criterias from "./Main/criteria/Criteria";
import ViewCampain from "./Main/Campaign/ViewCampain";
import AddCampaign from "./Main/Campaign/AddCampaign";
import AddCriteria from "./Main/criteria/AddCriteria";
import EditBusinessRules from "./Main/Campaign/EditBusinessRules";
import AddBusinessRules from "./Main/Campaign/BusinessRules";
import EditCampaign from "./Main/Campaign/EditCampaign";

// Admins ->
import AdminDashboard from "./Navs/AdminDash";

//Client
import Users from "./Main/Users/index";
import NewUsers from "./Main/Users/NewUsers";
import EditUser from "./Main/Users/EditUser";
import ClientAdmin from "./Main/Client/Index";
import EditClientAdmin from "./Main/Client/EditClient";
import AddClient from "./Main/Client/AddClient";

// ROles
import Role from "./Main/Role/Role";
import AddRole from "./Main/Role/AddRole";
import EditRole from "./Main/Role/EditRole";
import ViewRole from "./Main/Role/ViewRole";

// Drill downs
import ReferredCustomers from "./Main/ClientDashbordDrillDowns/ReferredCustomers";
import ActiveReferredCustomers from "./Main/ClientDashbordDrillDowns/ActiveReferredCustomers";
import transactingReferredCustomers from "./Main/ClientDashbordDrillDowns/TransactingReferredCustomers";

// Audit
import Audit from "./Main/Audit/Index";

import LeaderBoard from "./Main/LeaderBoard";

//card
import Wallet from "../Wallet";
import ViewAllCampaignDetails from "../Wallet/components/ViewAllCampaignDetails";



// Participants
import Participants from "../Participants"

//profile
import Profile  from "../Profile";

export default class index extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* Admin -> Client */}
          <Route
            path="/marketer/admin-dashboard"
            exact
            component={(props) => (
              // <Can I="manage" a="AdminDashboard">
              <AdminDashboard {...props} />
              // </Can>
            )}
          />
          <Route path="/marketer/client" exact component={ClientAdmin} />
          <Route path="/marketer/add-client" exact component={AddClient} />
          <Route
            path="/marketer/edit-client"
            exact
            component={EditClientAdmin}
          />
          {/* Clients - admin */}
          <Route path="/marketer/users" exact component={Users} />
          <Route path="/marketer/add-user" exact component={NewUsers} />
          <Route path="/marketer/edit-user" exact component={EditUser} />
          {/*  */}
          <Route path="/marketer/roles" exact component={Role} />
          <Route path="/marketer/newRole" exact component={AddRole} />
          <Route path="/marketer/editRole" exact component={EditRole} />
          <Route path="/marketer/viewRole" exact component={ViewRole} />
          {/* ReferredCustomers */}
          <Route
            path="/marketer/ReferredCustomers"
            exact
            component={ReferredCustomers}
          />
          <Route
            path="/marketer/transactingReferredCustomers"
            exact
            component={transactingReferredCustomers}
          />{" "}
          <Route
            path="/marketer/activeReferredCustomers"
            exact
            component={ActiveReferredCustomers}
          />
          {/*
           */}
          {/*  */}
          <Route path="/marketer/audit" exact component={Audit} />
          <Route
            path="/marketer/campaigns"
            exact
            component={() => (
              // <Can I="manage" a="Campains">
              <Campains />
              // </Can>
            )}
          />{" "}
          <Route path="/marketer/add-campiagns" exact component={AddCampaign} />{" "}
          <Route
            path="/marketer/view-campiagn"
            exact
            component={(props) => (
              // <Can I="view" a="ViewCampain">
              <ViewCampain {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/card" 
            exact
            component={(props) => (
              // <Can I="view" a="ViewCampain">
              <Wallet {...props} />
              // </Can>
            )}
          />

          <Route
            path="/marketer/participants" 
            exact
            component={(props) => (
              // <Can I="view" a="ViewCampain">
              <Participants {...props} />
              // </Can>
            )}
          />
          
         <Route
            path="/marketer/card/allcampaigns"
            exact
            component={(props) => (
              // <Can I="manage" a="Campains">
              <ViewAllCampaignDetails {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/add-businessrules"
            exact
            component={AddBusinessRules}
          />{" "}
          <Route path="/marketer/leaderBoard" exact component={LeaderBoard} />{" "}
          <Route
            path="/marketer/edit-businessrules"
            exact
            component={(props) => <EditBusinessRules {...props} />}
          />{" "}
          <Route
            path="/marketer/edit-campiagns"
            exact
            component={EditCampaign}
          />
          <Route path="/marketer/criterias" exact component={Criterias} />
          <Route
            path="/marketer/add-criteria"
            exact
            component={AddCriteria}
          />{" "}
          <Route
            path="/marketer/agent/sub-agent"
            exact
            component={subAccount}
          />{" "}
          <Route path="/marketer/agent" exact component={Agent} />
          <Route
            path="/marketer/agent/dashboard"
            exact
            component={AgentDashboard}
          />{" "}
          <Route path="/marketer/new-agent" exact component={NewAgent} />{" "}
          <Route
            path="/marketer/agent/new-subAgent"
            exact
            component={newSubAccount}
          />{" "}
          {/* <Can I="manage" a="Client"> */}
          <Route path="/marketer" component={Client} />
          {/* </Can> */}
          <Route
            path="/manage/profile"
            exact
            component={(props) => (
              // <Can I="manage" a="ClientAdmin">
              <Profile {...props} />
              // </Can>
            )}
          />
          <Redirect from="/marketer" to="/marketer/dashboard" />
        </Switch>
      </Router>
    );
  }
}

import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
const ConfirmTransactionCampaign = ({ transactionValues, state }) => {
  return (
    <div className={parseInt(state?.campaignType) === 3 ? "transbasedtype py-2" : "transbasedtype-3 py-2"}>
      <div className="pb-1 col-6 pt-4 pb-2">
        <p className="h6">Transaction Based Type</p>
      </div>
      <div className="row ml-3">
        {transactionValues?.map((element, index) => (
          <>
            <div className="column mr-3 mb-2" key={index}>
              {" "}
              <Label className="C_labell">Transaction Type</Label>
              <select
                className="form-select form-control textField"
                id={`transIdentifier${index}`}
                disabled
              >
                <option value="">{element.transactionBasedType}</option>
              </select>
            </div>

            <div className="column mr-3 mb-2">
              {" "}
              <Label className="C_labell">Choose Reward Bands</Label>
              <select
                className="form-select form-control textField"
                id={`bandsIdentifier${index}`}
                disabled
              >
                <option value="">{element.transactionBands}</option>
              </select>
            </div>

            <div className="column mr-3 mb-2">
              <FormGroup className="">
                <Label className="C_labell">{parseInt(state?.campaignType) === 3 ? "Reward Referrer Amount" : "Reward Amount"}</Label>

                <Input
                 className="textField"
                  type="text"
                  name="referrer"
                  placeholder="Input Amount"
                  defaultValue={element.referrer}
                  disabled
                />
              </FormGroup>
            </div>
           {parseInt(state?.campaignType) === 3 && <div className="column mr-2 mb-2">
              <FormGroup className="">
                <Label className="C_labell">Reward Referred Amount</Label>

                <Input
                 className="textField"
                  type="text"
                  name="referred"
                  placeholder="Input Reward"
                  defaultValue={element.referred}
                  disabled
                />
              </FormGroup>
            </div>}
          </>
        ))}

      </div>
    </div>
  );
};

export default ConfirmTransactionCampaign;

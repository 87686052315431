import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { getCampaignSummary } from "../../../../redux/wallet/walletActions";
import ToolBar1 from "../../Client/Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Client/Navs/SideDrawer/SideDrawer";
import PaginationComponent from "react-reactstrap-pagination";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export class ViewAllCampaignDetails extends Component {
  state = {
    selectedPage: 1,
    pageSize: 10,
    sideDrawerOpen: true,
  };

  async componentDidMount() {
    //const state = this.props.location.state;
    let { pageSize, selectedPage } = this.state;

    await this.props.getCampaignSummary(selectedPage, pageSize);
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  handleSelected = (selectedPage) => {
    this.props.getCampaignSummary(selectedPage);
    this.setState({ selectedPage: selectedPage });
  };

  changePageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      this.props.getCampaignSummary(selectedPage, pageSize);
    });
  };

  render() {
  
    const { selectedPage, pageSize } = this.state;
    const { walletCampaignHistory } = this.props.wallet;
    
    return (
      <div className="wallet-container">
        <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />
        <main className="container-fluid px-5">
          <div className="contentBody">
            <div className="col-sm-12 my7 px-4">
              <div className="wallet_mininav d-flex">
                <Link to="/marketer/dashboard" className="wallet_mininav_link ">
                  {" "}
                  <small>Home</small>
                </Link>
                <Link to="/marketer/card" className="wallet_mininav_link">
                  <small className="wallet_mininav">
                    {" "}
                    <ArrowForwardIosIcon className="p-2" />{" "}
                  </small>
                </Link>
                <Link to="/marketer/card" className="wallet_mininav_link">
                  {" "}
                  <small className="wallet_mininav_link">Card Management</small>
                </Link>
              </div>

              <div className="wallet_header">
                <h4 className="wallet_heading pb-1">Card Management</h4>
              </div>

              <section
                className="wallet_details_section mt-3"
                id="#TransactionHistory"
              >
                <h6 className="wallet_sub_heading">Your Campaign</h6>
                <small>View all your campaigns</small>

                <div className="transaction_history_card my-3">
                  <div className="mx-3 mt-4">
                    <table className="wallet_table table table-responsive{-sm|-md|-lg|-xl}">
                      <thead className="wallet_table-header">
                        <tr className="">
                          <th>Campaign Title</th>
                          <th>Campaign Id</th>
                          <th>Amount Spent</th>
                          <th>Budget</th>
                          <th>Budget Threshold</th>
                        </tr>
                      </thead>
                      <tbody>
                        {walletCampaignHistory?.returnObjects.length > 0  ? (
                          walletCampaignHistory?.returnObjects.map((his) => {
                            return (
                              <tr key={his.id}>
                                <td>{his.title}</td>
                                <td>{his.campaignId}</td>
                                <td>{his.amountSpent}</td>
                                <td>{his.budget}</td>
                                <td>{his.threshold}</td>
                              </tr>
                            );
                          })
                        ) : (
                            <tr>
                            <td colSpan="6">
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "12pt",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                className=" mt-5 col-sm-6  "
                              >
                                <Loader
                                  type="RevolvingDot"
                                  color="#00425f"
                                  height={100}
                                  width={100}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end">
                      <small className="mr-2 mt-1">
                        {selectedPage} - {pageSize} of {walletCampaignHistory?.returnObjects?.length}{" "}
                        records{" "}
                      </small>
                      <PaginationComponent
                        totalItems={walletCampaignHistory?.totalRecordCount || 0}
                        pageSize={pageSize}
                        onSelect={this.handleSelected}
                        activePage={selectedPage}
                        className="wallet_pag"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet: state.wallet
});
const mapDispatchToProps = () => ({
  getCampaignSummary,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ViewAllCampaignDetails);

import React from "react";
import { createNotification } from "../../../../../../../dependency/UtilityFunctions";
import AcquisitionCampaign from "../acquisition/AcquisitionCampaign";
import TransactionCampaign from "../transactional/TransactionCampaign";

const AddCampaignType = ({
  handleFormData,
  types,
  state,
  prevStep,
  nextStep,
  onSaveFirstStep,
  validator,
  resetFields,
  goback,
  handleCheckBox,
  transactionBasedTypes,
  transactionRewardablebands,
  onCreateTransaction,
  handleMultiFormChange,
  transactionValues,
  addFormFields,
  removeFormFields
}) => {
  const onEnterPage = () => {
    onSaveFirstStep();
  };
  const onSubmit = () => {
    let campaignID = parseInt(state?.campaignType);
    let mapTransactionValues = transactionValues?.map(value => {
        if(!value.referrer || !value.transactionBands || !value.transactionBasedType){
          return false
        }else {
          return true
        }
      })   
    if (campaignID > 0) {
      if (validator.allValid()) {
          if(campaignID === 2 || campaignID === 3) {
              if(!mapTransactionValues.includes(false)) {
                validator.hideMessages();
                nextStep();
              }else {
                validator.showMessages();
              }
          }
        if(campaignID === 1){
          validator.hideMessages();
          nextStep();
        }

      } else {
        createNotification("error", "Kindly supply all fields.");
        validator.showMessages();
      }
    } else {
      createNotification("error", "choose campaign type or cancel");
    }
  };

  return (
    <div className="campaign_types" onClick={onEnterPage}>
      <div className="col-6">
        {" "}
        <select
          className="form-select form-control textField"
          onChange={(e) => { 
            handleFormData(e, "campaignType")
            resetFields();
            validator.fields = [];                                   
          }}
          onInput={(e) => handleFormData(e, "campaignType")}
        >
          <option value="">Choose Campaign Type</option>

          {types?.map(({ name, campaignTypeId }) => (
            <option
              value={campaignTypeId}
              key={campaignTypeId}
              name="campaignType"
            >
              {name}
            </option>
          ))}
        </select>
      </div>
      <div className="campaign-type-segment mb-3">
        {parseInt(state?.campaignType) === 1 && (
          <AcquisitionCampaign
            handleFormData={handleFormData}
            state={state}
            validator={validator}
            handleCheckBox={handleCheckBox}
          />
        )}
        {parseInt(state?.campaignType) === 2 && (
          <TransactionCampaign
            handleFormData={handleFormData}
            state={state}
            validator={validator}
            transactionBasedTypes={transactionBasedTypes}
            transactionRewardablebands={transactionRewardablebands}
            onCreateTransaction={onCreateTransaction}
            transactionValues={transactionValues}
            handleMultiFormChange={handleMultiFormChange}
            addFormFields={addFormFields}
            removeFormFields={removeFormFields}
          />
        )}

        {parseInt(state?.campaignType) === 3 && (
          <>
            <AcquisitionCampaign
              handleFormData={handleFormData}
              state={state}
              validator={validator}
              handleCheckBox={handleCheckBox}
            />
            <TransactionCampaign
              handleFormData={handleFormData}
              state={state}
              validator={validator}
              transactionBasedTypes={transactionBasedTypes}
              transactionRewardablebands={transactionRewardablebands}
              onCreateTransaction={onCreateTransaction}
              transactionValues={transactionValues}
              handleMultiFormChange={handleMultiFormChange}
              addFormFields={addFormFields}
              removeFormFields={removeFormFields}
            />
          </>
        )}
      </div>

      <span className="wizard_footer d-flex justify-content-between align-items-center mx-2">
        <small
          className="wizard_footer_back ml-1 py-2 text-info h6"
          onClick={prevStep}
        >
          Back
        </small>

        <div className="mt-3">
          <button
            className="btn btn-outline-info py-2 px-3 mr-3"
            onClick={goback}
          >
            Cancel
          </button>
          <button className="btn btn-info py-2" onClick={onSubmit}>
            Save and Continue
          </button>
        </div>
      </span>
    </div>
  );
};

export default AddCampaignType;

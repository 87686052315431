import React from "react";
import moment from "moment"

const TransactionCamapaignSummary = ({ state, transactionValues }) => {
  return (
    <div>
      <div className="row py-3 px-2">
        <div className="col-4">
          <div className="py-3">
            <span className="trans_refId_label">Campaign Title</span>
            <span className="trans_refId">{state.campainTitle}</span>
          </div>

          <div className="py-3">
            {/* <span className="h6">Budget Limit</span>
            <br></br> */}
            <span className="trans_refId_label">Budget Limit</span>
            <span className="trans_refId">{state.budgetLimit}</span>
          </div>
        </div>
        {/*  */}

        <div className="col-4 pb-4">
            <span className="trans_refId_label">URL</span>
          <div className="py-3">
            <span className="trans_refId_url">{state.campainBarner}</span>
          </div>

          <div className="py-3">
            <span className="trans_refId_label">Budget Threshold</span>
            <span className="trans_refId">{state.budgetThreshold}</span>
          </div>

         
        </div>

        <div className="col-4 pb-4">
          <div className="py-3">
            <span className="trans_refId_label">Date</span>
            <span className="trans_refId">
              {moment(state.startDate).format("MMM DD, YYYY")} -{" "}
              {moment(state.endDate).format("MMM DD, YYYY")}
            </span>
          </div>
          <div className="py-3">
            <span></span>
            <span className="trans_refId_label pb-1">Reward Interval</span>
            <span className="trans_refId">{state.rewardInterval || 1}</span>
          </div>
        </div>
      </div>

      <div className="transbasedtype-3 py-4">
        <div className="pb-1 pt-4 pb-2">
          <p className="h6">Transaction Based Type</p>
        </div>
        <div className="row ml-1">
          {transactionValues?.map((element, index) => (
            <>
              <div className="column mr-3 mb-2" key={index}>
                <div className="py-3">
                  <span></span>
                  <span className="trans_refId_label pb-1">
                    Transaction Type
                  </span>
                  <span className="trans_refId">
                    {element.transactionBasedType}
                  </span>
                </div>
              </div>

              <div className="column mr-3 mb-2">
                {" "}
                <div className="py-3">
                  <span></span>
                  <span className="trans_refId_label pb-1">Reward Bands</span>
                  <span className="trans_refId">
                    {element.transactionBands}
                  </span>
                </div>
              </div>

              <div className="column mr-3 mb-2">
                <div className="py-3">
                  <span></span>
                  <span className="trans_refId_label pb-1">
                    Reward Amount
                  </span>
                  <span className="trans_refId">{element.referrer}</span>
                </div>
              </div>
             {parseInt(state?.campaignType) === 3 && <div className="column mr-2 mb-2">
                <div className="py-3">
                  <span></span>
                  <span className="trans_refId_label pb-1">
                    Referred Amount
                  </span>
                  <span className="trans_refId">{element.referred}</span>
                </div>
              </div>}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransactionCamapaignSummary;

import React, { Component } from "react";
import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

import { withRouter } from "react-router-dom";
import { compose } from "redux";
import * as moment from "moment";

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Loader from "react-loader-spinner";
import PaginationComponent from "react-reactstrap-pagination";

import {
  getCampaigns,
  updateCampaign,
  deleteCampaign,
  addCampaign,
  updateCampaignStatus,
  setData,
} from "../../../../../redux/campain/campaignActions";
import {
  LogoutNow,
  getPermissions,
  getActiveClient,
} from "../../../../../dependency/UtilityFunctions";
import "./campains.css";
import CampaignActionButton from "./CampaignActionButton";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
class Campains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      email: "",
      password: "",
      err: "",
      loading: false,
      sideDrawerOpen: true,
      anchorEl: null,
      open: null,
      selectedPage: 1,
      pageSize: 10,
      selectedCampaign: [],
      searchTerm: "",
      permission: getPermissions(),
      activator: false,
      campaignStatus: [
        {
          CampaignStatuId: "1",
          Name: "In Draft",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 2,
          Name: "Activated",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 3,
          Name: "Not Activated",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 4,
          Name: "Stopped",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 5,
          Name: "Expired",
          DateCreated: new Date(),
        },
      ],
      cs: [
        "",
        { color: "badge-secondary", name: "In Draft" },
        { color: "badge-info", name: "Activated" },
        { color: "badge-warning", name: "Not Activated" },
        { color: "badge-danger", name: "Stopped" },
        { color: "badge-danger", name: "Expired" },
      ],
    };
  }

  componentDidMount() {
    let r = this.state.permission.filter((v) => v === "MKT_03");
    if (r.length > 0) {
      this.setState({ activator: true });
    }
    let { pageSize, selectedPage } = this.state;
    let altCid = getActiveClient();

    if (!altCid) {
      this.createNotification("error", "No assigned client");

      return;
    }
    this.props.getCampaigns(altCid.id, selectedPage, pageSize);
  }
  toggle = () => this.setState({dropdownOpen: !this.state.dropdownOpen});
  getClientId = (id) => {
    let { pageSize, selectedPage } = this.state;
    this.props.getCampaigns(id, selectedPage, pageSize).then((res) => {});
  };
  handleClick = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };
  searchUpdated = (term) => {
    this.setState({ searchTerm: term });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  logOut = () => {
    LogoutNow();
  };

  goTo = (url, campaign) => {
    this.setState({
      selectedCampaign: campaign,
    });

    this.props.history.push({
      pathname: url,
      // search: "?query=abc",
      state: { campaign },
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.login();
    }
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  handleSelected = (selectedPage) => {
    let { pageSize } = this.state;
    let altCid = getActiveClient();
    this.props.getCampaigns(altCid.id, selectedPage, pageSize);
    this.setState({ selectedPage: selectedPage });
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };
  addCampain = () => {
    this.props.history.push("add-campiagns");
  };
  changePageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      let altCid = getActiveClient();
      this.props.getCampaigns(altCid.id, selectedPage, pageSize);
    });
  };
  updateCamStatus = (id, status) => {
    let altCid = getActiveClient();
    
    if (status === 2) {
      const newDetails = {
        campaignId: id,
        statusId: 1,
        clientId: altCid.id,
      };
      this.props.updateCampaignStatus(newDetails, this.props.getCampaigns);
    } else {
      const newDetails = {
        campaignId: id,
        statusId: 2,
        clientId: altCid.id,
      };
      this.props.updateCampaignStatus(newDetails, this.props.getCampaigns);
    }
  };

  render() {
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <div className="sidebarContentContainer">
            <SideDrawer show={this.state.sideDrawerOpen} />
            <main
              style={{
                flex: "1 1 0%",
              }}
            >
              <ToolBar1
                sendClientId={this.getClientId}
                drawerToggleClickHandler={this.drawerToggleClickHandler}
              />

              <div className="contentBody  px-5">
                <div className="col-sm-12 my7">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h4
                      style={{
                        fontWeight: "bold",
                        paddingTop: "10px",
                        marginRight: "68%",
                      }}
                      className=""
                    >
                      Campaigns
                    </h4>

                    <div className="btnCOntainer d-flex justify-content-end w-100">
                      <div className="header33  " onClick={this.addCampain}>
                        Add Campaign
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" subacc-table container-fluid py-2 px-0">
                  <div className="paginations d-flex justify-content-end w-100  ">
                  <h6 className="pt-2 pr-1 text-info">Page size:</h6>
                  <>
                  <div className="d-flex" >
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} direction={this.props.direction}>
                          <DropdownToggle className="btn-outline btn-outline-info bg-light text-info" caret>{this.state.pageSize}</DropdownToggle>
                          <DropdownMenu {...this.props.args}>
                            <DropdownItem  onClick={() => this.changePageSize(10)}>
                              10
                            </DropdownItem>
                            <DropdownItem  onClick={() => this.changePageSize(25)}>
                              25
                            </DropdownItem>
                            <DropdownItem onClick={() => this.changePageSize(50)}>
                              50
                            </DropdownItem>
                            <DropdownItem onClick={() => this.changePageSize(100)}>
                              100
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    {/* <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <Tooltip title="Page size" aria-label="Page size">
                            <div
                              style={{
                                background: "#FFF",
                                padding: "5pt",
                                borderRadius: "5px",
                                boxShadow: "2px 2px 2px 2px #efefef",
                                height: "36px",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              {...bindTrigger(popupState)}
                            >
                              {this.state.pageSize}
                            </div>
                          </Tooltip>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem
                              onMouseDown={popupState.close}
                              onClick={() => this.changePageSize(10)}
                            >
                              10
                            </MenuItem>
                            <MenuItem
                              onMouseDown={popupState.close}
                              onClick={() => this.changePageSize(25)}
                            >
                              25
                            </MenuItem>
                            <MenuItem
                              onMouseDown={popupState.close}
                              onClick={() => this.changePageSize(50)}
                            >
                              50
                            </MenuItem>
                            <MenuItem
                              onMouseDown={popupState.close}
                              onClick={() => this.changePageSize(100)}
                            >
                              100
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState> */}
                    {/* <PaginationComponent
                      // totalItems={this.props.totalRecordCount}
                      // pageSize={this.state.pageSize}
                      // onSelect={this.handleSelected}
                      // activePage={this.state.selectedPage}
                      // maxPaginationNumbers={2}
                    /> */}
                    </>
                  </div>
                  <table className="table table-borderless table-hover ">
                    <thead>
                      <tr>
                        <th>Campaign</th>
                        <th>Campaign Type</th>
                        {/* <th>Campaign ID</th> */}
                        <th>Status</th>
                        <th>Added</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="clearTable">
                      {!this.props.loading ? (
                        this.state.searchTerm === "" ? (
                          this.props?.campains?.map((campain) => {
                            return (
                              <tr key={campain.campaignId}>
                                <td>{`${campain.title}`}</td>
                                <td>{campain.campaignTypeName}</td>
                                {/* <td>{campain.campaignId}</td> */}
                                <td>
                                  <span
                                    className={
                                      "badge " +
                                      this.state.cs[campain.statusId].color
                                    }
                                  >
                                    {this.state.cs[campain.statusId].name}
                                  </span>
                                </td>
                                <td>
                                  {moment(campain.createdOn).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>

                                <td>
                                  <CampaignActionButton goTo={this.goTo} campaigns={campain} updateCamStatus={this.updateCamStatus} />
                                  
                                  {/* <PopupState
                                    variant="popover"
                                    popupId="demo-popup-menu"
                                  >
                                    {(popupState) => (
                                      <React.Fragment>
                                        <button
                                          type="button"
                                          className="btn "
                                          variant="contained"
                                          {...bindTrigger(popupState)}
                                          style={{
                                            color: "#00b8de",
                                            borderColor: "#00b8de",
                                          }}
                                        >
                                          Action
                                        </button>
                                        <Menu {...bindMenu(popupState)}>
                                          <MenuItem
                                            onMouseDown={popupState.close}
                                            onClick={() =>
                                              this.goTo(
                                                "view-campiagn",
                                                campain
                                              )
                                            }
                                          >
                                            View
                                          </MenuItem>

                                          <MenuItem
                                            onMouseDown={popupState.close}
                                            onClick={() =>
                                              this.goTo(
                                                "edit-campiagns",
                                                campain
                                              )
                                            }
                                          >
                                            Edit
                                          </MenuItem>
                                          <MenuItem
                                            onMouseDown={popupState.close}
                                            onClick={() =>
                                              this.updateCamStatus(
                                                campain.id,
                                                campain.statusId
                                              )
                                            }
                                          >
                                            {campain.statusId === 2
                                              ? "Deactivate"
                                              : "Activate"}
                                          </MenuItem>
                                        </Menu>
                                      </React.Fragment>
                                    )}
                                  </PopupState> */}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          this.props?.campaigns?.map((campain) => {
                            return (
                              <tr key={campain.campaignId}>
                                <td>{campain.title}</td>
                                <td>
                                  {
                                    this.props.campaignType[
                                      campain.campaignTypeId
                                    ].Name
                                  }
                                </td>
                                <td>{campain.campaignId}</td>
                                <td>
                                  <span
                                    className={
                                      "badge " +
                                      this.state.cs[campain.statusId].color
                                    }
                                  >
                                    {this.state.cs[campain.statusId].name}
                                  </span>
                                </td>
                                <td>
                                  {moment(campain.dateOfCreation).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>

                                <td>
                                  <PopupState
                                    variant="popover"
                                    popupId="demo-popup-menu"
                                  >
                                    {(popupState) => (
                                      <React.Fragment>
                                        <button
                                          type="button"
                                          className="btn "
                                          variant="contained"
                                          {...bindTrigger(popupState)}
                                          style={{
                                            color: "#00b8de",
                                            borderColor: "#00b8de",
                                          }}
                                        >
                                          Action
                                        </button>
                                        <Menu {...bindMenu(popupState)}>
                                          {this.state.activator ? (
                                            <MenuItem
                                              onMouseDown={popupState.close}
                                              onClick={() =>
                                                this.goTo(
                                                  "view-campiagn",
                                                  campain
                                                )
                                              }
                                            >
                                              View
                                            </MenuItem>
                                          ) : null}

                                          <MenuItem
                                            onMouseDown={popupState.close}
                                            onClick={() =>
                                              this.goTo(
                                                "edit-campiagns",
                                                campain
                                              )
                                            }
                                          >
                                            Edit
                                          </MenuItem>
                                          <MenuItem
                                            onMouseDown={popupState.close}
                                          >
                                            Delete
                                          </MenuItem>
                                        </Menu>
                                      </React.Fragment>
                                    )}
                                  </PopupState>
                                </td>
                              </tr>
                            );
                          })
                        )
                      ) : !this.props.getCampaignErr ? (
                        <tr>
                          <td colSpan="6">
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "12pt",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              className=" mt-5 col-sm-6  "
                            >
                              <Loader
                                type="RevolvingDot"
                                color="#00425f"
                                height={100}
                                width={100}
                              />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="6">
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "12pt",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              className=" mt-5 col-sm-6  "
                            >
                              {this.props.getCampaignErr}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="paginations  d-flex justify-content-end w-100  ">
                    {/* <PaginationComponent
                      totalItems={this.props.totalRecordCount}
                      pageSize={this.state.pageSize}
                      onSelect={this.handleSelected}
                      activePage={this.state.selectedPage}
                      maxPaginationNumbers={2}
                    /> */}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  campains: state.campain.campaigns,
  totalRecordCount: state.campain.totalRecordCount,
  campaignType: state.campain.campaignType,
  loading: state.campain.loading,
  getCampaignErr: state.campain.getCampaignErr,
});
const mapDispatchToProps = () => ({
  addCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaigns,
  updateCampaignStatus,
  setData,
});

// export default connect(mapStateToProps, mapDispatchToProps())(Campains);
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps())
)(Campains);

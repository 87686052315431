import React from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import DatePicker from "react-date-picker";
import { createNotification } from "../../../../../../../dependency/UtilityFunctions";

const AddCampaignDetails = ({
  validator,
  nextStep,
  handleFormData,
  state,
  handleEndChange,
  handleStartChange,
  handleRewardExpiryDateChange,
  goback
}) => {
  const submitFormstate = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      if (parseInt(state.budgetThreshold) > parseInt(state.budgetLimit)) {
        createNotification("error","threshold cannot be bigger than budget");
      } else {
        validator.hideMessages();
        nextStep();
      }
    } else {
      createNotification("error", "Kindly supply all fields.");
      validator.showMessages();
    }
  };

  return (
    <>
      <Form onSubmit={submitFormstate}>
        <div className="col-6">
          <FormGroup className="">
            <Label className="C_labell">Campaign Title</Label>

            <Input
              type="text"
              className="textField"
              name="campainTitle"
              placeholder="Enter Campaign Title"
              defaultValue={state.campainTitle}
              onChange={(e) =>handleFormData(e,"campainTitle")}
              autoComplete="off"
            />
            <div className="col-sm-4"></div>
            <div className="text-danger p-0 f8">
              {validator.message(
                "campaign Title",
                state.campainTitle,
                "required"
              )}
            </div>
          </FormGroup>
          <FormGroup className="">
            <Label for="description" className="C_labell">
              Description
            </Label>
            <Input
              className="textArea textField"
              type="textarea"
              name="campainDescription"
              id="description"
              placeholder="Short text..."
              defaultValue={state.campainDescription}
              onChange={(e) =>handleFormData(e,"campainDescription")}
              autoComplete="off"
            />
            <div className="col-sm-4"></div>
            <div className=" text-danger p-0 f8">
              {validator.message(
                "campaign Description",
                state.campainDescription,
                "required"
              )}
            </div>
          </FormGroup>
          <FormGroup className="">
            <Label for="exampleSelect" className="C_labell">
              Campaign Banner
            </Label>
            <Input
              className="textField"
              type="text"
              name="campainBarner"
              id="campainBarner"
              placeholder="Url"
              defaultValue={state.campainBarner}
              onChange={e => handleFormData(e, "campainBarner")}
              autoComplete="off"
            />
            <div className=""></div>
            <div className="col-sm-7 text-danger p-0 f8">
              {validator.message("campaignBanner", state.campainBarner, "required")}
            </div>
          </FormGroup>
          <div className="d-flex">
            <FormGroup className="">
              <Label for="" className="C_labell">
                Start Date
              </Label>

              <DatePicker
                calendarClassName="textField"
                clearAriaLabel
                clearIcon=""
                selected={state.startDate}
                onChange={handleStartChange}
                value={state.startDate}
                minDate={new Date() }
              />
              <div className=""></div>
              <div className=" text-danger p-0 f8">
                {validator.message("startDate", state.startDate, "required")}
              </div>
            </FormGroup>
            <FormGroup className="">
              <Label for="" className="C_labell">
                End Date
              </Label>
              <DatePicker
                calendarClassName="textField"
                clearAriaLabel
                clearIcon=""
                selected={state.endDate}
                onChange={handleEndChange}
                value={state.endDate}
                minDate={state.startDate}
              />
              <div className=""></div>
              <div className="text-danger p-0 f8">
                {validator.message("endDate", state.endDate, "required")}
              </div>
            </FormGroup>
          </div>
          <FormGroup className="">
            <Label for="exampleSelect" className="C_labell">
                Reward Interval
            </Label>
            <Input
              className="textField"
              type="number"
              name="rewardInterval"
              id="rewardInterval"
              min= "1"
              max="100"
              placeholder="Reward Interval"
              defaultValue={state.rewardInterval}
              onChange={e => handleFormData(e, "rewardInterval")}
              autoComplete="off"
            />
            {/* <div className=""></div>
            <div className="col-sm-7 text-danger p-0 f8">
              {validator.message("rewardInterval", state.rewardInterval, "required")}
            </div> */}
          </FormGroup>
          <FormGroup className="">
            <Label for="exampleEmail" className="C_labell">
              Budget Limit
            </Label>

            <Input
              className="textField"
              type="number"
              name="budgetLimit"
              id="campainTitle"
              placeholder="Enter Budget Limit"
              onChange={e => handleFormData(e,"budgetLimit")}
              value={state.budgetLimit}
              autoComplete="off"
            />
            <div className=""></div>
            <div className=" text-danger p-0 f8">
              {validator.message(
                "Budget Limit",
                state.budgetLimit,
                "required|numeric"
              )}
            </div>
          </FormGroup>

          <FormGroup className="">
            <Label for="exampleEmail" className="C_labell">
              Budget Threshold
            </Label>

            <Input
              className="textField"
              type="number"
              name="budgetThreshold"
              id="campainTitle"
              placeholder="Enter Budget Threshold"
              onChange={e =>handleFormData(e,"budgetThreshold")}
              value={state.budgetThreshold}
              autoComplete="off"
            />
            <div className=""></div>
            <div className=" text-danger p-0 f8">
              {validator.message(
                "Budget Threshold",
                state.budgetThreshold,
                "required|numeric"
              )}
            </div>
          </FormGroup>

          <FormGroup className="">
            <Label for="exampleEmail" className="C_labell">
              Reward Expiry Date
            </Label>

            <br />

            <DatePicker
                calendarClassName="textField"
                clearAriaLabel
                clearIcon=""
                selected={state.rewardExpiryDate}
                onChange={handleRewardExpiryDateChange}
                value={state.rewardExpiryDate}
                minDate={state.endDate}
              />
              <div className=""></div>
              <div className="text-danger p-0 f8">
                {validator.message("endDate", state.endDate, "required")}
              </div>
          </FormGroup>
        </div>

        <div className="wizard_footer d-flex justify-content-end mx-1">
          <button
            className="mt-3 mb-5 mr-4 p-1 btn btn-outline-info py-2 px-3"
            onClick={goback}
          >
            Cancel
          </button>
          <button className="mt-3 mb-5 mr-4 btn btn-info py-2">
            Save and Continue
          </button>
        </div>
      </Form>
    </>
  );
};

export default AddCampaignDetails;

import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { LogoutNow } from "../../../../../dependency/UtilityFunctions";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import profileImg from "../../../Profile/images/profile-avater.svg";

import "./ToolBar.css";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    color: "#707070",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: "30rem",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    // marginLeft:"20pt"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "150",
    /* width: 100%; */
    /* background: #521751; */
    height: "56px",
    // background: "#ffffff 0% 0% no-repeat padding-box",
    // boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    fontFamily: "Poppins",
    width: "100%",
    marginLeft: "0",
    // paddingLeft: "20rem"
  },
  searchprops: {
    width: "60%",
    height: "40px",
    textAlign: "left",
    fontFamily: "Poppins",
    letterSpacing: "0",
    color: "#afafaf",
    opacity: 1,
    border: "1pt solid #AFAFAF",
    borderRadius: "25px",
  },
}));

export default function Toolbar1() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    LogoutNow();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          {/* <Badge badgeContent={11} color="secondary"> */}
          {/* <NotificationsIcon /> */}
          {/* </Badge> */}
        </IconButton>
        <p>Notifications</p>
      </MenuItem>

      <MenuItem>
        <IconButton>
          <Grid container justify="center" alignItems="center">
            <Avatar
              alt="Remy Sharp"
              src="https://res.cloudinary.com/mentor-africa-project/image/upload/v1603502333/Pngtree_user_vector_avatar_4830521.png"
              className={classes.avatar}
            />
          </Grid>
        </IconButton>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
          {/* <AccountCircle /> */}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow + " " + classes.toolbar} style={{}}>
      <AppBar
        position="static"
        style={{
          background: "white",
          color: "#00B8DE",
          // width: "100%"
          // marginLeft: "10%",
          paddingLeft: "15rem",
        }}
      >
        <Toolbar>
          <div className="col-sm-8 dearchContainer d-none">
            <div className="form-group has-search inputWithIcon">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search (Campaign Client User)"
                className="text-field-props-search has-search form-control paddit"
                autoComplete="off"
              />
              <span className="form-control-feedback2">
                <SearchIcon />
              </span>
            </div>
          </div>

          <div
            className={classes.search + " " + classes.searchprops}
            style={{ display: "none" }}
          >
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "Search..." }}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <NotificationsIcon className="d-none" />
            </IconButton>
            <div
              style={{
                display: "flex",
                flex: 1,
                marginRight: "10pt",
                marginLeft: "0pt",
                borderRight: "2pt solid #AFAFAF",
                height: "40px",
              }}
            ></div>
            <Grid container justify="center" alignItems="center">
              <Avatar
                alt="Remy Sharp"
                src={profileImg}
                className={classes.avatar}
              />

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={LogoutNow}
                // onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <ExitToAppIcon
                  style={{
                    color: "#AFAFAF",
                    background: " no-repeat padding-box",
                  }}
                />
              </IconButton>
            </Grid>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

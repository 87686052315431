import React, { Component } from "react";
import { connect } from "react-redux";

import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";

import { NotificationManager } from "react-notifications";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import HTTP from "../../../../../redux/http-trips";

import {
  getCampaigns,
  updateCampaign,
  deleteCampaign,
  addCampaign,
} from "../../../../../redux/campain/campaignActions";
import { getClientList } from "../../../../../redux/client/clientActions";
import { Label } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

import "./addcampain.css";

class AddCampains extends Component {
  constructor() {
    super();
    this.state = {
      sideDrawerOpen: true,
      name: "",
      id: "",
      getPermissionsErr: "",
      permissions: [],
      loading: true,
      selectedOption: null,
      open: false,
      agreed: false,
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    const state = this.props.location.state;

    let { name, id } = state.role;

    this.setState({
      name,
      id,
    });
    this.getPermissions(id);
  }
  getPermissions = (id) => {
    let url = `/api/RoleMgmt/Get/${id}`;
    HTTP.get(url)
      .then((res) => {
        let { permissions } = res.data.payload;
        this.setState({
          permissions,
          loading: false,
        });
      })
      .catch((err) => {
        if (err.response) {
          let { data } = err.response;
          this.setState({
            getPermissionsErr: data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,",
          });
          this.createNotification(
            "error",
            data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,"
          );
        }
      });
  };

  handleAgreed = () => {
    this.toggleComfirm();
    this.setState(
      {
        agreed: true,
      },
      () => {
        this.send(true);
      }
    );
  };
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };

  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  render() {
    let count = 0;
    return (
      <>
        <div>
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          <main
            className="container-fluid px-5 "
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7 row">
                <div style={{ display: "flex", flexDirection: "row" }}></div>
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    marginRight: "60%",
                  }}
                  className=""
                >
                  Role
                </h4>
                <div className="btnCOntainer">
                  <div
                    className="header33"
                    onClick={() => this.props.history.push("roles")}
                  >
                    Back
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="column">
                  <div className="col-sm-10 mx-0 py-0">
                    <div className="card my-4 singleHeight">
                      <div className="card-header headerWHite">Role Name</div>
                      <div className="card-body">
                        <Label for="exampleEmail" className="col-sm-12 labell ">
                          {this.state.name}
                        </Label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-10 mx-0 py-0">
                    <div className="card my-4 singleHeight">
                      <div className="card-header headerWHite">Permissions</div>
                      <div className="card-body">
                        <Label for="exampleEmail" className="col-sm-12 labell ">
                          {this.state.name}
                          <Grid>
                            <List dense className="row">
                              {!this.state.loading ? (
                                this.state.permissions.map((value, key) => {
                                  return (
                                    <ListItem key={key}>
                                      {/* <ListItem key={key} button> */}
                                      <span
                                        style={{ paddingRight: "15px" }}
                                      >{`${++count}`}</span>
                                      <ListItemText
                                        id={count}
                                        primary={`${value.description} `}
                                      />
                                      <ListItemSecondaryAction>
                                        {value.totalRewardValue}
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  );
                                })
                              ) : !this.state.getPermissionsErr ? (
                                <div>Loading...</div>
                              ) : (
                                <div> {this.state.getPermissionsErr}</div>
                              )}
                            </List>
                          </Grid>
                        </Label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  campains: state.campain.campaigns,
  campaignType: state.campain.campaignType,
  campaignStatus: state.campain.campaignStatus,
  clients: state.client.clients,
});
const mapDispatchToProps = () => ({
  addCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaigns,
  getClientList,
});

export default connect(mapStateToProps, mapDispatchToProps())(AddCampains);

import { GET_PARTICIPANTS, GET_PARTICIPANT, SET_LOADING, PARTICIPANTS_ANALYTICS, PARTICIPANT_ANALYTICS } from "../types";

const initialState = {
  participants: [],
  participant: [],
  totalParticipantCount: 0,
  totalParticipantsCount: 0,
  participantAnalytics: {},
  participantsAnalytics: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PARTICIPANTS:
      return {
        ...state,
        participants: action?.payload,
        totalParticipantsCount: action?.payload?.totalRecordCount,
        loading: false,
      };
    case GET_PARTICIPANT:
      return {
        ...state,
        participant: action?.payload?.payload?.returnObjects,
        totalParticipantCount: action?.payload?.totalCount,
        loading: false,
      };
    case PARTICIPANT_ANALYTICS:
      return {
        ...state,
        participantAnalytics: action?.payload?.payload,
        loading: false,
      };
    case PARTICIPANTS_ANALYTICS:
      return {
        ...state,
        participantsAnalytics: action?.payload?.payload,
        loading: false,
      };
      case SET_LOADING:
      return {
        ...state,
        loading: action?.payload
      };

    default:
      return state;
  }
}

import React from "react";
import activeStep from "../../../images/active_indicator.svg";
import stepIndicator2 from "../../../images/step-indicator2.svg";
import stepIndicator3 from "../../../images/step-indicator3.svg";
import completed from "../../../images/step-indicator-complete.svg";

const StepIndicator = ({ step }) => {
  return (
    <div className="wizard-header pl-3 mx-1">
      <div className="row p-3">
        <div className="column">
          <img
            src={step === 1 ? activeStep : step === 2 ? completed : completed}
            alt="active"
          />
          <div className="pt-3 pl-1">
            <h6 className="campaign_stepI_header"> Campaign Details</h6>
            <small className="campaign_stepI_des">
              Complete campaign details
            </small>
          </div>
        </div>
        <div class="column">
          {" "}
          <img
            src={
              step === 3
                ? activeStep
                : step === 4
                ? completed
                : step === 5
                ? completed
                : stepIndicator2
            }
            alt="step 2"
          />
          <div className="pt-3 pl-1">
            <h6 className="campaign_stepI_header">Campaign Type</h6>
            <small className="campaign_stepI_des">
              Set up the campaign logic
            </small>
          </div>
        </div>
        <div class="column">
          <img src={step === 5 ? completed : stepIndicator3} alt="step 3" />
          <div className="pt-3 pl-1">
            <h6 className="campaign_stepI_header">Confirm Details</h6>
            <small className="campaign_stepI_des">
              Review and activate campaign
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepIndicator;

import React, { Component } from "react";
import { connect } from "react-redux";
import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import SimpleReactValidator from "simple-react-validator";
import {
  getCampaigns,
  updateCampaign,
  deleteCampaign,
  addCampaign,
  getCampaignTypes,
  addAquisitionCampaign,
  getCampaignRewardableTransactionTypes,
  getRewardBands,
  addTransactionCampaign,
} from "../../../../../redux/campain/campaignActions";
import {
  createNotification,
  getClients,
  getActiveClient,
} from "../../../../../dependency/UtilityFunctions";
import AddCampaignDetails from "./components/steps/AddCampaignDetails";
import StepIndicator from "./components/steps/layout/StepIndicator";
import ConfirmCampaignDetails from "./components/steps/ConfirmCampaignDetails";
import AddCampaignType from "./components/steps/AddCampaignType";
import ConfirmCampaignType from "./components/steps/ConfirmCampaignType";
import ConfirmAllCampaignDetails from "./components/steps/ConfirmAllCampaignDetails";
import "./addCampaign.css";
import moment from "moment";

export class AddCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideDrawerOpen: true,
      clientIds: getClients(),
      clientId: "",
      step: 1,
      selectedDate: "",
      startDate: new Date(),
      endDate: new Date(),
      rewardExpiryDate: new Date(),
      campainTitle: "",
      campainDescription: "",
      campainBarner: "",
      campaignType: "",
      campaignBillsPayment: "",
      rewardAmount: "",
      rewardStyle: "",
      budgetLimit: "",
      budgetThreshold: "",
      onSubmitStep1Btn: false,
      onSubmitStep2Btn: false,
      referred: "",
      referrer: "",
      refererChecked: false,
      referredChecked: false,
      transactionBands: "",
      transactionBasedType: "",
      rewardInterval: "",
      transactionValues: [
        {
          transactionBands: "",
          transactionBasedType: "",
          transactionBandsId: "",
          transactionBasedTypeId: "",
          referred: "",
          referrer: "",
        },
      ],
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    this.props.getCampaignTypes();
    this.props.getRewardBands();
    this.props.getCampaignRewardableTransactionTypes();
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  nextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  prevStep = () => {
    this.setState({ step: this.state.step + -1 });
  };

  handleMultiFormChange = (i, e) => {
    const transactionBasedType = document.getElementById(`transIdentifier${i}`);
    const transactionBands = document.getElementById(`bandsIdentifier${i}`);
    const trans =
      transactionBasedType.options[transactionBasedType.selectedIndex].label;
    const bands =
      transactionBands.options[transactionBands.selectedIndex].label;
    const transId =
      transactionBasedType.options[transactionBasedType.selectedIndex].getAttribute('data-key');
    const bandsId =
    transactionBands.options[transactionBands.selectedIndex].getAttribute('data-key');          //e.target.options[e.target.name].value, e.target.name
    let transactionValues = this.state.transactionValues;
    transactionValues[i][e.target.name] = e.target.value;
    transactionValues[i].transactionBasedType = trans !== "Choose Type" ? trans : null ;
    transactionValues[i].transactionBands = bands !== "Choose Bands"? bands : null ;
    transactionValues[i].transactionBasedTypeId = transId;
    transactionValues[i].transactionBandsId = bandsId;
    this.setState({ transactionValues });
  };

  addFormFields = () => {
    this.setState({
      transactionValues: [
        ...this.state.transactionValues,
        {
          transactionBands: "",
          transactionBasedType: "",
          referred: "",
          referrer: "",
        },
      ],
    });
  };

  removeFormFields = index => { 
    // this.validator.hideMessages();
    let fields =  [...this.state.transactionValues];
    fields.splice(index, 1)
    this.setState({transactionValues:[ ...fields]});
  };

  handleCheckBox = (field) => {
    this.setState((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  handleInputData = (e, input) => {
    const { value } = e.target;

    this.setState((previousState) => ({
      ...previousState,
      [input]: value,
    }));
  };

  isDateToday = (date) =>{
    const otherDate = new Date(date);
    const todayDate = new Date();
  
    if (
      otherDate.getDate() === todayDate.getDate() &&
      otherDate.getMonth() === todayDate.getMonth() &&
      otherDate.getYear() === todayDate.getYear()
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleStartDateChange = (date) => {
    if (new Date(date).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)) {
      this.setState({
        startDate: date,
      });
      if(new Date(date).setHours(0,0,0,0) > new Date(this.state.endDate).setHours(0,0,0,0)) {
        this.setState({
          startDate: new Date(),
        });
        createNotification("error", "Start date cannot be greater than end date")
      }
    } else createNotification("error", "Start date must be greater or equal to today");
  };

  handleEndDateChange = (date) => {
    if (new Date(date).setHours(0,0,0,0) >= new Date(this.state.startDate).setHours(0,0,0,0)) {
      this.setState({
        endDate: date,
      });

      if(new Date(date).setHours(0,0,0,0) > new Date(this.state.rewardExpiryDate).setHours(0,0,0,0)) {
        this.setState({
          rewardExpiryDate: date,
        });
      };

    } else createNotification("error", "End date should be greater or equal to start date");
  };

  handleRewardExpiryDateChange = (date) => {
    if (new Date(date).setHours(0,0,0,0) >= new Date(this.state.endDate).setHours(0,0,0,0)) {
      this.setState({
        rewardExpiryDate: date,
      });
    } else createNotification("error", "Expiry date should be greater or equal to end date");
  };

  onSubmitStep1 = () => {
    const { referrer, referred, referredChecked, refererChecked } = this.state;
    let mapVal = [];
    mapVal = this.state.transactionValues.map((each) => {
      return {
        refereeAmount: each.referred ? parseInt(each.referred) : 0,
        referrerAmount: each.referrer ? parseInt(each.referrer) : 0,
        rewardBandId: each.transactionBandsId ? each.transactionBandsId : "00000000-0000-0000-0000-000000000000",
        rewardBandTitle: each.transactionBands,
        rewardableTransactionTypeId: each.transactionBasedTypeId ? parseInt(each.transactionBasedTypeId) : 0,
        transactionTypeName: each.transactionBasedType,
      };
    });

    let clientIds = getClients();
    let activeClient = getActiveClient();
    const newDetails = {
      title: this.state.campainTitle,
      description: this.state.campainDescription,
      campaignTypeId: parseInt(this.state.campaignType),
      //statusId: parseInt(this.state.campaignType),
      budget: parseInt(this.state.budgetLimit),
      imageUrl: this.state.campainBarner,
      startDate: moment(this.state.startDate).format(),
      endDate:  moment(this.state.endDate).format(),
      threshold: parseInt(this.state.budgetThreshold),
      individualTransaction: 0,
      totalTransaction: 0,
      rewardInterval: Number(this.state.rewardInterval) || 1,
      // clientId: clientIds[0].id,
      clientId: activeClient?.id,
      referrerAmount: referrer ? parseInt(referrer) : 0,
      refereeAmount: referred ? parseInt(referred) : 0,
      isPaidReferee: referredChecked,
      isPaidReferrer: refererChecked,
      reclaimRewardsOn: moment(this.state.rewardExpiryDate).format() ,
      transactionCampaignRewards: mapVal
    };
    this.props.addCampaign(newDetails).then(this.props.history.push("/marketer/campaigns"));
  };

  onSaveFirstStep = () => {
    this.setState({ onSubmitStep1Btn: true });
  };

  onSaveSecondStep = () => {
    this.setState({ onSubmitStep2Btn: true, onSubmitStep1Btn: true });
  };

  onCreateAcquisitionCampaign = () => {
    const { referrer, referred, referredChecked, refererChecked } = this.state;
    const newDetails = {
      campaignId: this.props.campaignID,
      referrerAmount: referrer ? parseInt(referrer) : 0,
      refereeAmount: referred ? parseInt(referred) : 0,
      isPaidReferee: referredChecked,
      isPaidReferrer: refererChecked,
    };
    this.props
      .addAquisitionCampaign(newDetails)
      .then(this.props.history.push("/marketer/campaigns"));
  };
  goback = () => {
    this.props.history.push("/marketer/campaigns");
  };

  onCreateTransaction = () => {
    let mapVal = [];
    mapVal = this.state.transactionValues.map((each) => {
      return {
        rewardableTransactionTypeId: parseInt(each.transactionBasedTypeId),
        transactionTypeName: each.transactionBasedType,
        rewardBandId: each.transactionBandsId,
        rewardBandTitle: each.transactionBands,
        referrerAmount: parseInt(each.referrer),
        transactionCampaignId: this.props.campaignID,
        refereeAmount: parseInt(each.referred),
      };
    });

    const newDetails = {
      id: this.props.campaignID,
      isPaidReferee: true,
      isPaidReferrer: true,
      campaignId: this.props.campaignID,
      transactionCampaignRewards: mapVal,
    };

    this.props.addTransactionCampaign(newDetails)
    .then(this.props.history.push("/marketer/campaigns"));
  };

  resetFields = () => {
    this.validator.hideMessages();
    this.setState({
      referred: "",
      referrer: "",
      refererChecked: false,
      referredChecked: false,
      transactionBands: "",
      transactionBasedType: "",
      transactionValues: [
        {
          transactionBands: "",
          transactionBasedType: "",
          transactionBandsId: "",
          transactionBasedTypeId: "",
          referred: "",
          referrer: "",
        },
      ],
    })
  }

  render() {
    const { campaignType, transactionBasedTypes, transactionRewardablebands } =
      this.props;
    const campaignSubHeading = "Enter the campaign details below";
    
    switch (this.state.step) {
      case 1:
        return (
          <div>
            <ToolBar1
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <SideDrawer show={this.state.sideDrawerOpen} />
            <main className="container-fluid px-5">
              <div className="contentBody">
                <div className="col-sm-12 my7 px-4">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h4 className="profile_heading">Create New Campaign</h4>
                  </div>
                  <p>
                    <small>
                      {campaignSubHeading}
                    </small>
                  </p>

                  <div className="campaign_card_container p-3">
                    <StepIndicator step={this.state.step} />
                    <div className="campaign_details_header pt-3">
                      <div className="pl-3">
                        <h5 className="profile_heading">Campaign Details</h5>

                        <p>
                          <span>{campaignSubHeading}</span>
                        </p>
                      </div>
                      <AddCampaignDetails
                        validator={this.validator}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleFormData={this.handleInputData}
                        handleEndChange={this.handleEndDateChange}
                        handleStartChange={this.handleStartDateChange}
                        handleRewardExpiryDateChange={this.handleRewardExpiryDateChange}
                        selectedDate={this.state.selectedDate}
                        state={this.state}
                        goback={this.goback}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        );
      case 2:
        return (
          <div>
            <ToolBar1
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <SideDrawer show={this.state.sideDrawerOpen} />
            <main className="container-fluid px-5">
              <div className="contentBody">
                <div className="col-sm-12 my7 px-4">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h4 className="profile_heading">Create New Campaign</h4>
                  </div>
                  <p>
                    <small>
                    {campaignSubHeading}
                    </small>
                  </p>

                  <div className="campaign_card_container p-3">
                    <StepIndicator
                      onSubmitStep1Btn={this.state.onSubmitStep1Btn}
                      step={this.state.step}
                    />
                    <div className="campaign_details_header pt-3">
                      <div className="pl-3">
                        <h5 className="profile_heading">Campaign Details</h5>

                        <p>
                          <span>{campaignSubHeading}</span>
                        </p>
                      </div>
                      <ConfirmCampaignDetails
                        validator={this.validator}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        state={this.state}
                        onSaveFirstStep={this.onSaveFirstStep}
                        goback={this.goback}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        );

      case 3:
        return (
          <div>
            <ToolBar1
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <SideDrawer show={this.state.sideDrawerOpen} />
            <main className="container-fluid px-5">
              <div className="contentBody">
                <div className="col-sm-12 my7 px-4">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h4 className="profile_heading">Create New Campaign</h4>
                  </div>
                  <p>
                    <small>
                    {campaignSubHeading}
                    </small>
                  </p>

                  <div className="campaign_card_container p-3">
                    <StepIndicator
                      onSubmitStep1Btn={this.state.onSubmitStep1Btn}
                      step={this.state.step}
                    />
                    <div className="campaign_details_header pt-3">
                      <div className="pl-3">
                        <h5 className="profile_heading">Campaign Details</h5>

                        <p>
                          <span>{campaignSubHeading}</span>
                        </p>
                      </div>
                      <AddCampaignType
                        resetFields={this.resetFields}
                        validator={this.validator}
                        types={campaignType}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        state={this.state}
                        campaignType={this.state.campaignType}
                        handleFormData={this.handleInputData}
                        onSaveFirstStep={this.onSaveFirstStep}
                        onSubmitStep1={this.onSubmitStep1}
                        goback={this.goback}
                        handleCheckBox={this.handleCheckBox}
                        transactionBasedTypes={transactionBasedTypes}
                        transactionRewardablebands={transactionRewardablebands}
                        handleMultiFormChange={this.handleMultiFormChange}
                        transactionValues={this.state.transactionValues}
                        onCreateTransaction={this.onCreateTransaction}
                        addFormFields={this.addFormFields}
                        removeFormFields={this.removeFormFields}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        );

      case 4:
        return (
          <div>
            <ToolBar1
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <SideDrawer show={this.state.sideDrawerOpen} />
            <main className="container-fluid px-5">
              <div className="contentBody">
                <div className="col-sm-12 my7 px-4">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h4 className="profile_heading">Create New Campaign</h4>
                  </div>
                  <p>
                    <small>
                    {campaignSubHeading}
                    </small>
                  </p>

                  <div className="campaign_card_container p-3">
                    <StepIndicator
                      onSubmitStep2Btn={this.state.onSubmitStep2Btn}
                      step={this.state.step}
                    />
                    <div className="campaign_details_header pt-3">
                      <div className="pl-3">
                        <h5 className="profile_heading">Campaign Details</h5>

                        <p>
                          <span>{campaignSubHeading}w</span>
                        </p>
                      </div>
                      <ConfirmCampaignType
                        validator={this.validator}
                        types={campaignType}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        state={this.state}
                        campaignType={this.state.campaignType}
                        handleFormData={this.handleInputData}
                        onSaveSecondStep={this.onSaveSecondStep}
                        addAquisitionCampaign={this.onCreateAcquisitionCampaign}
                        goback={this.goback}
                        transactionValues={this.state.transactionValues}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        );
      case 5:
        return (
          <div>
            <ToolBar1
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <SideDrawer show={this.state.sideDrawerOpen} />
            <main className="container-fluid px-5">
              <div className="contentBody">
                <div className="col-sm-12 my7 px-4">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h4 className="profile_heading">Create New Campaign</h4>
                  </div>
                  <p>
                    <small>
                      Confirm the campaign details below{" "}
                    </small>
                  </p>

                  <div className="campaign_card_container p-3">
                    <StepIndicator
                      onSubmitStep2Btn={this.state.onSubmitStep2Btn}
                      step={this.state.step}
                    />
                    <div className="campaign_details_header pt-3">
                      <div className="pl-3">
                        <h5 className="profile_heading">
                          Confirm Campaign Details
                        </h5>
                      </div>
                      <ConfirmAllCampaignDetails
                        validator={this.validator}
                        types={campaignType}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        state={this.state}
                        campaignType={this.state.campaignType}
                        handleFormData={this.handleInputData}
                        onSaveSecondStep={this.onSaveSecondStep}
                        submitCampaignDetails= {this.onSubmitStep1}
                        addAquisitionCampaign={this.onCreateAcquisitionCampaign}
                        onCreateTransaction={this.onCreateTransaction}
                        transactionValues={this.state.transactionValues}
                        goback={this.goback}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        );
      default:
        return (
          <div>
            <ToolBar1
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <SideDrawer show={this.state.sideDrawerOpen} />
          </div>
        );
    }
  }
}

const mapStateToProps = (state) => ({
  campains: state.campain.campaigns,
  campaignType: state.campain.campaignType,
  campaignStatus: state.campain.campaignStatus,
  clients: state.client.clients,
  campaignID: state.campain.campaignID,
  transactionBasedTypes: state.campain.transactionBasedTypes,
  transactionRewardablebands: state.campain.transactionRewardablebands,
});
const mapDispatchToProps = () => ({
  addCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaigns,
  getCampaignTypes,
  addAquisitionCampaign,
  getRewardBands,
  getCampaignRewardableTransactionTypes,
  addTransactionCampaign,
});

export default connect(mapStateToProps, mapDispatchToProps())(AddCampaign);

import React from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import DatePicker from "react-date-picker";

const ConfirmCampaignDetails = ({ nextStep, prevStep, state, onSaveFirstStep, goback }) => {

  const submitFormData = (e) => {
    e.preventDefault();
    onSaveFirstStep();
    nextStep();
  };

  return (
    <>
      <Form onSubmit={submitFormData}>
        <div className="col-6">
          <FormGroup className="">
            <Label className="C_labell">Campaign Title</Label>

            <Input
              className="textField"
              type="text"
              name="campainTitle"
              value={state.campainTitle}
              disabled
            />
          </FormGroup>
          <FormGroup className="">
            <Label for="description" className="C_labell">
              Description
            </Label>
            <Input
              className="textArea textField"
              type="textarea"
              name="campainDescription"
              id="description"
              value={state.campainDescription}
              disabled
            />
          </FormGroup>
          <FormGroup className="">
            <Label for="exampleSelect" className="C_labell">
              Campaign Banner
            </Label>
            <Input
              className="textField"
              type="text"
              name="campainBarner"
              id="campainBarner"
              defaultValue={state.campainBarner}
              disabled
            />
          </FormGroup>
          <div className="d-flex">
            <FormGroup className="">
              <Label for="" className="C_labell">
                Start Date
              </Label>

              <DatePicker
                calendarClassName="textField"
                clearAriaLabel
                clearIcon=""
                value={state.startDate}
                disabled
              />
            </FormGroup>
            <FormGroup className="">
              <Label for="" className="C_labell">
                End Date
              </Label>
              <DatePicker
                calendarClassName="textField"
                clearAriaLabel
                clearIcon=""
                value={state.endDate}
                disabled
              />
            </FormGroup>
          </div>
          <FormGroup className="">
            <Label for="exampleEmail" className="C_labell">
               Reward Interval
            </Label>

            <Input
              className="textField"
              type="number"
              name="budgetLimit"
              id="campainTitle"
              disabled
              value={state.rewardInterval || 1}
            />
          </FormGroup>
          <FormGroup className="">
            <Label for="exampleEmail" className="C_labell">
              Budget Limit
            </Label>

            <Input
              className="textField"
              type="number"
              name="budgetLimit"
              id="campainTitle"
              disabled
              value={state.budgetLimit}
            />
          </FormGroup>

          <FormGroup className="">
            <Label for="exampleEmail" className="C_labell">
              Budget Threshold
            </Label>

            <Input
              className="textField"
              type="number"
              name="budgetThreshold"
              id="campainTitle"
              value={state.budgetThreshold}
              disabled
            />
          </FormGroup>
        </div>

        <div className="wizard_footer d-flex justify-content-between mx-1">
        <small
          className="wizard_footer_back ml-1 py-2 mt-3 mb-5 p-1 text-info h6"
          onClick={prevStep}
        >
          Back
        </small>
          <button className="mt-3 mb-5 mr-4 btn btn-info py-2">
            Save and Continue
          </button>
        </div>
      </Form>
    </>
  );
};

export default ConfirmCampaignDetails;

import React, { Component } from "react";
import ToolBar1 from "../Navs/ToolBar/ToolBar1";
import SideDrawer from "../Navs/SideDrawer/SideDrawer";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

import { getDashLeaderBoardDetails } from "../../../../redux/dash/dashActions";
import {
  LogoutNow,
  getActiveClient,
} from "../../../../dependency/UtilityFunctions";
import "./Campaign/campains.css";
class LeaderBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sideDrawerOpen: true,
      anchorEl: null,
      open: null,
      selectedPage: 1,
      pageSize: 10,
      selectedCampaign: [],
      leaderboard: [],

      cs: [
        "",
        { color: "badge-secondary", name: "In Draft" },
        { color: "badge-info", name: "Activated" },
        { color: "badge-warning", name: "Not Activated" },
        { color: "badge-danger", name: "Stopped" },
        { color: "badge-danger", name: "Expired" },
      ],
    };
  }

  componentDidMount() {
    let altCid = getActiveClient();
    this.props.getDashLeaderBoardDetails(altCid.id).then((res) => {
      let { status } = res;
      if (status !== 200) {
        this.createNotification("error", res.data.description);
        this.setState({
          getLeadershipErr: res.data.description,
        });
      } else {
        this.setState({ leaderboard: res.data.payload.returnObjects });
      }
    });
    // let { pageSize, selectedPage } = this.state;
    // let altCid = getActiveClient();
    // this.props.getCampaigns(altCid.id, selectedPage, pageSize);
  }
  getClientId = (id) => {
    let { pageSize, selectedPage } = this.state;
    this.props.getCampaigns(id, selectedPage, pageSize).then((res) => {});
  };
  handleClick = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };
  searchUpdated = (term) => {
    this.setState({ searchTerm: term });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  logOut = () => {
    LogoutNow();
  };

  goTo = (url, campaign) => {
    this.setState({
      selectedCampaign: campaign,
    });
    this.props.history.push({
      pathname: url,
      // search: "?query=abc",
      state: { campaign },
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.login();
    }
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  handleSelected = (selectedPage) => {
    let { pageSize } = this.state;
    let altCid = getActiveClient();
    this.props.getCampaigns(altCid.id, selectedPage, pageSize);
    this.setState({ selectedPage: selectedPage });
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };
  addCampain = () => {
    this.props.history.push("add-campiagns");
  };
  changePageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      let altCid = getActiveClient();
      this.props.getCampaigns(altCid.id, selectedPage, pageSize);
    });
  };
  render() {
    // let backDrop = "";
    // if (this.state.sideDrawerOpen) {
    //   backDrop = <BackDrop backDropClickHandler={this.backDropClickHandler} />;
    // }

    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <div className="sidebarContentContainer">
            <SideDrawer show={this.state.sideDrawerOpen} />
            <main
              style={{
                flex: "1 1 0%",
              }}
            >
              <ToolBar1
                sendClientId={this.getClientId}
                drawerToggleClickHandler={this.drawerToggleClickHandler}
              />

              <div className="contentBody  px-5">
                <div className="col-sm-12 my7">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h4
                      style={{
                        fontWeight: "bold",
                        paddingTop: "10px",
                        marginRight: "68%",
                      }}
                      className=""
                    >
                      LeaderBoard
                    </h4>
                    <div className="btnCOntainer">
                      {/* <div
                        style={{
                          background: "#FFF",
                          padding: "5pt",
                          borderRadius: "5px",
                          boxShadow: "2px 2px 2px 2px #efefef",
                          height: "36px",
                          marginRight: "10px",
                        }}
                      >
                        <FilterListIcon />
                      </div> */}
                      <div
                        className="header33"
                        onClick={() => this.props.history.push("dashboard")}
                      >
                        Back
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" subacc-table container-fluid py-2">
                  {/* <div className="paginations d-flex justify-content-end w-100  ">
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <Tooltip title="Page size" aria-label="Page size">
                            <div
                              style={{
                                background: "#FFF",
                                padding: "5pt",
                                borderRadius: "5px",
                                boxShadow: "2px 2px 2px 2px #efefef",
                                height: "36px",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              {...bindTrigger(popupState)}
                            >
                              {this.state.pageSize}
                            </div>
                          </Tooltip>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem
                              onMouseDown={popupState.close}
                              onClick={() => this.changePageSize(10)}
                            >
                              10
                            </MenuItem>
                            <MenuItem
                              onMouseDown={popupState.close}
                              onClick={() => this.changePageSize(25)}
                            >
                              25
                            </MenuItem>
                            <MenuItem
                              onMouseDown={popupState.close}
                              onClick={() => this.changePageSize(50)}
                            >
                              50
                            </MenuItem>
                            <MenuItem
                              onMouseDown={popupState.close}
                              onClick={() => this.changePageSize(100)}
                            >
                              100
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                    <PaginationComponent
                      totalItems={this.props.totalRecordCount}
                      pageSize={this.state.pageSize}
                      onSelect={this.handleSelected}
                      activePage={this.state.selectedPage}
                      maxPaginationNumbers={2}
                    />
                  </div> */}
                  <table className="table table-borderless  ">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        <th>Reward value</th>
                      </tr>
                    </thead>
                    <tbody id="clearTable">
                      {this.state.leaderboard.map((v, key) => {
                        return (
                          <tr key={key}>
                            <td>{key+=1}</td>
                            <td>{`${v.firstName} ${v.lastName}`}</td>
                            <td>{v.totalRewardValue}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* <div className="paginations  d-flex justify-content-end w-100  ">
                    <PaginationComponent
                      totalItems={this.props.totalRecordCount}
                      pageSize={this.state.pageSize}
                      onSelect={this.handleSelected}
                      activePage={this.state.selectedPage}
                      maxPaginationNumbers={2}
                    />
                  </div> */}
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getLeadershipErr: state.dash.getLeadershipErr,
  l_loading: state.dash.l_loading,
});
const mapDispatchToProps = () => ({
  getDashLeaderBoardDetails,
});

export default connect(mapStateToProps, mapDispatchToProps())(LeaderBoard);

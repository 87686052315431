import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ToolBar1 from "../Client/Navs/ToolBar/ToolBar1";
import SideDrawer from "../Client/Navs/SideDrawer/SideDrawer";
import walletIcon from "./images/Wallet.svg";
import arrowIcon from "./images/ArrowUpRight.svg";
import "./wallet.css";
import ProgressBar from "./components/ProgressBar";
import TransactionHistory from "./components/TransactionHistory";
import {
  getWalletBalance,
  getWalletHistory,
  getCampaignSummary,
  getAllWalletHistory,
  setData,
  mockCampaignsData
} from "../../../redux/wallet/walletActions";
import { createNotification } from "../../../dependency/UtilityFunctions";
import moment from "moment";

export class index extends Component {
  myRef = React.createRef();

  state = {
    selectedPage: 1,
    pageSize: 10,
    sideDrawerOpen: true,
    searchField: "",
    startDate: new Date(),
    endDate: new Date(),
    showDateRange: false,
    types: [10, 20, 50, 100],
    loader: false,
    mockCampaignsData: []
  };

  async componentDidMount() {
   
    let { selectedPage, pageSize } = this.state;
    const [searchfield, startDate, endDate] = ["", "", ""];
    
    localStorage.setItem("activeB", "card");

    await this.props.getWalletBalance();
    await this.props.getAllWalletHistory();
    await this.props.getCampaignSummary();
    await this.props.getWalletHistory(selectedPage, pageSize, searchfield, startDate, endDate);
    this.setState({mockCampaignsData: mockCampaignsData})
  }

  
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  executeScroll = () => this.myRef.current.scrollIntoView();

  handleSelected = async (selectedPage) => {
    const [searchfield, startDate, endDate] = ["", "", ""];
    await this.props.getWalletHistory(selectedPage, this.state.pageSize,searchfield, startDate, endDate);
    this.setState({ selectedPage: selectedPage });
  };

  changePageSize = async (value) => {
    const [searchfield, startDate, endDate] = ["", "", ""];
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      this.props.getWalletHistory(selectedPage, pageSize, searchfield, startDate, endDate);
    });
  };
  
  onChange = (e) => {
    this.setState({
      searchField: e.target.value,
    });
    
  };

  handleStartDateChange = (date) => {
    if (date > new Date()) {
      this.setState({
        startDate: new Date(),
      });
      createNotification(
        "error",
        "Invalid Date"
      );
    } else if (date <= new Date()) {
      this.setState({
        startDate: date,
      });
    }
  };

  handleEndDateChange = (date) => {
    if (date > new Date()) {
      this.setState({
        endDate: new Date(),
      });
      createNotification(
        "error",
        "Date cannot be greater than today"
      );
    }else if (date <= new Date()) {
      this.setState({
        endDate: date,
      });
    };
  };

  onMouseLeaveField = async (e) => {
    const { startDate, endDate, selectedPage, pageSize, searchField } = this.state;

    let formattedStartDate =  moment(startDate).format("YYYY-MM-DD");
    let formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    let search = "";

    if (startDate <= endDate) {
      if (startDate && endDate && searchField) {
        this.props.getWalletHistory(
          selectedPage,
          pageSize,
          searchField,
          formattedStartDate,
          formattedEndDate
        );
      } else if(startDate && endDate){
        await this.props.getWalletHistory(
          selectedPage,
          pageSize,
          search,
          formattedStartDate,
          formattedEndDate
        );
    
      }else {
        createNotification("error", "Invalid date range");
      }

      } else {
        createNotification("error", "End date must be greater than start date");
      }
  };

  onClickDateFilter = () => {
    const { selectedPage, pageSize, searchField, startDate, endDate, showDateRange } = this.state; 
    const [searchfield, startDateNull, endDateNull] = ["", "", ""];
    
    this.setState({ showDateRange: !this.state.showDateRange});
    if(searchField){
      this.props.getWalletHistory(selectedPage, pageSize, searchField, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));
    }

    if(showDateRange){
     this.props.getWalletHistory(selectedPage, pageSize, searchfield, startDateNull, endDateNull);
    }
  };

  onSearchField = (e) => {
    const { selectedPage, pageSize, searchField} = this.state;
    const [startDate, endDate] = ["", "", ""];
    if (searchField !== "" && searchField.length > 2) {
      this.props.getWalletHistory(
        selectedPage,
        pageSize,
        searchField,
        startDate,
        endDate
      );
      
    } else if(searchField === "") {
      const [searchfield, startDate, endDate] = ["", "", ""];
       this.props.getWalletHistory(selectedPage, pageSize, searchfield, startDate, endDate);
    }
    
  }
  render() {
    const {
      walletBalance,
      walletCampaignHistory,
      walletHistory,
      totalHistoryCount,
      allWalletHistory
    } = this.props.wallet;
   
    const { selectedPage, pageSize } = this.state;
    
    return (
      <div className="wallet-container">
        <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />

        <main className="container-fluid px-5">
          <div className="contentBody">
            <div className="col-sm-12 my7 px-4">
              <div className="wallet_mininav d-flex">
                <Link to="/marketer/dashboard" className="wallet_mininav_link ">
                  {" "}
                  <small>Home</small>
                </Link>
                <Link to="/marketer/card" className="wallet_mininav_link">
                  <small className="wallet_mininav">
                    {" "}
                    <ArrowForwardIosIcon className="p-2" />{" "}
                  </small>
                </Link>
                <Link to="/marketer/card" className="wallet_mininav_link">
                  {" "}
                  <small className="wallet_mininav_link">Card Management</small>
                </Link>
              </div>
              <div className="wallet_header">
                <h4 className="wallet_heading pb-1">Card Management</h4>
                {/* <small className="profile_heading">last logged in</small> */}
              </div>
              <section>
                <h6 className="py-3 wallet_sub_heading">Your card</h6>
                <div className="wallet_content row p-4 mr-5 ml-1 pt-3">
                  <img
                    src={walletIcon}
                    alt="walletIcon"
                    className="wallet-img mb-5 pb-4"
                  />
                  <span className="col-sm-9">
                    <span className="wallet_bal_heading">Card Balance</span>
                    <h4 className="pb-4">
                      {walletBalance?.balance && <span>N</span>}
                      {!isNaN(walletBalance?.balance/100) ? (walletBalance?.balance/100)
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                        "_ _ _   _ _ _"}{" "}
                    </h4>

                    <span
                      className="wallet_bal_link mt-5"
                      onClick={this.executeScroll}
                    >
                      View Transactions <img src={arrowIcon} alt="walletIcon" />
                    </span>
                  </span>
                </div>
              </section>
            {walletCampaignHistory &&
              <section className="campaign_section mt-3">
                <h6 className="wallet_sub_heading pt-3">Your Campaign</h6>
                <span className="pb-2 d-flex justify-content-between mr-5">
                  <small>
                    View all inward and outward transactions on your card
                  </small>

                  <small className="pt-1">
                    <Link to="/marketer/card/allcampaigns">View all</Link>
                  </small>
                </span>

                <div className="campaign_columns mt-2">
                  {walletCampaignHistory?.returnObjects ? walletCampaignHistory?.returnObjects
                    .slice(0, 3)
                    .map((history) => (
                    
                      <div className="campaign_card px-3 mr-4 my-1" key={history.id}>
                        <span className="campaign_innerContainer px-3 py-4 ml-2">
                          <span className="d-flex justify-content-between">
                            <h6 className="campaign_heading">
                              {history.title}
                            </h6>
                            {/* <small className="campaign_id">
                              {" "}
                              {history.id.slice(0, 4)}
                            </small> */}
                          </span>
                          <span className="campaign_heading pt-5">
                            {" "}
                            {100 -  Math.round((history.amountSpent * 100) / history.budget)}%
                          </span>
                          <ProgressBar percentage={Math.round((history.amountSpent * 100) / history.budget)} />
                          <span className="d-flex justify-content-between mt-2">
                            <span className="">
                              <small className="campaign_footer_heading singleHeight">
                                Amount Spent
                              </small>
                              <h6 className="campaign_footer_amt">
                                N{history.amountSpent}
                              </h6>
                            </span>
                            <span className="">
                              <small className="campaign_footer_heading">
                                Budget Limit
                              </small>
                              <h6 className="campaign_footer_amt">
                                N{history.budget}
                              </h6>
                            </span>
                          </span>
                        </span>
                      </div>
                    )): mockCampaignsData
                    .slice(0, 3)
                    .map((history) => (
                      <div className="campaign_card px-3 mr-4 my-1" key={history.id}>
                        <span className="campaign_innerContainer px-3 py-4 ml-2">
                          <span className="d-flex justify-content-between">
                            <h6 className="campaign_heading">
                              {history.loading}
                            </h6>
                            <small className="campaign_id">
                              {" "}
                              {history.loading}
                            </small>
                          </span>
                          <span className="campaign_heading pt-5">
                            {" "}
                            {0}%
                          </span>
                          <ProgressBar percentage={100} />
                          <span className="d-flex justify-content-between mt-2">
                            <span className="">
                              <small className="campaign_footer_heading singleHeight">
                                Amount Spent
                              </small>
                              <h6 className="campaign_footer_amt">
                                {history.loading}
                              </h6>
                            </span>
                            <span className="">
                              <small className="campaign_footer_heading">
                                Budget Limit
                              </small>
                              <h6 className="campaign_footer_amt">
                                {history.loading}
                              </h6>
                            </span>
                          </span>
                        </span>
                      </div>
                    ))}
                </div>
              </section>}

              <section
                className="wallet_details_section mt-3"
                id="#TransactionHistory"
              >
                <h6 className="wallet_sub_heading">Transaction History</h6>
                <small ref={this.myRef}>
                  View all inward and outward transactions on your card
                </small>
                <TransactionHistory
                  allWalletHistory={allWalletHistory}
                  walletHistory={walletHistory?.payload}
                  changePageSize={this.changePageSize}
                  selectedPage={selectedPage}
                  handleSelected={this.handleSelected}
                  pageSize={pageSize}
                  totalCount={totalHistoryCount}
                  viewTransactionDetails={this.viewTransactionDetails}
                  onChangeInput={this.onChange}
                  searchField={this.state.searchField}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  showDateRange={this.state.showDateRange}
                  onClickDateFilter={this.onClickDateFilter}
                  handleStartDateChange={this.handleStartDateChange}
                  handleEndDateChange={this.handleEndDateChange}
                  loader={this.state.loader}
                  onMouseLeaveField={this.onMouseLeaveField}
                  onSearchField={this.onSearchField}
                  types={this.state.types}
                />
              </section>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  totalCount: state.user.totalCount,
});
const mapDispatchToProps = () => ({
  getWalletBalance,
  getWalletHistory,
  getCampaignSummary,
  setData,
  getAllWalletHistory
});

export default connect(mapStateToProps, mapDispatchToProps())(index);

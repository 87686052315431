import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function CampaignActionButton({
  updateCamStatus,
  goTo,
  campaigns,
  direction,
  ...args
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="d-flex" >
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
        <DropdownToggle className="btn-outline btn-outline-info bg-light text-info" color="">Action</DropdownToggle>
        <DropdownMenu {...args}>
          <DropdownItem onClick={() => goTo("view-campiagn", campaigns)}>
            View
          </DropdownItem>
          <DropdownItem onClick={() => goTo("edit-campiagns", campaigns)}>
            Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => updateCamStatus(campaigns.id, campaigns.statusId)}
          >
            {" "}
            {campaigns.statusId === 2 ? "Deactivate" : "Activate"}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default CampaignActionButton;

import React, { Component } from "react";
import { connect } from "react-redux";

import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";

import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import AddIcon from "@material-ui/icons/Add";
import { NotificationManager } from "react-notifications";

// Dialogue Hanlder
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Can from "../../../../../config/Can";
import * as moment from "moment";

import {
  getCampaigns,
  updateCampaign,
  deleteCampaign,
  addCampaign,
  updateCampaignStatus,
} from "../../../../../redux/campain/campaignActions";
import { getActiveClient, getClients } from "../../../../../dependency/UtilityFunctions";
import { getClientList } from "../../../../../redux/client/clientActions";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

import "./addcampain.css";
import { getPermissions } from "../../../../../dependency/UtilityFunctions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class AddCampains extends Component {
  constructor() {
    super();
    this.state = {
      campaignId: "",
      sideDrawerOpen: true,
      selectedDate: "",
      startDate: new Date(),
      endDate: new Date(),
      criteria: "",
      criterias: [],
      criteriaAmount: "",
      campainTitle: "",
      campainDescription: "",
      campainBarner: "",
      campaignType: "",
      campaignBillsPayment: "",
      rewardAmount: "",
      rewardStyle: "",
      budgetLimit: "",
      rewardInterval: "",
      productClient: "",
      completionStatus: "",
      campaignTypeId: null,
      campaignCategoryId: null,
      campaignStatus: "",
      statusId: null,
      businessRules: [],
      clientIds: getClients(),
      clientId: "",
      client: "",
      loading: false,
      cid: null,
      ctid: null,
      selectedOption: null,
      open: false,
      agreed: false,
      permission: getPermissions(),
      activator: false,
      campaignTypes: [
        {
          CampaignTypeId: "1",
          Name: "Referral Campaign",
        },

        {
          CampaignTypeId: "2",
          Name: "Instant Campaign",
        },
      ],
      campaignCategorys: [
        { id: 1, name: "Wallet transfer" },
        { id: 2, name: "Call credit top up" },
      ],
      campaignStatuses: [
        {
          CampaignStatuId: 1,
          Name: "In Draft",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 2,
          Name: "Activated",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 3,
          Name: "Not Activated",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 4,
          Name: "Stopped",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 5,
          Name: "Expired",
          DateCreated: new Date(),
        },
      ],
      rewardBandDetails: [],
      isPaidReferrerAcquisition: false,
      isPaidReferreeAcquisition: false,
      acquisitionAmountReferrer: 0,
      acquisitionAmountReferee: 0
    };
    this.validator = new SimpleReactValidator();
  }
  componentWillReceiveProps(nextProps) {
    const state = nextProps.history.location.state;
    let {
      budget,
      campaignCategoryId,
      campaignId,
      campaignTypeId,
      clientId,
      dateOfCreation,
      description,
      endDate,
      imageUrl,
      startDate,
      title,
      statusId,
      campaignTypeName,
      rewardBandDetails,
      isPaidReferrerAcquisition,
      isPaidReferreeAcquisition,
      acquisitionAmountReferrer,
      acquisitionAmountReferee,
      businessRules,
      rewardInterval
    } = state.campaign;
    let rs = this.state.campaignStatuses.filter(
      (v) => v.CampaignStatuId === statusId
    );
    let crs = this.state.clientIds.filter((v) => v.id === clientId);

    this.setState({
      budgetLimit: budget,
      campaignCategoryId,
      campaignId,
      campaignTypeId,
      clientId,
      dateOfCreation,
      campainDescription: description,
      endDate,
      campainBarner: imageUrl,
      campaignType: campaignTypeName,
      rewardInterval: rewardInterval,
      startDate,
      campainTitle: title,
      statusId,
      status: rs[0]["Name"],
      businessRules,
      client: crs[0]["name"],
      rewardBandDetails: rewardBandDetails,
      isPaidReferrerAcquisition,
      isPaidReferreeAcquisition,
      acquisitionAmountReferrer,
      acquisitionAmountReferee
    });
  }

  componentDidUpdate (prevProps, prevState) {
    if(prevState.statusId !== this.state.statusId){
      this.forceUpdate();
      console.log(this.state.statusId)
      this.setState({
        statusId: this.state.statusId
      })
    }
  }
  componentDidMount() {
    // let r = this.state.permission.filter((v) => v === "MKT_03");
    // if (r.length > 0) {
    //   this.setState({ activator: true });
    // }

    //
    const state = this.props.location.state;
    let {
      budget,
      campaignCategoryId,
      id,
      campaignId,
      campaignTypeId,
      clientId,
      dateOfCreation,
      description,
      endDate,
      imageUrl,
      startDate,
      campaignTypeName,
      threshold,
      title,
      statusId,
      businessRules,
      rewardBandDetails,
      isPaidReferrerAcquisition,
      isPaidReferreeAcquisition,
      acquisitionAmountReferrer,
      acquisitionAmountReferee,
      rewardInterval
    } = state.campaign;

    let rs = this.state.campaignStatuses.filter(
      (v) => v.CampaignStatuId === statusId
    );
    let crs = this.state.clientIds.filter((v) => v.id === clientId);
    //

    this.setState({
      budgetLimit: budget,
      campaignCategoryId,
      campaignId: id,
      campaignTypeId,
      campaignType: campaignTypeName,
      threshold,
      clientId,
      dateOfCreation, 
      rewardInterval: rewardInterval,
      campainDescription: description,
      endDate,
      campainBarner: imageUrl,
      startDate,
      campainTitle: title,
      statusId,
      status: rs[0]["Name"],
      businessRules,
      client: crs[0]["name"],
      rewardBandDetails: rewardBandDetails,
      isPaidReferrerAcquisition,
      isPaidReferreeAcquisition,
      acquisitionAmountReferrer,
      acquisitionAmountReferee
    });
  }
  delete = () => {
    alert("deleting...");
  };
  toggleComfirm = (e) => {
    this.setState({
      open: !this.state.open,
    });
  };
  send = (agreed) => {
    if (agreed) {
      this.toggleLoading();
      let {
        startDate,
        endDate,
        campainTitle,
        campainDescription,
        campainBarner,
        budgetLimit,
        ClientId,
        clientIds,
      } = this.state;
      let rs = clientIds.filter((v) => v.name === ClientId);

      let newCampaign = {
        title: campainTitle,
        description: campainDescription,
        campaignTypeId: parseInt(this.ctid),
        statusId: parseInt(this.cid),
        modifiedBy: null,
        imageUrl: campainBarner,
        dateOfModification: null,
        startDate: startDate,
        budget: parseInt(budgetLimit),
        endDate: endDate,
        ClientId: rs[0]["id"],
        dateOfCreation: new Date(),
        CampaignCategoryId: 2,
      };

      this.props.addCampaign(newCampaign).then((res) => {
        this.toggleLoading();
        let { status, data } = res;
        if (res) {
          if (status === 200) {
            if (data.payload.successful === true) {
              this.createNotification("success", data.payload.message);

              localStorage.setItem("tCampain", data.payload.returnObjects);
              this.props.history.push({
                pathname: "add-businessrules",
                // search: "?query=abc",
                state: { cid: data.payload.returnObjects },
              });
            }
          } else if (status === 400) {
            if (data.payload.message) {
              this.createNotification("error", data.payload.message);
            } else {
              this.createNotification("error", "Bad Request");
            }
          } else {
            this.createNotification("error", data.message);
          }
        } else {
          this.createNotification(
            "error",
            "An Error Occourd, Please, try again"
          );
        }
      });
    }
  };
  handleAgreed = () => {
    this.toggleComfirm();
    this.setState(
      {
        agreed: true,
      },
      () => {
        this.send(true);
      }
    );
  };
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  addCriteria = (e) => {
    e.preventDefault();
    // localStorage.setItem("criterias", JSON.stringify(""));
    // alert("Adding Criteria");
    let { criteria, criteriaAmount } = this.state;
    if (!criteria || !criteriaAmount) {
      let cr = document.getElementById("criteria");
      cr.focus();
      this.createNotification(
        "error",
        "Criteria and Criteria Amount is required."
      );
      return;
    }
    if (criteria === "Select Criteria") {
      this.createNotification("error", "Criteria is required.");
      return;
    }

    let criteriaVals = {
      id: this.state.criterias.length,
      criteria,
      criteriaAmount,
    };
    let c = this.state.criterias;
    for (let i = 0; i < c.length; i++) {
      const element = c[i];
      if (element.criteria === criteriaVals.criteria) {
        this.createNotification(
          "error",
          criteria + ", as already been selected.."
        );
        return;
      }
    }
    this.setState(
      {
        criterias: [...this.state.criterias, criteriaVals],
      },
      () => {
        //
      }
    );
    this.setState({ criterias: [...this.state.criterias, criteriaVals] });

    this.setState({ criteria: "" });
    this.setState({ criteriaAmount: "" });
  };
  criteriaDelete = (id) => {
    this.setState({
      criterias: this.state.criterias.filter((criteria) => criteria.id !== id),
    });
    this.createNotification("success", "Deleted Successfully.");
  };
  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  addCampain = () => {
    // alert("Adding");
    this.props.history.push("add-campians");
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    })
    let altCid = getActiveClient();
    let { campaignId, statusId } = this.state;
    
    if (statusId === 2) {
      const newDetails = {
        campaignId: campaignId,
        statusId: 1,
        clientId: altCid.id,
      };
      this.props.updateCampaignStatus(newDetails, this.props.getCampaigns).then(
        this.setState({
          hasErrors: false,
          status: "Activated",
          loading: false
        })
      );
    } else {
      const newDetails = {
        campaignId: campaignId,
        statusId: 2,
        clientId: altCid.id,
      };
      this.props.updateCampaignStatus(newDetails, this.props.getCampaigns).then(this.setState({
        hasErrors: false,
        status: "Not Activated",
        loading: false
      }));
    }
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  render() {
    return (
      <>
        <div>
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          <main
            className="container-fluid px-5 "
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7 row pr-0 mr-0">
                {/* <div style={{ display: "flex", flexDirection: "row" }}></div> */}
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    width: "80%",
                  }}
                  className=""
                >
                  Campaign
                </h4>
                <button
                  style={{ width: "20%" }}
                  className="btn button2 float-right wid"
                  onClick={() => this.props.history.push("campaigns")}
                >
                  {"Back"}
                </button>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6 mx-0 py-0">
                    <div className="card my-4 singleHeight">
                      <div className="card-header headerWHite">
                        Campaign Details
                      </div>
                      <div className="card-body">
                        <Form style={{ color: "#000" }}>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Campaign title
                            </Label>
                            <div className="col-sm-7 textField viewcampaig-font-et-color">
                              {this.state.campainTitle}
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              for="description"
                              className="col-sm-4 labell"
                            >
                              Description
                            </Label>
                            <div
                              className="col-sm-7   textField pb-5 viewcampaig-font-et-color"
                              value={this.state.campainDescription}
                            >
                              {this.state.campainDescription}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleSelect"
                              className="col-sm-4 labell"
                            >
                              Campaign banner
                            </Label>
                            <div className="col-sm-7 textField viewcampaig-font-et-color">
                              {this.state.campainBarner
                                ? this.state.campainBarner
                                : "Link Here"}
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              for="exampleSelect"
                              className="col-sm-4 labell"
                            >
                              Campaign Type
                            </Label>
                            <div className="col-sm-5 textField viewcampaig-font-et-color">
                            {this.state.campaignType}
                              {/* {this.state.campaignTypeId === 1
                                ? this.state.campaignTypes[0]["Name"]
                                : this.state.campaignTypes[1]["Name"]} */}
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              for="description"
                              className="col-sm-4 labell"
                            >
                              Start date
                            </Label>

                            <div className="textField col-sm-7 viewcampaig-font-et-color">
                              {this.state.startDate
                                ? moment(this.state.startDate).format(
                                    "MMM DD, YYYY"
                                  )
                                : JSON.stringify(this.state.startDate)}
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-4 labell">End date</Label>
                            <div className="textField col-sm-7 viewcampaig-font-et-color">
                              {this.state.startDate
                                ? moment(this.state.endDate).format(
                                    "MMM DD, YYYY"
                                  )
                                : JSON.stringify(this.state.endDate)}
                            </div>
                          </FormGroup>

                          <FormGroup className="row">
                              <Label
                                for="exampleEmail"
                                className="col-sm-4 labell"
                              >
                               Reward Interval
                              </Label>

                              <div className="col-sm-7 textField viewcampaig-font-et-color">
                                {this.state.rewardInterval}
                              </div>
                            </FormGroup>

                          <FormGroup className="row">
                              <Label
                                for="exampleSelect"
                                className="col-sm-4 labell"
                              >
                                Budget Threshold
                              </Label>
                              <div className="col-sm-7 textField viewcampaig-font-et-color">
                                {/* {this.state.campaignCategoryId === 1
                                  ? this.state.campaignCategorys[0]["name"]
                                  : this.state.campaignCategoryId === 2
                                  ? this.state.campaignCategorys[1]["name"]
                                  : JSON.stringify(
                                      this.state.campaignCategoryId
                                    )} */}
                                     {this.state.threshold}
                              </div>
                            </FormGroup>

                            <FormGroup className="row">
                              <Label
                                for="exampleEmail"
                                className="col-sm-4 labell"
                              >
                                Budget Limit
                              </Label>

                              <div className="col-sm-7 textField viewcampaig-font-et-color">
                                {this.state.budgetLimit}
                              </div>
                            </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mx-0 py-0">
                    <div className="col-sm-12 mx-   ">
                   {(this.state.campaignTypeId === 2 || this.state.campaignTypeId === 3) &&   <div
                        className="card card my-4 singleHeight"
                        style={{ height: "auto" }}
                      >
                        <div className="card-header headerWHite">Reward</div>
                        <div className="card-body">
                          <Form>

                            {(this.state.campaignTypeId === 2 || this.state.campaignTypeId === 3) && (
                              this.state.rewardBandDetails.map(details => (
                                <section>
                                <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Transaction Type
                                </Label>
  
                                <div className="col-sm-7 textField viewcampaig-font-et-color">
                                  {details.transactionType}
                                </div>
                              </FormGroup>
                              <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Reward Band 
                                </Label>
  
                                <div className="col-sm-7 textField viewcampaig-font-et-color">
                                  {details.rewardBandTitle}
                                </div>
                              </FormGroup>
                              <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Referee Amount
                                </Label>
  
                                <div className="col-sm-7 textField viewcampaig-font-et-color">
                                  {details.refereeAmount}
                                </div>
                              </FormGroup>
                              <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Referrer Amount
                                </Label>
  
                                <div className="col-sm-7 textField viewcampaig-font-et-color">
                                  {details.referrerAmount}
                                </div>
                              </FormGroup>
                              <hr></hr>
                              </section>
                              ))
                            )}

                            {
                              (this.state.campaignTypeId === 1) && 
                              <section>
                            {this.state.isPaidReferrerAcquisition &&  <FormGroup className="row">
                              <Label
                                for="exampleEmail"
                                className="col-sm-4 labell"
                              >
                                Acquisition Referrer Amount
                              </Label>

                              <div className="col-sm-7 textField viewcampaig-font-et-color">
                                {this.state.acquisitionAmountReferrer}
                              </div>
                            </FormGroup>}
                            {this.state.isPaidReferreeAcquisition &&  <FormGroup className="row">
                             <Label
                               for="exampleEmail"
                               className="col-sm-4 labell"
                             >
                               Acquisition Referee Amount
                             </Label>

                             <div className="col-sm-7 textField viewcampaig-font-et-color">
                               {this.state.acquisitionAmountReferee}
                             </div>
                           </FormGroup>}
                           </section>
                            }
                          </Form>
                        </div>
                      </div>}
                    </div>
                    <div className="col-sm-12 mx-0 pt-0 mt-4">
                      <div className="card" style={{ height: "auto " }}>
                        <div className="card-header headerWHite">Status</div>
                        <div className="card-body">
                          <FormGroup className="row">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell"
                            >
                              Completion status
                            </Label>
                            <div className="col-sm-7 textField viewcampaig-font-et-color">
                              {this.state.status}
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell"
                            >
                              Client
                            </Label>

                            <div className="col-sm-7 textField viewcampaig-font-et-color">
                              {this.state.client}
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                      <Can I="activate" a="campaign">
                        {() => (
                          <div>
                            {/* <div className="float-right w-25">
                              <button
                                className="btn button2 my-4 w-100"
                                onClick={this.onSubmit}
                                disabled={this.state.loading}
                              >
                                {this.state.loading
                                  ? "Loading..."
                                  : this.state.status === "Activated"
                                  ? "De activate"
                                  : "Activate"}
                              </button>
                            </div> */}
                            <div className="float-right w-25 d-none">
                              <button
                                className="btn  my-4 w-100"
                                style={{ color: "red" }}
                                onClick={this.delete}
                                disabled={this.state.loading}
                              >
                                {this.state.loading ? "Loading..." : "Delete"}
                              </button>
                            </div>
                          </div>
                        )}
                      </Can>
                      {/* {this.state.activator ? (
                        <div>
                          <div className="float-right w-25">
                            <button
                              className="btn button2 my-4 w-100"
                              onClick={this.onSubmit}
                              disabled={this.state.loading}
                            >
                              {this.state.loading
                                ? "Loading..."
                                : this.state.status === "Activated"
                                ? "De activate"
                                : "Activate"}
                            </button>
                          </div>
                          <div className="float-right w-25 d-none">
                            <button
                              className="btn  my-4 w-100"
                              style={{ color: "red" }}
                              onClick={this.delete}
                              disabled={this.state.loading}
                            >
                              {this.state.loading ? "Loading..." : "Delete"}
                            </button>
                          </div>
                        </div>
                      ) : null} */}

                      {/*  */}
                    </div>

                    <div
                      className="card addCampain my-4 d-none"
                      style={{ height: "auto" }}
                    >
                      <div className="card-header headerWHite">
                        <span>Reward Criteria</span>
                        <span
                          className="remove-card-header col-sm-2"
                          style={{ marginLeft: "35%" }}
                        >
                          Remove selected
                        </span>
                      </div>
                      <div className="card-body">
                        <Form className="rewardForm ">
                          <FormGroup
                            className="colum split mx-0 px-0 "
                            style={{ padding: "0" }}
                          >
                            <div className="">
                              <Label
                                for="description "
                                className="except-this-label p-0"
                              >
                                Criteria
                              </Label>

                              <Input
                                type="select"
                                name="criteria"
                                id="criteria"
                                placeholder="Select Campaign Type"
                                className="col-sm-11 textField select"
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}
                                value={this.state.criteria}
                                autoComplete="off"
                              >
                                <option>Select Criteria</option>
                                {[
                                  {
                                    criteriaId: "1",
                                    Name: "Airtime purchase",
                                  },
                                  {
                                    criteriaId: 2,
                                    Name: "Global mall purchase",
                                  },
                                  {
                                    criteriaId: 3,
                                    Name: "Event ticket purchase",
                                  },
                                  {
                                    criteriaId: 4,
                                    Name: "Flight ticket",
                                  },
                                  {
                                    criteriaId: 5,
                                    Name: "Funds transfer",
                                  },
                                ].map((value) => {
                                  return (
                                    <option key={value.criteriaId}>
                                      {value.Name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </div>
                          </FormGroup>
                          <FormGroup className="colum split mx-0 px-0  ml-1">
                            <div className="p-0">
                              <Label
                                for="description"
                                className=" except-this-label"
                              >
                                Minimum Amount
                              </Label>
                              <Input
                                className="textField"
                                type="number"
                                name="criteriaAmount"
                                id="campainTitle"
                                placeholder="0"
                                min="0"
                                step="20"
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}
                                value={this.state.criteriaAmount}
                                autoComplete="off"
                              />
                            </div>
                          </FormGroup>
                          <FormGroup className="col  mx-0 pl-0 no-padding ">
                            <div
                              style={{
                                display: "flex",
                                height: "45%",
                                width: "3%",
                              }}
                            ></div>
                            <button
                              className="btn button ml-2 addBtn"
                              onClick={this.addCriteria.bind(this)}
                            >
                              Add
                            </button>
                          </FormGroup>
                        </Form>
                        <div>
                          <div>
                            <table className="table addCampaintable campainstable table-hover">
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "left" }}>
                                    <span className="pr-2">
                                      <CheckBoxOutlineBlankIcon />
                                    </span>
                                    Criteria
                                  </th>
                                  <th>Minimum Amount</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.criterias.map((value) => {
                                  return (
                                    <tr key={value.id}>
                                      <td>
                                        <span className="pr-2">
                                          <AddIcon />
                                        </span>
                                        {value.criteria}
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="textfieldPackage"
                                          value={value.criteriaAmount}
                                          autoComplete="off"
                                          readOnly
                                        />
                                      </td>

                                      <td>
                                        <span
                                          className="exit"
                                          onClick={this.criteriaDelete.bind(
                                            this,
                                            value.id
                                          )}
                                        >
                                          <RemoveCircleIcon className="exitSvg" />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div>
                  <div>
                    <table className="table addCampaintable campainstable table-hover">
                      <thead className="buss-thead">
                        <tr>
                          <th style={{ textAlign: "left" }}>Title</th>
                          <th>Criteria</th>
                          <th>Minimum Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.businessRules?.map((value) => {
                          return (
                            <tr key={value}>
                              <td>{value.title}</td>
                              <td>{value.rewardableTransactionTypeId}</td>
                              <td>{value.rewardableValueThreshold}</td>
                              {/* <td>
                                <span
                                  className="exit"
                                  onClick={this.criteriaDelete.bind(
                                    this,
                                    value.id
                                  )}
                                >
                                  <RemoveCircleIcon className="exitSvg" />
                                </span>
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.toggleComfirm}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ color: "#000" }}>
            {"Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ color: "#000" }}
            >
              Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleComfirm} color="danger">
              No
            </Button>
            <Button
              onClick={this.handleAgreed}
              style={{ background: "#212529" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  campains: state.campain.campaigns,
  campaignType: state.campain.campaignType,
  campaignStatus: state.campain.campaignStatus,
  clients: state.client.clients,
});
const mapDispatchToProps = () => ({
  addCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaigns,
  getClientList,
  updateCampaignStatus,
});

export default connect(mapStateToProps, mapDispatchToProps())(AddCampains);

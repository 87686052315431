import React, { Component } from "react";
import { connect } from "react-redux";

import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";

import { NotificationManager } from "react-notifications";

// Dialogue Hanlder
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import {
  getClientList,
  addClient,
} from "../../../../../redux/client/clientActions";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

import "./add-client.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class AddCampains extends Component {
  constructor() {
    super();
    this.state = {
      sideDrawerOpen: true,
      selectedDate: "",
      startDate: new Date(),
      endDate: new Date(),

      name: "",
      description: "",
      clientKey: "",
      clientSecret: "",
      Email: "",
      phoneNumber: "",
      corporateAlias: "",
      isDeleted: false,
      ClientUniqueIdentifierKey: "IKIA5A2F85EF181BA122CD65E6E68988F87EA779F19A",
      loading: false,
      cid: null,
      ctid: null,
      selectedOption: null,
      open: false,
      agreed: false,
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    this.props.getClientList().then((res) => {});
    // .catch(err);
  }
  toggleComfirm = (e) => {
    this.setState({
      open: !this.state.open,
    });
  };
  send = (agreed) => {
    if (agreed) {
      this.toggleLoading();
      let {
        startDate,
        endDate,

        campainTitle,
        campainDescription,

        budgetLimit,
      } = this.state;
      // alert(resp);
      let newCampaign = {
        title: campainTitle,
        description: campainDescription,
        campaignTypeId: parseInt(this.ctid),
        statusId: parseInt(this.cid),
        modifiedBy: null,
        imageUrl: "http://kdbkbk.img",
        dateOfModification: null,
        startDate: startDate,
        budget: parseInt(budgetLimit),
        endDate: endDate,
        ClientId: "d8760c41-b58b-4fb1-ab5c-a2b9088ac296",
        dateOfCreation: new Date(),
        CampaignCategoryId: 2,
      };
      this.props.addCampaign(newCampaign).then((res) => {
        this.toggleLoading();
        if (typeof res !== "undefined") {
          let { status, data } = res;
          if (res) {
            if (status === 200) {
              if (data.payload.successful === true) {
                this.createNotification("success", data.payload.message);
                this.setState({
                  selectedDate: "",
                  startDate: "",
                  endDate: "",
                  criteria: "",
                  criterias: "",
                  criteriaAmount: "",
                  campainTitle: "",
                  campainDescription: "",
                  campainBarner: "",
                  campaignType: "",
                  campaignBillsPayment: "",
                  rewardAmount: "",
                  rewardStyle: "",
                  budgetLimit: "",
                  productClient: "",
                  completionStatus: "",
                });
              }
            } else if (status === 400) {
              if (data.payload.message) {
                this.createNotification("error", data.payload.message);
              } else {
                this.createNotification("error", "Bad Request");
              }
            } else {
              this.createNotification("error", data.message);
            }
          } else {
            this.createNotification(
              "error",
              "An Error Occourd, Please, try again"
            );
          }
        }
      });
    }
  };
  handleAgreed = () => {
    this.toggleComfirm();
    this.setState(
      {
        agreed: true,
      },
      () => {
        this.send(true);
      }
    );
  };
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  h;
  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  addCriteria = (e) => {
    e.preventDefault();
    let { criteria, criteriaAmount } = this.state;
    if (!criteria || !criteriaAmount) {
      let cr = document.getElementById("criteria");
      cr.focus();
      this.createNotification(
        "error",
        "Criteria and Criteria Amount is required."
      );
      return;
    }
    if (criteria === "Select Criteria") {
      this.createNotification("error", "Criteria is required.");
      return;
    }

    let criteriaVals = {
      id: this.state.criterias.length,
      criteria,
      criteriaAmount,
    };
    let c = this.state.criterias;
    for (let i = 0; i < c.length; i++) {
      const element = c[i];
      if (element.criteria === criteriaVals.criteria) {
        this.createNotification(
          "error",
          criteria + ", as already been selected.."
        );
        return;
      }
    }
    this.setState(
      {
        criterias: [...this.state.criterias, criteriaVals],
      },
      () => {
        //
      }
    );
    this.setState({ criterias: [...this.state.criterias, criteriaVals] });

    this.setState({ criteria: "" });
    this.setState({ criteriaAmount: "" });
  };
  criteriaDelete = (id) => {
    this.setState({
      criterias: this.state.criterias.filter((criteria) => criteria.id !== id),
    });
    this.createNotification("success", "Deleted Successfully.");
  };
  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  addCampain = () => {
    // alert("Adding");
    this.props.history.push("add-campians");
  };

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      let {
        name,
        description,
        clientKey,
        clientSecret,
        Email,
        phoneNumber,
        corporateAlias,
        isDeleted,
        ClientUniqueIdentifierKey,
      } = this.state;

      let newClient = {
        name,
        description,
        clientKey,
        clientSecret,
        Email,
        phoneNumber,
        corporateAlias,
        isDeleted,
        ClientUniqueIdentifierKey,
      };

      this.toggleLoading();
      this.props
        .addClient(newClient)
        .then((res) => {
          this.toggleLoading();
          if (typeof res !== "undefined") {
            let { status, data } = res;
            if (res) {
              if (status === 200) {
                if (data.payload.successful === true) {
                  this.createNotification("success", data.payload.message);
                  this.setState({
                    name: "",
                    description: "",
                    clientKey: "",
                    clientSecret: "",
                    Email: "",
                    phoneNumber: "",
                    corporateAlias: "",
                    isDeleted: false,
                    ClientUniqueIdentifierKey: "",
                  });
                  this.props.history.push("client");
                } else {
                  this.createNotification(
                    "error",
                    data.payload.message
                      ? data.payload.message
                      : data.description
                  );
                }
              } else {
                this.createNotification(
                  "error",
                  data.payload.message ? data.payload.message : data.description
                );
              }
            } else {
            }
          }
        })
        .catch((err) => {
          this.toggleLoading();
        });
    } else {
      this.createNotification("error", "Kindly supply all fields.");
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  createNotification = (type, msg = null) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  render() {
    return (
      <>
        <div>
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          <main
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7">
                <div style={{ display: "flex", flexDirection: "row" }}></div>
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    marginRight: "60%",
                  }}
                  className=""
                >
                  Add New Client
                </h4>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6 mx-0 py-0">
                    <div className="card my-4 singleHeight">
                      <div className="card-header headerWHite">
                        Business Details
                      </div>
                      <div className="card-body">
                        <Form>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Business Name
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Business Name"
                              value={this.state.name}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Name",
                                this.state.name,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              for="description"
                              className="col-sm-4 labell"
                            >
                              Description
                            </Label>
                            <Input
                              className="col-sm-7 textField "
                              type="textarea"
                              placeholder="Description"
                              name="description"
                              id="description"
                              value={this.state.description}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                              style={{ height: "130px" }}
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Description",
                                this.state.description,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleSelect"
                              className="col-sm-4 labell"
                            >
                              Business Email
                            </Label>
                            <Input
                              className="col-sm-7 textField"
                              type="email"
                              name="Email"
                              id="Email"
                              placeholder="Business Email"
                              value={this.state.Email}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />

                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Email",
                                this.state.Email,
                                "required|email"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Phone number
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="tel"
                              name="phoneNumber"
                              id="phoneNumber"
                              placeholder=""
                              value={this.state.phoneNumber}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "phoneNumber",
                                this.state.phoneNumber,
                                "required|phone|min:7"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell"
                            >
                              Corporate Alias
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="corporateAlias"
                              id="corporateAlias"
                              placeholder="Corporate Alias"
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Corporate Alias",
                                this.state.corporateAlias,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mx-0 py-0">
                    <div
                      className="card addCampain my-4"
                      style={{ height: "auto" }}
                    >
                      <div className="card-body">
                        <Form className=" text-center w-100">
                          <FormGroup className="row">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell"
                            >
                              Client Key
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="clientKey"
                              id="clientKey"
                              placeholder="Client Key"
                              value={this.state.clientKey}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "clientKey",
                                this.state.clientKey,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell"
                            >
                              Secret Key
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="clientSecret"
                              id="clientSecret"
                              placeholder="Secret Key"
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              value={this.state.clientSecret}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "clientSecret",
                                this.state.clientSecret,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <button
                    className="btn button22 my-4 float-right w-25 text-white"
                    onClick={this.onSubmit}
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? "Loading..." : "Create Client"}
                  </button>
                  <button
                    className="btn button22 my-4 float-right w-25 text-white"
                    onClick={() => {
                      this.props.history.push("client");
                    }}
                  >
                    {"Back"}
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.toggleComfirm}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ color: "#000" }}>
            {"Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ color: "#000" }}
            >
              Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleComfirm} color="danger">
              No
            </Button>
            <Button
              onClick={this.handleAgreed}
              style={{ background: "#212529" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  campains: state.campain.campaigns,
  campaignType: state.campain.campaignType,
  campaignStatus: state.campain.campaignStatus,
  clients: state.client.clients,
});
const mapDispatchToProps = () => ({
  addClient,
  getClientList,
});

export default connect(mapStateToProps, mapDispatchToProps())(AddCampains);

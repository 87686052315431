export const GET_AGENTS = "GET_AGENTS";
export const ADD_AGENT = "ADD_AGENT";
export const DELETE_USER = "DELETE_USER";
export const USER_LOADING = "USER_LOADING";
export const UPDATE_AGENT = "UPDATE_AGENT";
export const PERMISSION_LOADING = "PERMISSION_LOADING";
export const GET_PERMISSION = "GET_PERMISSION";
export const GET_USER_CLIENTS = "GET_USER_CLIENTS";
export const GET_AGENTS_FAIL = "GET_AGENTS_FAIL";
export const GET_SUB_AGENTS = "GET_SUB_AGENTS";
export const GET_SUB_AGENTS_FAIL = "GET_SUB_AGENTS_FAIL";

import {
  GET_WALLET_BALANCE,
  GET_CAMPAIGNS_LIMITS,
  GET_TRANSACTION_HISTORY,
  GET_CAMPAIGN_SUMMARY,
  GET_ALL_TRANSACTION_HISTORY,
} from "../types";
import { getAccessToken } from "../../../dependency/UtilityFunctions";

const initislState = {
  walletBalance: [],
  walletHistory: [],
  walletDetails: [],
  allWalletHistory: [],
  totalHistoryCount: 0,
  access_token: getAccessToken(),
};
export default function (state = initislState, action) {
  switch (action.type) {
    case GET_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: action.payload,
        loading: false,
      };
    case GET_CAMPAIGN_SUMMARY:
      return {
        ...state,
        walletCampaignHistory: action.payload,
        loading: false,
      };
      case  GET_TRANSACTION_HISTORY:
      return {
        ...state,
        walletHistory: action.payload,
        totalHistoryCount: action.payload.totalCount,
        l_loading: false,
      };
      case  GET_ALL_TRANSACTION_HISTORY:
        return {
          ...state,
          allWalletHistory: action.payload.payload,
        };
    case GET_CAMPAIGNS_LIMITS:
      return {
        ...state,
        updatedProfileDetails: action.payload,
        l_loading: false,
      };

    default:
      return state;
  }
}

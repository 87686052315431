import React, { Component } from "react";
import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import PaginationComponent from "react-reactstrap-pagination";

import HTTP from "../../../../../redux/http-trips";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Loader from "react-loader-spinner";
import {
  getCritetias,
  addCriteria,
} from "../../../../../redux/criteria/criteriaActions";
import { LogoutNow } from "../../../../../dependency/UtilityFunctions";
import "./campains.css";

class Campains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      rolesErr: "",
      totalRecordCount: 0,
      loading: true,
      sideDrawerOpen: true,
      anchorEl: null,
      open: null,
      selectedPage: 1,
      pageSize: 10,
      selectedCampaign: [],
      campaignStatus: [
        {
          CampaignStatuId: "1",
          Name: "In Draft",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 2,
          Name: "Activated",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 3,
          Name: "Not Activated",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 4,
          Name: "Stopped",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 5,
          Name: "Expired",
          DateCreated: new Date(),
        },
      ],
      cs: [
        "",
        { color: "badge-secondary", name: "In Draft" },
        { color: "badge-info", name: "Activated" },
        { color: "badge-warning", name: "Not Activated" },
        { color: "badge-danger", name: "Stopped" },
        { color: "badge-danger", name: "Expired" },
      ],
    };
    // this.toggleBasic = this.toggleBasic.bind(this);
    // this.toggleRight = this.toggleRight.bind(this);
    // this.toggleModalView = this.toggleModalView.bind(this);
    // this.toggle = this.toggle.bind(this);
    // this.toggleLoading = this.toggleLoading.bind(this);
  }

  componentDidMount() {
    let { pageSize, selectedPage } = this.state;
    this.getRoles(selectedPage, pageSize);
  }
  getRoles = (selectedPage = 1, pageSize = 10) => {
    HTTP.get(
      `/api/RoleMgmt/Get?Keyword=&PageIndex=${selectedPage}&PageTotal=${pageSize}`
    )
      .then((res) => {
        if (res.status === 200) {
          let { payload, totalCount } = res.data;
          this.setState({
            roles: payload,
            totalRecordCount: totalCount,
            loading: false,
            rolesErr: "",
          });
        }
      })
      .catch((err) => {
        this.setState({
          rolesErr: "An Error Occured",
          loading: false,
        });
      });
  };
  getClientId = (id) => {
    //
    this.props.getCritetias(id).then((res) => {});
  };
  handleClick = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  logOut = () => {
    LogoutNow();
  };

  goTo = (url, role) => {
    this.setState({
      selectedCampaign: role,
    });
    this.props.history.push({
      pathname: url,
      // search: "?query=abc",
      state: { role },
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.login();
    }
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
      // alert();
      // }
    }
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  addCampain = () => {
    this.props.history.push("newRole");
  };
  handleSelected = (selectedPage) => {
    let { pageSize } = this.state;
    this.getRoles(selectedPage, pageSize);
    this.setState({ selectedPage: selectedPage });
  };
  changePageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      this.getRoles(selectedPage, pageSize);
    });
  };
  render() {
    // let backDrop;
    // if (this.state.sideDrawerOpen) {
    //   backDrop = <BackDrop backDropClickHandler={this.backDropClickHandler} />;
    // }
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <SideDrawer show={this.state.sideDrawerOpen} />

          <main
            style={{
              flex: "1 1 0%",
            }}
          >
            <ToolBar1
              cl
              sendClientId={this.getClientId}
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h4
                    style={{
                      fontWeight: "bold",
                      paddingTop: "10px",
                      marginRight: "68%",
                      width: "100%",
                    }}
                    className="w-100"
                  >
                    Roles
                  </h4>

                  <div className="btnCOntainer">
                    <div className="header33" onClick={this.addCampain}>
                      Add Role
                    </div>
                  </div>
                </div>
              </div>
              <div className=" subacc-table container-fluid p-0">
                <div className="paginations d-flex justify-content-end w-100 p-0 ">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Tooltip title="Page size" aria-label="Page size">
                          <div
                            style={{
                              background: "#FFF",
                              padding: "5pt",
                              borderRadius: "5px",
                              boxShadow: "2px 2px 2px 2px #efefef",
                              height: "36px",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            {...bindTrigger(popupState)}
                          >
                            {this.state.pageSize}
                          </div>
                        </Tooltip>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(10)}
                          >
                            10
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(25)}
                          >
                            25
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(50)}
                          >
                            50
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(100)}
                          >
                            100
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                  <PaginationComponent
                    totalItems={this.state.totalRecordCount}
                    pageSize={this.state.pageSize}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                    maxPaginationNumbers={2}
                  />
                </div>
                <table className="table table-borderless  table-hover p-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody className="table-hover">
                    {!this.state.loading ? (
                      this.state.roles.map((c, key) => {
                        return (
                          <tr key={key}>
                            <td>{c.name}</td>
                            <td>{c.createdOnUtc}</td>
                            <td>
                              <PopupState
                                variant="popover"
                                popupId="demo-popup-menu"
                              >
                                {(popupState) => (
                                  <React.Fragment>
                                    <button
                                      type="button"
                                      className="btn "
                                      variant="contained"
                                      {...bindTrigger(popupState)}
                                      style={{
                                        color: "#00b8de",
                                        borderColor: "#00b8de",
                                      }}
                                    >
                                      Action
                                    </button>
                                    <Menu {...bindMenu(popupState)}>
                                      <MenuItem
                                        onMouseDown={popupState.close}
                                        onClick={() => this.goTo("viewRole", c)}
                                      >
                                        View
                                      </MenuItem>

                                      <MenuItem
                                        onMouseDown={popupState.close}
                                        onClick={() => this.goTo("editRole", c)}
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        onMouseDown={popupState.close}
                                        onClick={() => {
                                          confirmAlert({
                                            title: "Confirm to delete",
                                            message: "Are you sure to do this.",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => {
                                                  HTTP.post(
                                                    `/api/RoleMgmt/Delete/${c.id}`
                                                  )
                                                    .then((res) => {
                                                      let {
                                                        pageSize,
                                                        selectedPage,
                                                      } = this.state;
                                                      this.getRoles(
                                                        selectedPage,
                                                        pageSize
                                                      );
                                                      this.createNotification(
                                                        "success",
                                                        "Deleted Successfully."
                                                      );
                                                    })
                                                    .catch((err) => {
                                                      this.createNotification(
                                                        "error",
                                                        "Please try again."
                                                      );
                                                    });
                                                },
                                              },
                                              {
                                                label: "No",
                                                onClick: () => ({}),
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </React.Fragment>
                                )}
                              </PopupState>
                            </td>
                          </tr>
                        );
                      })
                    ) : !this.state.rolesErr ? (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            <Loader
                              type="RevolvingDot"
                              color="#00425f"
                              height={100}
                              width={100}
                              //timeout={3000} //3 secs
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            {this.state.rolesErr}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="paginations d-flex justify-content-end w-100  ">
                  <PaginationComponent
                    totalItems={this.state.totalRecordCount}
                    pageSize={this.state.pageSize}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                    maxPaginationNumbers={2}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}
// export default connect(mapStateToProps)(Index);
const mapStateToProps = (state) => ({
  criteria: state.criteria.criterias,
  loading: state.criteria.loading, //totalRecordCount
  totalRecordCount: state.criteria.totalRecordCount, //
  getCriteriaErr: state.criteria.getCriteriaErr,
});
const mapDispatchToProps = () => ({
  getCritetias,
  addCriteria,
});

export default connect(mapStateToProps, mapDispatchToProps())(Campains);

import React, { Component } from "react";
import { connect } from "react-redux";

import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { NotificationManager } from "react-notifications";
import SearchInput, { createFilter } from "react-search-input";

import HTTP from "../../../../../redux/http-trips";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {
  getPermissions,
  addUsers,
  getUserClients,
  getUserDetailsByID,
} from "../../../../../redux/user/userActions";
import { getClientList } from "../../../../../redux/client/clientActions";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Collapse } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

import "./newMarketer.css";

const KEYS_TO_FILTERS = ["value.id", "name", "description"];
class EditUser extends Component {
  constructor() {
    super();
    this.state = {
      sideDrawerOpen: true,
      selectedDate: "",
      criteria: "",
      searchTerm: "",
      roles: [
        {
          roleId: "1",
          Name: "SUPER_ADMIN",
        },

        {
          roleId: "3",
          Name: "MARKETING_USERS",
        },

        {
          roleId: "2",
          Name: "PRODUCT_USERS",
        },
      ],
      tval: false,
      roleId: null,
      permission: null,
      clientId: null,
      clients: [],
      userName: "",
      email: "",
      lastName: "",
      givenName: "",
      middleName: "",
      extraPermissions: [],
      phoneNumber: "",
      role: "SUPER_ADMIN",
      clientIds: [],
      clientNames: [],
      permLoading: false,
      loading: false,
      selectedOption: null,
      open: false,
      isOpen: false,
      agreed: false,
      permissions: [],
      userPerminsionLoaded: false,
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    const state = this.props.location.state;

    this.props
      .getClientList()
      .then((res) => {
        this.getRoles(1, 100);
      })
      .then(() => {
        if (state) {
          this.props
            .getUserDetailsByID(state.user.id)
            .then((res) => {
              if (res.status === 200) {
                let {
                  givenName,
                  lastName,
                  role,
                  username,
                  phoneNumber,
                  clientIds,
                } = res.data.payload;
                let clients = [];
                clientIds.map((id) => {
                  let r = this.props.clients.filter((c) => c.clientId === id);
                  if (r.length > 0) {
                    clients.push({ id: r[0]["clientId"], name: r[0]["name"] });
                  }
                  return clients;
                });

                this.setState(
                  {
                    email: username,
                    lastName: lastName,
                    givenName: givenName,
                    middleName: "",
                    phoneNumber,
                    role: role,
                    clientIds,
                    clientNames: clients,
                    clients: clients,
                  },
                  () => {}
                );
              }
            })
            .then(() => {});
        }
      });
  }
  getRoles = (selectedPage = 1, pageSize = 10) => {
    HTTP.get(
      `/api/RoleMgmt/Get?Keyword=&PageIndex=${selectedPage}&PageTotal=${pageSize}`
    )
      .then((res) => {
        if (res.status === 200) {
          let { payload, totalCount } = res.data;
          this.setState({
            roles: payload,
            totalRecordCount: totalCount,
            loading: false,
            rolesErr: "",
          });
        }
      })
      .catch((err) => {
        this.setState({
          rolesErr: "An Error Occured",
          loading: false,
        });
      });
  };
  toggleComfirm = (e) => {
    this.setState({
      open: !this.state.open,
    });
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  goTo = (campaign) => {
    this.setState({
      selectedCampaign: campaign,
    });
    this.props.history.push({
      pathname: "edit-user",
      state: { campaign },
    });
  };
  togglePermLoading = () => {
    this.setState({
      permLoading: !this.state.permLoading,
    });
  };

  searchUpdated = (term) => {
    this.setState({ searchTerm: term });
  };

  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onChange = (e) => {
    const result = this.state.roles.filter(
      (role) => role.Name === e.target.value
    );

    this.setState(
      {
        [e.target.name]: e.target.value,
        tval: result.length > 0 ? true : false,
        roleId: result.length > 0 ? result[0].roleId : null,
      },
      () => {
        if (this.state.tval) {
          this.togglePermLoading();
          this.props.getPermissions(this.state.roleId).then((res) => {
            this.togglePermLoading();
            this.setState({ extraPermissions: [] });
          });
        }
        const rs = this.state.extraPermissions.filter(
          (permission) => permission === parseInt(this.state.permission)
        );

        if (rs.length < 1) {
          if (this.state.permission !== null) {
            this.setState(
              {
                extraPermissions: [
                  ...this.state.extraPermissions,
                  parseInt(this.state.permission),
                ],
              },
              () => {
                this.setState({ permission: null });
              }
            );
          }
        } else {
          this.setState(
            {
              extraPermissions: this.state.extraPermissions.filter(
                (permission) => permission !== parseInt(this.state.permission)
              ),
            },
            () => {}
          );
        }
        this.clientIdSetter();
      }
    );
  };
  filterClientName = () => {
    this.props.clients.filter((c) => c.clientId === this.state.clientId);
  };
  clientIdSetter = () => {
    let r = this.props.clients.filter(
      (c) => c.clientId === this.state.clientId
    );
    const crs = this.state.clientIds.filter(
      (cid) => cid === this.state.clientId
    );
    if (crs.length < 1) {
      if (this.state.clientId !== null) {
        this.setState(
          {
            clientIds: [...this.state.clientIds, this.state.clientId],
            clientNames: [
              ...this.state.clientNames,
              { id: this.state.clientId, name: r[0]["name"] },
            ],
          },
          () => {
            this.setState({ clientId: null });
          }
        );
      }
    } else {
      this.setState(
        {
          clientIds: this.state.clientIds.filter(
            (clientId) => clientId !== this.state.clientId
          ),
          clientNames: this.state.clientNames.filter(
            (clientName) => clientName.id !== r[0]["clientId"]
          ),
        },
        () => {}
      );
    }
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  addCriteria = (e) => {
    e.preventDefault();
    // localStorage.setItem("criterias", JSON.stringify(""));
    // alert("Adding Criteria");
    let { criteria, criteriaAmount } = this.state;
    if (!criteria || !criteriaAmount) {
      let cr = document.getElementById("criteria");
      cr.focus();
      this.createNotification(
        "error",
        "Criteria and Criteria Amount is required."
      );
      return;
    }
    if (criteria === "Select Criteria") {
      this.createNotification("error", "Criteria is required.");
      return;
    }

    let criteriaVals = {
      id: this.state.criterias.length,
      criteria,
      criteriaAmount,
    };
    let c = this.state.criterias;
    for (let i = 0; i < c.length; i++) {
      const element = c[i];
      if (element.criteria === criteriaVals.criteria) {
        this.createNotification(
          "error",
          criteria + ", as already been selected.."
        );
        return;
      }
    }
    this.setState(
      {
        criterias: [...this.state.criterias, criteriaVals],
      },
      () => {
        //
      }
    );
    this.setState({ criterias: [...this.state.criterias, criteriaVals] });

    this.setState({ criteria: "" });
    this.setState({ criteriaAmount: "" });
  };
  criteriaDelete = (id) => {
    this.setState({
      criterias: this.state.criterias.filter((criteria) => criteria.id !== id),
    });
    this.createNotification("success", "Deleted Successfully.");
  };
  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  addCampain = () => {
    // alert("Adding");
    this.props.history.push("add-campians");
  };

  onSubmit = (e) => {
    if (this.validator.allValid()) {
      let {
        userName,
        email,
        lastName,
        givenName,
        // middleName,
        extraPermissions,
        phoneNumber,
        role,
        clientIds,
      } = this.state;

      let newUser = {
        userName: email,
        email,
        lastName,
        givenName,
        middleName: lastName,
        extraPermissions,
        phoneNumber,
        role,
        clientIds,
      };
      if (newUser.role === "SUPER_ADMIN") {
        // newUser.clientIds = [];
      }

      this.toggleLoading();
      this.props.addUsers(newUser).then((res) => {
        if (typeof res !== "undefined" && typeof res === "object") {
          this.toggleLoading();
          if (res.status === 200) {
            if (res.data.hasErrors === false) {
              this.createNotification("success", res.data.description);
              this.setState({
                userName,
                email: "",
                lastName: "",
                givenName: "",
                middleName: "",
                extraPermissions: [],
                phoneNumber: "",
                role: "SUPER_ADMIN",
                clientIds: [],
              });
              this.props.history.push("users");
            } else {
              this.createNotification("error", res.data.description);
            }
          } else {
            this.createNotification("error", res.data.errors[0]);
          }
        } else {
        }
      });
    } else {
      this.createNotification("error", "Kindly supply all fields.");
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
      // alert();
      // }
    }
  };
  render() {
    const filteredClients = this.props.clients.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );

    return (
      <>
        <div className="newUserBody">
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          <main
            className="container-fluid px-5 newUserMain"
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7">
                <div style={{ display: "flex", flexDirection: "row" }}></div>
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    marginRight: "60%",
                  }}
                  className=""
                >
                  Edit User
                </h4>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 mx-0 py-0">
                    <div className="card my-4 singleHeight">
                      <div className="card-header headerWHite"> Details</div>
                      <div className="card-body">
                        <Form>
                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Last Name
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="lastName"
                              id="lastName"
                              placeholder="Last Name"
                              value={this.state.lastName}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Last Name",
                                this.state.lastName,
                                "required|alpha_space"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              First Name
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="givenName"
                              id="campainTitle"
                              placeholder="First Name"
                              value={this.state.givenName}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "First Name",
                                this.state.givenName,
                                "required|alpha_space"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              htmlFor="description"
                              className="col-sm-4 labell"
                            >
                              Email
                            </Label>
                            <Input
                              className="col-sm-7  textField"
                              placeholder="Email"
                              type="email"
                              name="email"
                              id="description"
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              value={this.state.email}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "email",
                                this.state.email,
                                "required|email"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Phone number
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="tel"
                              name="phoneNumber"
                              id="phoneNumber"
                              placeholder="+234"
                              value={this.state.phoneNumber}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                              maxLength={11}
                            />
                            {/* </div> */}
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "phoneNumber",
                                this.state.phoneNumber,
                                "required|phone|min:7"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleSelect"
                              className="col-sm-4 labell"
                            >
                              Role
                            </Label>
                            <Input
                              type="select"
                              name="role"
                              id="campainType"
                              placeholder="Role "
                              autoComplete="off"
                              className="col-sm-7 textField"
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              defaultValue={"SUPER_ADMIN"}
                              value={this.state.role}
                            >
                              {this.state.roles.map((value) => {
                                return (
                                  <option key={value.CampaignTypeId}>
                                    {value.name}
                                  </option>
                                );
                              })}
                            </Input>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Email",
                                this.state.role,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.state.role !== "SUPER_ADMIN" ? "w-100" : "d-none"
                    }
                  >
                    <div className="col-sm-12 mx-0 pt-0">
                      <div className="card" style={{ height: "auto " }}>
                        <div className="card-header headerWHite">
                          Assign Clients
                        </div>
                        <div className="card-body mx-5 my-3 assign-client">
                          <FormGroup className="row pl-4">
                            <div className="row col-sm-12">
                              <div className="col-sm-8 py-2">
                                {this.state.clientNames.map((c) => (
                                  <span className="badge  px-3 py-2 mx-2 my-2 agenlist">
                                    {c.name}
                                  </span>
                                ))}
                              </div>
                              <div className="col-sm-4 row h-25">
                                <span className="badge  px-3 py-2 mx-2 my-2 agenlist">
                                  {this.state.clientIds.length}
                                </span>
                                <div
                                  style={{
                                    color: "#000",
                                    textAlign: "right",
                                    width: "60%",
                                  }}
                                  className="float-right w-60 h-50 pt-2"
                                >
                                  {this.state.isOpen ? (
                                    <ArrowDropUpIcon
                                      onClick={this.toggle}
                                      style={{
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    />
                                  ) : (
                                    <ArrowDropDownIcon
                                      onClick={this.toggle}
                                      style={{
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <Collapse
                              isOpen={this.state.isOpen}
                              className="col-sm-12 row"
                              style={{
                                color: "#3C3C3C",
                                fontSize: "12px",
                                fontWeight: "normal",
                              }}
                            >
                              <SearchInput
                                className="search-input col-sm-12 row w-100 h-25 searchBar "
                                onChange={this.searchUpdated}
                              />

                              <div
                                className="col-sm-12 row"
                                style={{
                                  color: "#3C3C3C",
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                }}
                              >
                                {filteredClients.map((value) => {
                                  return (
                                    <div
                                      className="custom-control py-2 custom-checkbox col-sm-6"
                                      style={{ fontSize: "10px" }}
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={value.clientId}
                                        name="clientId"
                                        value={value.clientId}
                                        onChange={this.onChange}
                                        checked={
                                          this.state.clientIds.filter(
                                            (v) => v === value.clientId
                                          ).length === 1
                                            ? true
                                            : false
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        for={value.clientId}
                                      >
                                        {value.name}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </Collapse>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-25" style={{ display: "flex" }}>
                  <button
                    className="btn button2 my-4"
                    onClick={() => {
                      this.props.history.push("users");
                    }}
                  >
                    {"Back"}
                  </button>
                  <button
                    className="btn button2 my-4"
                    onClick={this.onSubmit}
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? "Loading..." : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.client.clients,
  permissions: state.user.permissions,
  userClients: state.user.userClients,
  //
});
const mapDispatchToProps = () => ({
  getPermissions,
  getClientList,
  addUsers,
  getUserClients,
  getUserDetailsByID,
});

export default connect(mapStateToProps, mapDispatchToProps())(EditUser);

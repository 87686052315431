import { combineReducers } from "redux";
import auth from "./auth/reducers/authReducer";
import dash from "./dash/reducers/dashReducer";
import campain from "./campain/reducers/campaignReducer";
import client from "./client/reducers/clientReducer";
import user from "./user/reducers/userReducer";
import agent from "./agent/reducers/agentReducer";
import criteria from "./criteria/reducers/criteriaReducer";
import profile from "./profile/reducers/profileReducer";
import wallet from "./wallet/reducers/walletReducer";
import participant from "./participants/reducers/participantReducer";

const reducers = combineReducers({
  auth,
  dash,
  campain,
  client,
  user,
  agent,
  criteria,
  profile,
  wallet,
  participant
});

export default reducers;

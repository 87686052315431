import {
  GET_CAMPAIGNS,
  ADD_CAMPAIGN,
  ADD_BUSS_RULE,
  DELETE_CAMPAIGN,
  CAMPAIGN_LOADING,
  UPDATE_CAMPAIGN,
  GET_CAMPAIGN_FAIL,
  SET_CAMPAIGN_TYPE,
  SET_CAMPAIGN_STATUS,
  SET_CAMPAIGN_TRANSACTION_TYPES,
  SET_REWARDABLE_BANDS
} from "../types";
import { getAccessToken } from "../../../dependency/UtilityFunctions";
const initislState = {
  campaigns: [],
  totalRecordCount: 0,
  loading: false,
  access_token: getAccessToken(),
  getCampaignErr: "",
  campaignType: [],
  campaignStatus: [],
  campaignID: "",
  transactionBasedTypes: [],
  transactionRewardablebands: []
};

export default function (state = initislState, action) {
  switch (action.type) {
    case GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload.payload.returnObjects,
        totalRecordCount: action.payload.payload.totalRecordCount,
        loading: false,
      };
    case DELETE_CAMPAIGN:
      return {
        ...state,
        UPDATE_CAMPAIGN: state.campaigns.filter(
          (campaign) => campaign.id !== action.payload
        ),
      };
    case ADD_CAMPAIGN:
      return {
        ...state,
        campaignID: action.payload
      };
    case ADD_BUSS_RULE:
      return {
        ...state,
        // campaigns: [action.payload, ...state.campaigns]
      };
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        campaigns: [action.payload, ...state.campaigns],
      };
    case GET_CAMPAIGN_FAIL:
      //

      if (action.payload.data !== "") {
        return {
          ...state,
          getCampaignErr: action.payload.data.description
            ? action.payload.data.description
            : action.payload.data.payload.message
            ? action.payload.data.payload.message
            : "An Error Occurred, Please try again.",
        };
      } else {
        return {
          ...state,
        };
      }
    case SET_CAMPAIGN_TYPE:
      return {
        ...state,
        campaignType: action.payload,
      };

      case SET_CAMPAIGN_TRANSACTION_TYPES:
      return {
        ...state,
        transactionBasedTypes: action.payload,
      };
      case SET_REWARDABLE_BANDS:
        return {
          ...state,
          transactionRewardablebands: action.payload,
        };
    case SET_CAMPAIGN_STATUS:
      return {
        ...state,
        campaignStatus: [...action.payload],
      };
    case CAMPAIGN_LOADING:
      return {
        ...state,
        loading: true,
        getCampaignErr: "",
      };
    default:
      return state;
  }
}

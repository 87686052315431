import {
  GET_USERS,
  ADD_USERS,
  USER_LOADING,
  UPDATE_USERS,
  PERMISSION_LOADING,
  GET_PERMISSION,
  GET_USER_CLIENTS,
  SEARCH
} from "./types";
import HTTP from "../http-trips";
import { NotificationManager } from "react-notifications";
// import { tokenConfig } from "./authActions";
// import { returnErrors } from "./errorActions";

export const getUsers = (pge = 1, limit = 10, userType = "all") => (
  dispatch,
  getState
  ) => {
    dispatch(setUserLoading());
    return HTTP.get(
      userType === "all"
        ? `api/usersmgmt/getusers?pageIndex=${pge}&pageSize=${limit}`
        : `api/usersmgmt/getusers?pageIndex=${pge}&pageSize=${limit}&role=marketing_users`
    )
      .then((res) => {
        dispatch(setData(res));
        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }
        let error = JSON.stringify(err);
        return error;
      });
  };

// Get Permission
export const getPermissions = (id = null) => (dispatch, getState) => {
    dispatch(setPermissionLoading());
    let url =
      null === id
        ? `api/UsersMgmt/GetPermissions`
        : `api/UsersMgmt/GetPermissions/${id}`;
    return HTTP.get(url)
      .then((res) => {
        dispatch(setPermissionData(res));
        return res.data;
      })
      .catch((err) => {
        // ;
        //
        if (err.response) {
          return err.response;
        }

        let error = JSON.stringify(err);
        return error;
      });
  };

// Get USer By Id
export const getUserDetailsByID = (id) => (dispatch, getState) => {
  // dispatch(setPermissionLoading());
  return HTTP.get(`api/UsersMgmt/GetUserInfo/${id}`)
    .then((res) => {
      // dispatch({
      //   type: GET_USER_CLIENTS,
      //   payload: res.data
      // });
      return res;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

// Get Permission
export const getUserClients = (id) => (dispatch, getState) => {
  dispatch(setPermissionLoading());
  return HTTP.get(`api/UsersMgmt/GetUserClients/${id}`)
    .then((res) => {
      dispatch({
        type: GET_USER_CLIENTS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const addUsers = (newUsers) => (dispatch, getState) => {
  return HTTP.post("api/UsersMgmt/MapUserInfo", newUsers)
    .then((res) => {
      newUsers.campaignId = res.data.returnObjects;

      dispatch({
        type: ADD_USERS,
        payload: newUsers,
      });
      return res;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const updateUser = (update) => (dispatch, getState) => {
  return HTTP.post("api/UsersMgmt/MapUserInfo", update)
    .then((res) => {
      // ;

      dispatch({
        type: UPDATE_USERS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {});
};

export const deleteUser = (id, callback) => (dispatch) => {
  dispatch(setUserLoading());
  return HTTP.post(`api/UsersMgmt/DeleteUser?userId=${id}`)
    .then((res) => {
        createNotification("success", res?.data?.description);
        callback();
    })

    .catch((err) => {
      createNotification("error", "user could not be deleted at this time");
    });
};

export const setData = (res) => {
  return {
    type: GET_USERS,
    payload: res.data,
  };
};
export const setPermissionData = (res) => {
  return {
    type: GET_PERMISSION,
    payload: res.data,
  };
};
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const setPermissionLoading = () => {
  return {
    type: PERMISSION_LOADING,
  };
};

const createNotification = (type, msg = null) => {
  // return () => {
  switch (type) {
    case "info":
      NotificationManager.info("Info message");
      break;
    case "success":
      NotificationManager.success(msg, "Success!", 3000);
      break;
    case "warning":
      NotificationManager.warning(msg, "Warning!", 3000);
      break;
    case "error":
      NotificationManager.error(msg, "Oops!", 3000);
      break;
    case "xxx":
      NotificationManager.error(msg, "Oops!", 5000, () => {
        alert("callback");
      });
      break;
    default:
      alert();
  }
};

export const search = (value) =>{
  return {type: SEARCH, payload:value};
}

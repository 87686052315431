import React, { Component } from "react";
import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import FilterListIcon from "@material-ui/icons/FilterList";
import { connect } from "react-redux";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

import * as moment from "moment";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Loader from "react-loader-spinner";
import PaginationComponent from "react-reactstrap-pagination";

import "./subaccount.css";
import { getAgents } from "../../../../../redux/agent/agentActions";
import { NotificationManager } from "react-notifications";
import { getActiveClient } from "../../../../../dependency/UtilityFunctions";
class Index extends Component {
  state = {
    sideDrawerOpen: true,
    selectedDate: "",
    startDate: new Date(),
    endDate: new Date(),
    criteria: "",
    criterias: [],
    selectedPage: 1,
    pageSize: 10,
  };
  componentDidMount() {
    let ac = getActiveClient();
    if (!ac) {
      this.createNotification("error", "No assigned client");

      return;
    }
    let { selectedPage, pageSize } = this.state;
    this.props.getAgents(ac.id, selectedPage, pageSize);
  }
  createNotification = (type, msg = null) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  goTo = (url, agent = []) => {
    this.props.history.push({
      pathname: url,
      // search: "?query=abc",
      state: { agent },
    });
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  addCriteria = (e) => {
    e.preventDefault();
    alert("Adding Criteria");
    var newCriterias = [this.state.criteria].concat(this.state.criteria);
    this.setState({ criterias: newCriterias });
  };
  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  newSubAccount = () => {
    this.props.history.push("new-agent");
  };
  // hey

  getClientId = (id) => {
    let { selectedPage, pageSize } = this.state;
    this.props.getAgents(id, selectedPage, pageSize);
  };
  changePageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      let altCid = getActiveClient();
      this.props.getAgents(altCid.id, selectedPage, pageSize);
    });
  };
  handleSelected = (selectedPage) => {
    let { pageSize } = this.state;
    let altCid = getActiveClient();
    this.props.getAgents(altCid.id, pageSize, selectedPage);
    this.setState({ selectedPage: selectedPage });
  };
  render() {
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <ToolBar1
            sendClientId={this.getClientId.bind(this)}
            drawerToggleClickHandler={this.drawerToggleClickHandler}
          />

          <SideDrawer show={this.state.sideDrawerOpen} />

          <main
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7 ">
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    fontSize: "30px",
                  }}
                >
                  Agents
                </h4>
                <div className="" style={{ flex: "1" }}></div>
                <div className="btnCOntainer">
                  <div
                    style={{
                      background: "#FFF",
                      padding: "5pt",
                      borderRadius: "5px",
                      boxShadow: "2px 2px 2px 2px #efefef",
                      height: "36px",
                      marginRight: "10px",
                    }}
                  >
                    <FilterListIcon />
                  </div>
                  <div className="header33" onClick={this.newSubAccount}>
                    Add Agent
                  </div>
                </div>
              </div>
              <div className=" subacc-table container-fluid">
                <div className="paginations d-flex justify-content-end w-100  ">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Tooltip title="Page size" aria-label="Page size">
                          <div
                            style={{
                              background: "#FFF",
                              padding: "5pt",
                              borderRadius: "5px",
                              boxShadow: "2px 2px 2px 2px #efefef",
                              height: "36px",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            {...bindTrigger(popupState)}
                          >
                            {this.state.pageSize}
                          </div>
                        </Tooltip>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(10)}
                          >
                            10
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(25)}
                          >
                            25
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(50)}
                          >
                            50
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(100)}
                          >
                            100
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                  <PaginationComponent
                    totalItems={this.props.totalRecordCount}
                    pageSize={this.state.pageSize}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                  />
                </div>
                <table className="table table-borderless ">
                  <thead>
                    <tr className="theaded">
                      <th scope="col theaded">Name</th>
                      <th scope="col theaded">Referrals</th>
                      {/* <th scope="col theaded">Running cost</th> */}
                      <th scope="col theaded">Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!this.props.loading ? (
                      this.props.agents.map((v) => {
                        return (
                          <tr key={v.customerId}>
                            <td>
                              {v.firstName} {v.lastName}
                            </td>
                            <td>{v.referralCode}</td>
                            {/* <td>
                            <span className={"badge "}>{"Running Cost"}</span>
                          </td> */}
                            <td>{moment(Date()).format("MMM DD, YYYY")}</td>
                            <td>
                              <PopupState
                                variant="popover"
                                popupId="demo-popup-menu"
                              >
                                {(popupState) => (
                                  <React.Fragment>
                                    <button
                                      type="button"
                                      className="btn "
                                      variant="contained"
                                      {...bindTrigger(popupState)}
                                      style={{
                                        color: "#00b8de",
                                        borderColor: "#00b8de",
                                      }}
                                    >
                                      Action
                                    </button>
                                    <Menu {...bindMenu(popupState)}>
                                      <MenuItem
                                        onMouseDown={popupState.close}
                                        onClick={() =>
                                          this.goTo("agent/dashboard", v)
                                        }
                                      >
                                        View
                                      </MenuItem>
                                      {/* <MenuItem
                                        onMouseDown={popupState.close}
                                        onClick={() => this.goTo("agent")}
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem onMouseDown={popupState.close}>
                                        Delete
                                      </MenuItem> */}
                                    </Menu>
                                  </React.Fragment>
                                )}
                              </PopupState>
                            </td>
                          </tr>
                        );
                      })
                    ) : !this.props.getAgentErr ? (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            <Loader
                              type="RevolvingDot"
                              color="#00425f"
                              height={100}
                              width={100}
                              //timeout={3000} //3 secs
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            {this.props.getAgentErr}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="paginations  d-flex justify-content-end w-100  ">
                  <PaginationComponent
                    totalItems={this.props.totalRecordCount}
                    pageSize={10}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  agents: state.agent.agents,
  loading: state.agent.loading,
  totalRecordCount: state.agent.totalRecordCount,
  getAgentErr: state.agent.getAgentErr,
});
const mapDispatchToProps = () => ({ getAgents });

export default connect(mapStateToProps, mapDispatchToProps())(Index);

import {
  ADD_CAMPAIGN,
  CAMPAIGN_LOADING,
  DELETE_CAMPAIGN,
  GET_CAMPAIGNS,
  GET_CAMPAIGN_FAIL,
  ADD_BUSS_RULE,
  SET_CAMPAIGN_TYPE,
  SET_CAMPAIGN_STATUS,
  SET_CAMPAIGN_TRANSACTION_TYPES,
  SET_REWARDABLE_BANDS,
} from "./types";
import HTTP from "../http-trips";
import {
  createNotification,
  getActiveClient,
} from "../../dependency/UtilityFunctions";
let activeCampaign = getActiveClient();

export const getCampaignTypes = () => async (dispatch) => {
  return HTTP.get("api/Campaign/GetCampaignTypes")
    .then((res) => {
      dispatch({
        type: SET_CAMPAIGN_TYPE,
        payload: res.data.payload.returnObjects,
      });
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getCampaignStatuses = () => (dispatch, getState) => {
  return HTTP.get("api/Campaign/GetCampaignStatuses")
    .then((res) => {
      dispatch({
        type: SET_CAMPAIGN_STATUS,
        payload: res.data.payload.returnObjects,
      });
      return res.data.payload.returnObjects;
    })
    .catch((err) => {});
};

export const getCampaigns =
  (cid, pageindex = 1, pagesize = 10) =>
  async (dispatch, getState) => {
    dispatch(setItemsLoading());
    let altCid = getActiveClient();
    if (cid || altCid) {
      return HTTP.get(
        `api/campaign/GetClientCampaigns?pageindex=${pageindex}&pagesize=${pagesize}&ClientId=${
          cid ? cid : altCid.id
        }&typeId=0`
      )
        .then((res) => {
          dispatch(setData(res));
          return res.data;
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: GET_CAMPAIGN_FAIL,
              payload: err.response,
            });
            return err.response;
          }

          //
          let error = JSON.stringify(err);
          return error;
        });
    }
  };
export const getRewardableTransactionTypes =
  (cid, pageindex = 1, pagesize = 10) =>
  (dispatch, getState) => {
    if (cid || activeCampaign) {
      return HTTP.get(
        `api/Clients/GetRewardableTransactionTypes?pageSize=${pagesize}&pageIndex=${pageindex}&${
          cid ? cid : activeCampaign.id
        }`
      )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response) {
            return err.response;
          }

          let error = JSON.stringify(err);
          return error;
        });
    }
  };

export const getCampaignRewardableTransactionTypes = () => async (dispatch) => {
  return HTTP.get("api/Campaign/GetRewardableTransactionType")
    .then((res) => {
      dispatch({
        type: SET_CAMPAIGN_TRANSACTION_TYPES,
        payload: res.data.payload.returnObjects,
      });
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getRewardBands = () => async (dispatch) => {
  return HTTP.get("api/Campaign/GetRewardBands")
    .then((res) => {
      dispatch({
        type: SET_REWARDABLE_BANDS,
        payload: res.data.payload,
      });
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addCampaign = (newBusiness) => async (dispatch) => {
  return HTTP.post("api/Campaign/CreateCampaign", newBusiness)
    .then((res) => {
      newBusiness.campaignId = res.data.returnObjects;
      dispatch({
        type: ADD_CAMPAIGN,
        payload: res.data?.payload?.returnObjects,
      });
      if (res?.data?.errors.length > 0) {
        createNotification("error", res.data?.payload?.message);
      } else {
        createNotification("success", "Campaign successfully created");
      }
      return res;
    })
    .catch((err) => {
      createNotification(
        "error",
        "Couldn't conplete request, Please, try again"
      );
      return err.response;
    });
};

export const addAquisitionCampaign = (newBusiness) => async () => {
  return HTTP.post("api/Campaign/CreateAcquisitionCampaign", newBusiness)
    .then((res) => {
      createNotification("success", "Aquisition Campaign successfully created");
      return res;
    })
    .catch((err) => {
      createNotification(
        "error",
        "Couldn't complete request, Please, try again"
      );
      return err.response;
    });
};

export const addTransactionCampaign = (newBusiness) => async () => {
  return HTTP.post("api/Campaign/CreateTransactionCampaign", newBusiness)
    .then((res) => {
      createNotification(
        "success",
        "Transaction Campaign successfully created"
      );
      return res;
    })
    .catch((err) => {
      createNotification(
        "error",
        "Couldn't complete request, Please, try again"
      );
      return err.response;
    });
};

export const updateCampaignStatus =
  (details, callback) => (dispatch, getState) => {
    return HTTP.post("api/Campaign/UpdateCampaignStatus", details)
      .then((res) => {
        if (res?.data?.hasErrors) {
          createNotification("error",  res?.data?.payload?.message);
        } else {
          createNotification("success", "Campaign Status Successfully updated");
        }
        callback();
        //return res;
      })
      .catch((err) => {
        return err.response;
      });
  };
export const addBussinesRule = (rules) => (dispatch, getState) => {
  return HTTP.post("api/Campaign/BulkAddBusinessRules", rules)
    .then((res) => {
      dispatch({
        type: ADD_BUSS_RULE,
        payload: rules,
      });
      return res;
    })
    .catch((err) => {
      // alert("Error : error");

      return err.response;
    });
};
export const updateCampaign = (update, callback) => (dispatch, getState) => {
  return HTTP.post("api/Campaign/UpdateCampaignDetails", update)
    .then((res) => {
      callback({res: res})
      // ;

      // dispatch({
      //   type: UPDATE_CAMPAIGN,
      //   payload: res.data
      // });
      return res;
    })
    .catch((err) => {
      callback({error: 'error'})
    });
};

export const deleteCampaign = (id) => (dispatch, getState) => {
  return HTTP.post(``, id)
    .then((res) => {
      dispatch({
        type: DELETE_CAMPAIGN,
        payload: id,
      });
      return res.data;
    })

    .catch((err) => {
      return err;
    });
};

export const setData = (res) => {
  return {
    type: GET_CAMPAIGNS,
    payload: res.data,
  };
};
export const setItemsLoading = () => {
  return {
    type: CAMPAIGN_LOADING,
  };
};

import React, { Component } from "react";

import Client from "./components/views/Client/index";
import Login from "./components/views/Login/Index";
import AddBusinessRules from "./components/views/Client/Main/Campaign/BusinessRules";
import EditBusinessRules from "./components/views/Client/Main/Campaign/EditBusinessRules";

import Campains from "./components/views/Client/Main/Campaign/Campains";
import Criterias from "./components/views/Client/Main/criteria/Criteria";
import ViewCampain from "./components/views/Client/Main/Campaign/ViewCampain";
import EditCampaign from "./components/views/Client/Main/Campaign/EditCampaign";
import AddCampaign from "./components/views/Client/Main/Campaign/AddCampaign";

import AddCriteria from "./components/views/Client/Main/criteria/AddCriteria";
// Agent
import AgentDashboard from "./components/views/Client/Navs/AgentDashboard";
import Agent from "./components/views/Client/Main/agent/index";
import NewAgent from "./components/views/Client/Main/agent/newAgent";
import subAccount from "./components/views/Client/Main/subaccount/index";
import newSubAccount from "./components/views/Client/Main/subaccount/newSubAccount";

// Roles
import Role from "./components/views/Client/Main/Role/Role";
import AddRole from "./components/views/Client/Main/Role/AddRole";
import EditRole from "./components/views/Client/Main/Role/EditRole";
import ViewRole from "./components/views/Client/Main/Role/ViewRole";

//
import ReferredCustomers from "./components/views/Client/Main/ClientDashbordDrillDowns/ReferredCustomers";
import ActiveReferredCustomers from "./components/views/Client/Main/ClientDashbordDrillDowns/ActiveReferredCustomers";
import transactingReferredCustomers from "./components/views/Client/Main/ClientDashbordDrillDowns/TransactingReferredCustomers";

// Audit
import Audit from "./components/views/Client/Main/Audit/Index";

// Admin
import AdminDashboard from "./components/views/Client/Navs/AdminDash";
//User
import Users from "./components/views/Client/Main/Users/index";
import NewUsers from "./components/views/Client/Main/Users/NewUsers";
import EditUser from "./components/views/Client/Main/Users/EditUser";
// Client
import ClientAdmin from "./components/views/Client/Main/Client/Index";
import EditClientAdmin from "./components/views/Client/Main/Client/EditClient";
import AddClient from "./components/views/Client/Main/Client/AddClient";

import { getAccessToken, isSignedIn } from "./dependency/UtilityFunctions";
import Can from "./config/Can";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//Profile
import Profile from "./components/views/Profile";

//wallet
import Wallet from "./components/views/Wallet";
import ViewAllCampaignDetails  from "./components/views/Wallet/components/ViewAllCampaignDetails";
import ForgotPassword from "./components/views/Login/ForgotPassword";

import Participants from "./components/views/Participants";
const Permissions = JSON.parse(sessionStorage.getItem("permissionsAndRoles"));

class routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password/reset" component={ForgotPassword} />
          <Route
            path="/manage/profile"
            exact
            component={(props) => (
              // <Can I="manage" a="ClientAdmin">
              <Profile {...props} /> 
              // </Can>
            )}
          />
          {/* Admin -> Client */}
          <Route
            path="/marketer/client"
            exact
            component={(props) => (
              // <Can I="manage" a="ClientAdmin">
              <ClientAdmin {...props} />
              // </Can>
            )}
          />
          {/*  */}
          <Route
            path="/marketer/add-criteria"
            exact
            component={(props) => (
              // <Can I="manage" a="AdminDashboard">
              <AddCriteria {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/admin-dashboard"
            exact
            component={(props) => (
              // <Can I="manage" a="AdminDashboard">
              <AdminDashboard {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/add-client"
            exact
            component={(props) => (
              // <Can I="manage" a="AddClient">
              <AddClient {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/edit-client"
            exact
            component={(props) => (
              // <Can I="manage" a="EditClientAdmin">
              <EditClientAdmin {...props} />
              // </Can>
            )}
          />
          {/* Clients - admin */}
          <Route
            path="/marketer/users"
            exact
            component={(props) => (
              // <Can I="manage" a="Users">
              <Users {...props} />
              // </Can>
            )}
          />
         <Route
            path="/marketer/participants"
            exact
            component={(props) => (
              // <Can I="manage" a="Users">
            <Participants {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/add-user"
            exact
            component={(props) => (
              // <Can I="create" a="NewUsers">
              <NewUsers {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/edit-user"
            exact
            component={(props) => (
              // <Can I="update" a="EditUser">
              <EditUser {...props} />
              // </Can>
            )}
          />
          {/* Role */}
          <Route path="/marketer/roles" exact component={Role} />
          <Route path="/marketer/newRole" exact component={AddRole} />
          <Route path="/marketer/editRole" exact component={EditRole} />
          <Route path="/marketer/viewRole" exact component={ViewRole} />
          {/* ReferredCustomers */}
          <Route
            path="/marketer/referredCustomers"
            exact
            component={ReferredCustomers}
          />
          <Route
            path="/marketer/activeReferredCustomers"
            exact
            component={ActiveReferredCustomers}
          />
          <Route
            path="/marketer/transactingReferredCustomers"
            exact
            component={transactingReferredCustomers}
          />
          {/* Audit */}
          <Route path="/marketer/audit" exact component={Audit} />
          <Route
            path="/marketer/agent/new-subAgent"
            exact
            component={newSubAccount}
          />
          <Route
            path="/marketer/agent/new-subAgent"
            exact
            component={subAccount}
          />
          {/*  */}
          <Route
            path="/marketer/campiagns"
            exact
            component={(props) => (
              // <Can I="manage" a="Campains">
              <Campains {...props} />
              // </Can>
            )}
          />{" "}
          <Route
            path="/marketer/edit-campiagns"
            exact
            component={(props) => (
              // <Can I="update" a="EditCampaign">
              <EditCampaign {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/add-campiagns"
            exact
            component={(props) => (
              // <Can I="create" a="AddCampains">
              <AddCampaign {...props} />
              // </Can>
            )}
          />
          {/*  */}
          <Route
            path="/marketer/view-campiagn"
            exact
            component={(props) => (
              // <Can I="view" a="ViewCampain">
              <ViewCampain {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/add-businessrules"
            exact
            component={(props) => (
              // <Can I="create" a="AddBusinessRules">
              <AddBusinessRules {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/edit-businessrules"
            exact
            component={
              (props) => (
                // <Can I="manage" a="Post" passThrough>
                <EditBusinessRules {...props} />
              )
              // </Can>
            }
          />
          {/* Criteria */}
          <Route
            path="/marketer/criterias"
            exact
            component={(props) => (
              // <Can I="manage" a="Criterias">
              <Criterias {...props} />
              // </Can>
            )}
          />
          {/* Agent */}
          <Route
            path="/marketer/agent/sub-agent"
            exact
            component={(props) => (
              // <Can I="manage" a="subAccount">
              <subAccount {...props} />
              // </Can>
            )}
          />{" "}
          <Route
            path="/marketer/agent"
            exact
            component={(props) => (
              // <Can I="manage" a="Agent">
              <Agent {...props} />
              // </Can>
            )}
          />
          <Route
            path="/marketer/agent/dashboard"
            exact
            component={(props) => (
              <Can I="manage" a="AgentDashboard">
                <AgentDashboard {...props} />
              </Can>
            )}
          />{" "}
          <Route
            path="/marketer/new-agent"
            exact
            component={(props) => (
              <Can I="create" a="NewAgent">
                <NewAgent {...props} />
              </Can>
            )}
          />
          <Route
            path="/marketer/agent/new-subAgent"
            exact
            component={(props) => (
              <Can I="create" a="newSubAccount">
                <newSubAccount {...props} />
              </Can>
            )}
          />{" "}

          <Route
            path="/marketer/card"
            exact
            component={(props) => (
              // <Can I="manage" a="Campains">
             <Wallet {...props} />
              // </Can>
            )}
          />

         <Route
            path="/marketer/card/allcampaigns"
            exact
            component={(props) => (
              // <Can I="manage" a="Campains">
              <ViewAllCampaignDetails {...props} />
              // </Can>
            )}
          />
          {/* <Route
            exact
            path="/admin/dashboard"
            render={(props) => {
              if (getAccessToken() && isSignedIn()) {
                return <Admin {...props} />;
              }
            }}
          /> */}
          <Route
            exact
            path="/marketer/dashboard"
            render={(props) => {
              // if (getAccessToken() && isSignedIn()) {
              return <Client {...props} />;
              // }
            }}
          />
          <Redirect from="/" exact to="/login" />
          <Route
            path="*"
            component={() => {
              localStorage.setItem("activeB", "dashboard");
              if (getAccessToken() && isSignedIn()) {
                return <Redirect from="/" exact to="/marketer/dashboard" />;
              } else {
                return <Redirect from="/" to="/login" />;
              }
            }}
          />
        </Switch>
      </Router>
    );
  }
}

export default routes;

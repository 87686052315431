import React from "react";
import profileImg from "../images/profile-avater.svg";
import dot from "../images/profile-dot.svg";

const ProfileDisplayHeader = ({
  profileUserName,
  profileUserRole,
  ProfileUserBtnText,
  onClickAction,
  submitBtnClicked,
}) => {
  return (
    <div className="profile_info d-flex justify-content-between align-items-center px-4 py-3">
      <div className="profile_info_details d-flex  align-items-center">
        <img
          className="profile_info_image mr-2"
          src={profileImg}
          alt="profile-icon"
        />
        <div className="profile_info_details_breakdown">
          <h6 className="profile_info_name">{profileUserName}</h6>
          <div className="profile_info_role mt-3">
            <small className="prole-text p-1 pr-2">
              {" "}
              <img src={dot} alt="svg-icon" className="px-2 " />
              {profileUserRole}
            </small>
          </div>
        </div>
      </div>
      <div>
        <button
          className={`${
            submitBtnClicked
              ? "profile_info_button"
              : "profile_info_button_clicked"
          } px-5 py-3`}
          onClick={onClickAction}
        >
          {ProfileUserBtnText}
        </button>
      </div>
    </div>
  );
};

export default ProfileDisplayHeader;

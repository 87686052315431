import React, { Component } from "react";
import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { Form, FormGroup, Label, Input } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import "./newsubaccount.css";
import { NotificationManager } from "react-notifications";

import { connect } from "react-redux";

import {
  addAgent,
  getClientCampaign,
} from "../../../../../redux/agent/agentActions";
import { getClients } from "../../../../../dependency/UtilityFunctions";
class newAgent extends Component {
  state = {
    sideDrawerOpen: true,

    clientId: "",
    lastName: "",
    firstName: "",
    fullName: "",
    email: "",
    alias: "",
    referralCode: "",
    campaignId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    customerTypeId: 2,
    Phone: "",
    campaign: "",
    campaigns: [],
    clients: getClients(),
    loading: false,
  };
  validator = new SimpleReactValidator();
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  handleClient = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.clientId) {
          const r = this.state.clients.filter(
            (c) => c.name === this.state.clientId
          );

          // this
          this.toggleLoading();
          this.props.getClientCampaign(r[0]["id"]).then((res) => {
            this.toggleLoading();
            if (res.status === 200) {
              this.setState({ campaigns: res.data.payload.returnObjects });
            } else {
              this.createNotification("error", res.data.description);
            }
          });
        }
      }
    );
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let {
        clientId,
        firstName,
        lastName,
        email,

        referralCode,
        campaignId,
        customerTypeId,

        campaign,
      } = this.state;

      let newAgent = {
        email,
        lastName,
        firstName,
        referralCode,
        customerTypeId,
        clientId,
        alias: firstName,
        campaignId,
      };

      const r = this.state.clients.filter((c) => c.name === clientId);
      const rs = this.state.campaigns.filter((c) => c.title === campaign);

      newAgent.clientId = r[0]["id"];
      newAgent.campaignId = rs[0]["campaignId"];

      this.toggleLoading();
      this.props.addAgent(newAgent).then((res) => {
        this.toggleLoading();
        if (res.status === 200) {
          if (res.data.hasErrors === false) {
            this.createNotification("success", res.data.description);
            this.setState({
              email: "",
              fullName: "",
              referralCode: "",
              customerTypeId: "",
              clientId: "",
              alias: "",
            });
            this.props.history.push("agent");
          } else {
            this.createNotification("error", res.data.description);
          }
        } else {
          this.createNotification(
            "error",
            res.data.title
              ? res.data.title
              : res.data.description
              ? res.data.description
              : "An error ocurred, please try again,"
          );
        }
      });
    } else {
      this.createNotification("error", "Kindly supply all fields.");
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  addCampain = () => {
    this.props.history.push("add-campians");
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  render() {
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <div className="sidebarContentContainer">
            <ToolBar1
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <SideDrawer show={this.state.sideDrawerOpen} />

            <main
              className=" px-5 main pb-5 container-fluid"
              style={{
                flex: "1 1 0%",
              }}
            >
              <div className="contentBody  px-5">
                <div className="col-sm-12 my7 d-flex ">
                  <h4
                    style={{
                      fontWeight: "bold",
                      paddingTop: "10px",
                      fontSize: "30px",
                    }}
                  >
                    Add Agent
                  </h4>
                  <div className="" style={{ flex: "1" }}></div>
                </div>
                {/* <Content />
      {JSON.stringify(props.sideDrawerOpen)} */}

                <div className=" container-fluid d-flex">
                  <div className="col-sm-8">
                    <div className="card card-auto  my-0">
                      <div className="card-header headerWHite">Details</div>
                      <div className="card-body">
                        <Form className="new-sub-acc-form">
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              First Name
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="firstName"
                              id="firstName"
                              placeholder="First Name"
                              value={this.state.firstName}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "firstName",
                                this.state.firstName,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Last Name
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="lastName"
                              id="lastName"
                              placeholder="Last Name"
                              value={this.state.lastName}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "lastName",
                                this.state.lastName,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Email address
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="email"
                              name="email"
                              id="lastname"
                              placeholder="Email Address"
                              value={this.state.email}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Email",
                                this.state.email,
                                "required|email"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Phone number
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="Phone"
                              id="Phone"
                              placeholder="Phone number"
                              value={this.state.Phone}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Phone number",
                                this.state.Phone,
                                "required|phone"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Referral code
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="referralCode"
                              id="referralCode"
                              placeholder="Referral code"
                              value={this.state.referralCode}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Referral Code",
                                this.state.referralCode,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleSelect"
                              className="col-sm-4 labell"
                            >
                              Client
                            </Label>
                            <Input
                              type="select"
                              name="clientId"
                              id="clientId"
                              placeholder="Client "
                              className="col-sm-7 textField"
                              onChange={this.handleClient}
                              onKeyPress={this.onKeyPress}
                              value={this.state.clientId}
                              autoComplete="off"
                            >
                              {this.state.clients.map((value) => {
                                return (
                                  <option key={value.id}>{value.name}</option>
                                );
                              })}
                            </Input>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Client",
                                this.state.clientId,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleSelect"
                              className="col-sm-4 labell"
                            >
                              Campaign
                            </Label>
                            <Input
                              type="select"
                              name="campaign"
                              id="campaign"
                              placeholder="Campaign "
                              className="col-sm-7 textField"
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              value={this.state.campaign}
                              defaultValue={"Select Campaign"}
                              autoComplete="off"
                            >
                              {this.state.loading ? (
                                <option>{"Loading..."}</option>
                              ) : (
                                this.state.campaigns.map((value) => {
                                  return (
                                    <option key={value.campaignId}>
                                      {value.title}
                                    </option>
                                  );
                                })
                              )}
                            </Input>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Campaign",
                                this.state.campaign,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <div className="row w-50 float-right pr-5 my-5">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 p-0">
                              <button
                                className="btn w-100"
                                onClick={this.onSubmit}
                                disabled={this.state.loading}
                              >
                                {this.state.loading
                                  ? "Loading..."
                                  : "Add Agent"}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.client.clients,
  permissions: state.user.permissions,
});
const mapDispatchToProps = () => ({
  addAgent,
  getClientCampaign,
});

export default connect(mapStateToProps, mapDispatchToProps())(newAgent);

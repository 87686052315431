export const GET_USERS = "GET_USERS";
export const ADD_USERS = "ADD_USERS";
export const DELETE_USER = "DELETE_USER";
export const USER_LOADING = "USER_LOADING";
export const UPDATE_USERS = "UPDATE_USERS";
export const PERMISSION_LOADING = "PERMISSION_LOADING";
export const GET_PERMISSION = "GET_PERMISSION";
export const GET_USER_CLIENTS = "GET_USER_CLIENTS";
export const SEARCH = "SEARCH";
//

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { resetPassword } from "../../../redux/auth/authActions";
import { Button, Form, FormGroup, Input } from "reactstrap";
import Mail from "@material-ui/icons/Mail";
import logo from "./999.png";
import "./login.css";
import { createNotification } from "../../../dependency/UtilityFunctions";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const onClickReset = () => {
   
      let mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
      if(email.match(mailformat)){
      setLoading(true);
      const body = {
        email: email,
      };
      resetPassword(body, resp => {
       props.history.push("/")
      }, resp => {
        setLoading(false);
      })
    }else {
      setLoading(false);
      createNotification("error", "Please enter valid email");
    }
   
  };

  return (
    <div className="login-body" style={{ height: "100%" }}>
      <div
        className="container d-flex justify-content-center align-items-center login-height"
        style={{ height: "100%" }}
      >
        <div className="col-sm-12 col-md-6  col-lg-5 lol">
          <div className="login-form-container">
            <Form>
              <div className="mb-4">Password Reset</div>
              <FormGroup className="form-group has-search inputWithIcon">
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="text-field-props"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="off"
                />
                <span className="form-control-feedbackUser">
                  <Mail />
                </span>
              </FormGroup>

              <Button
                className="login-btn btn col-sm-12"
                onClick={onClickReset}
                disabled={loading}
                style={{ textAlign: "center" }}
              >
                {loading ? "Loading..." : "Reset"}
              </Button>
              <small className="forgot__password-link text-center"> <Link to="/"><bold>Login</bold></Link></small>
              <div>
                <img className="logoInter mt-3" alt="logo" src={logo} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = () => ({
  resetPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import React, { Component } from "react";
import { connect } from "react-redux";
import "./css/AutoCompleteText.css";
import "./ToolBar/ToolBar.css";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router";
class AutoCompleteText extends Component {
  constructor(props) {
    super(props);
    this.items = ["David", "Donald", "Yemisi", "Titi", "Bola"];
    this.state = {
      suggestions: [],
      searchValue: "",
    };
  }
  onTextChange = (e) => {
    const { campaigns } = this.props;
    const value = e.target.value;
    let suggestions = [];

    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = campaigns.sort().filter((v) => regex.test(v.title));
    }
    this.setState({
      suggestions,
      searchValue: value,
    });
  };
  goTo = (campaign) => {
    this.setState({
      selectedCampaign: campaign,
    });
    this.props.history.push({
      pathname: "/marketer/view-campiagn",
      state: { campaign },
    });
  };

  suggestionSelected(v) {
    this.setState({
      searchValue: v.title,
      suggestions: [],
    });
    this.goTo(v);
  }
  renderSuggestions() {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul>
        {suggestions.map((item) => (
          <li onClick={() => this.suggestionSelected(item)}>{item.title}</li>
        ))}
      </ul>
    );
  }
  render() {
    let { searchValue } = this.state;
    return (
      <div className="form-group has-search inputWithIcon ">
        <input
          type="text"
          value={searchValue}
          onChange={this.onTextChange}
          placeholder="Search (Campaigns)"
          className="text-field-props-search has-search form-control paddit d-none"
          autoComplete="off"
        />
        <span className="form-control-feedback2 d-none">
          <SearchIcon />
        </span>
        {this.renderSuggestions()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  campaigns: state.campain.campaigns,
});
const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withRouter(AutoCompleteText));

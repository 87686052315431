import moment from "moment";
import React from "react";

const AcquisitionDetails = ({ state }) => {
  return (
    <div>
      <div className="row py-3 px-2">
        <div className="col-4">
          <div className="py-3">
            <span className="trans_refId_label">Campaign Title</span>
            <span className="trans_refId">{state.campainTitle}</span>
          </div>

          <div className="py-3">
            {/* <span className="h6">Budget Limit</span>
            <br></br> */}
            <span className="trans_refId_label">Budget Limit</span>
            <span className="trans_refId">{state.budgetLimit}</span>
          </div>

          <div className="py-3">
            <span className="h6">Acquisition Campaign</span>
            <br></br>
            <span className="trans_refId_label">Reward Referrer</span>
            <span className="trans_refId">{state.referrer  || "Not Specified"}</span>
          </div>
        </div>
        {/*  */}

        <div className="col-4 pb-4">
          <div className="py-3">
            <span className="trans_refId_label">URL</span>
            <span className="trans_refId_url">{state.campainBarner}</span>
          </div>

          <div className="py-3">
            <span></span>
            <span className="trans_refId_label">Budget Threshold</span>
            <span className="trans_refId">{state.budgetThreshold}</span>
          </div>

          <div className="py-3">
            <span></span>
            <span className="trans_refId_label pb-1">Reward Referred User</span>
            <span className="trans_refId">{state.referred || "Not Specified"}</span>
          </div>
        </div>

        <div className="col-4 pb-4">
          <div className="py-3">
            <span className="trans_refId_label">Date</span>
            <span className="trans_refId">
              {moment(state.startDate).format("MMM DD, YYYY")} -{" "}
              {moment(state.endDate).format("MMM DD, YYYY")}
            </span>
          </div>

          <div className="py-3">
            <span></span>
            <span className="trans_refId_label pb-1">Reward Interval</span>
            <span className="trans_refId">{state.rewardInterval || 1}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcquisitionDetails;

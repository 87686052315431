import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { NotificationManager } from "react-notifications";

// Dialogue Hanlder
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import {
  getCampaigns,
  updateCampaign,
  deleteCampaign,
  addCampaign,
} from "../../../../../redux/campain/campaignActions";
import { getClientList } from "../../../../../redux/client/clientActions";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import DatePicker from "react-date-picker";
import SimpleReactValidator from "simple-react-validator";
import { createNotification, getClients } from "../../../../../dependency/UtilityFunctions";
import "./addcampain.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class EditCampaign extends Component {
  constructor() {
    super();
    this.state = {
      sideDrawerOpen: true,
      selectedDate: "",
      startDate: new Date(),
      endDate: new Date(),
      criteria: "",
      criterias: [],
      criteriaAmount: "",
      campainTitle: "",
      campainDescription: "",
      campainBarner: "",
      rewardInterval: "",
      campaignType: "",
      campaignBillsPayment: "",
      rewardAmount: "",
      rewardStyle: "",
      budgetLimit: "",
      threshold: "",
      productClient: "",
      completionStatus: "",
      statusId: "",
      campaignTypeId: "",
      clientIds: getClients(),
      ClientId: "",
      loading: false,
      cid: null,
      ctid: null,
      selectedOption: null,
      open: false,
      agreed: false,
      businessRules: [],
      campaignId: "",
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    const state = this.props.location.state;
    let {
      budget,
      campaignCategoryId,
      campaignId,
      campaignTypeId,
      clientId,
      dateOfCreation,
      description,
      endDate,
      imageUrl,
      startDate,
      title,
      statusId,
      businessRules,
      rewardInterval,
      id,
      threshold
    } = state.campaign;

    let rs = this.state.clientIds.filter((v) => (v.id = clientId));

    this.setState({
      budgetLimit: budget,
      rewardStyle:
        campaignCategoryId === 1 ? "Wallet transfer" : "Call credit top up",
      campaignId: id,
      campaignTypeId,
      campaignType:
      campaignTypeId === 1 ? "Referral Campaign" : "Instant Campaign",
      ClientId: rs.length > 0 ? rs[0]["name"] : clientId,
      dateOfCreation,
      campainDescription: description,
      endDate: new Date(endDate),
      campainBarner: imageUrl,
      rewardInterval: rewardInterval,
      startDate: new Date(startDate),
      campainTitle: title,
      completionStatus: statusId === 1 ? "Draft" : "Not Activated",
      statusId,
      businessRules,
      threshold,  
    });
  }
  toggleComfirm = (e) => {
    this.setState({
      open: !this.state.open,
    });
  };
  send = (agreed) => {
    if (agreed && (this.state.rewardInterval > 0) && (this.state.rewardInterval <= 100)) {
      this.toggleLoading();
      let {
        startDate,
        endDate,
        campainTitle,
        campainDescription,
        campainBarner,
        budgetLimit,
        ClientId,
        clientIds,
        campaignId,
        rewardInterval,
        statusId,
        campaignTypeId,
        id,
        threshold
      } = this.state;

      clientIds.filter((v) => v.name === ClientId);

      let newCampaign = {
        title: campainTitle,
        description: campainDescription,
        statusId,
        imageUrl: campainBarner,
        startDate: moment(this.state.startDate).format('YYYY-MM-DD','HH:mm:ss'),
        endDate:  moment(this.state.endDate).format('YYYY-MM-DD','HH:mm:ss'),
        budgetAmount: parseInt(budgetLimit),
        rewardInterval: parseInt(rewardInterval),
        campaignId,
        threshold: parseInt(threshold)
      };
      this.props.updateCampaign(newCampaign, (res) => {
        this.toggleLoading();
        if (res && res.res?.data) {
              this.createNotification("success", res.res?.data?.payload?.message);
              this.props.history.push("campiagns");
          } else {
          this.createNotification(
            "error",
            "An Error Occourd, Please, try again"
          );
        }
      });
    }else{
      this.createNotification(
        "error",
        "Please enter a valid 'Reward Interval' value between 1-100"
      );
    }
  };
  handleAgreed = () => {
    this.toggleComfirm();
    this.setState(
      {
        agreed: true,
      },
      () => {
        this.send(true);
      }
    );
  };
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  handleChange = (date) => {
    if (date >= new Date()) {
      this.setState({
        startDate: date,
      });
    } else this.createNotification("error", "Invalid Date");
  };
  // handleEndDateChange = (date) => {
  //   if (date > new Date()) {
  //     this.setState({
  //       endDate: date,
  //     });
  //   } else this.createNotification("error", "Invalid Date");
  // };

  handleStartDateChange = (date) => {
    if (new Date(date).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)) {
      this.setState({
        startDate: date,
      });
      if(new Date(date).setHours(0,0,0,0) > new Date(this.state.endDate).setHours(0,0,0,0)) {
        this.setState({
          startDate: new Date(),
        });
        createNotification("error", "Start date cannot be greater than end date")
      }
    } else createNotification("error", "Start date must be greater or equal to today");
  };

  handleEndDateChange = (date) => {
    if (new Date(date).setHours(0,0,0,0) >= new Date(this.state.startDate).setHours(0,0,0,0)) {
      this.setState({
        endDate: date,
      });
    } else createNotification("error", "End date should be greater or equal to start date");
  };
  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  addCriteria = (e) => {
    e.preventDefault();
    // localStorage.setItem("criterias", JSON.stringify(""));
    // alert("Adding Criteria");
    let { criteria, criteriaAmount } = this.state;
    if (!criteria || !criteriaAmount) {
      let cr = document.getElementById("criteria");
      cr.focus();
      this.createNotification(
        "error",
        "Criteria and Criteria Amount is required."
      );
      return;
    }
    if (criteria === "Select Criteria") {
      this.createNotification("error", "Criteria is required.");
      return;
    }

    let criteriaVals = {
      id: this.state.criterias.length,
      criteria,
      criteriaAmount,
    };
    let c = this.state.criterias;
    for (let i = 0; i < c.length; i++) {
      const element = c[i];
      if (element.criteria === criteriaVals.criteria) {
        this.createNotification(
          "error",
          criteria + ", as already been selected.."
        );
        return;
      }
    }
    this.setState(
      {
        criterias: [...this.state.criterias, criteriaVals],
      },
      () => {
        //
      }
    );
    this.setState({ criterias: [...this.state.criterias, criteriaVals] });

    this.setState({ criteria: "" });
    this.setState({ criteriaAmount: "" });
  };
  criteriaDelete = (id) => {
    this.setState({
      criterias: this.state.criterias.filter((criteria) => criteria.id !== id),
    });
    this.createNotification("success", "Deleted Successfully.");
  };
  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  addCampain = () => {
    // alert("Adding");
    this.props.history.push("add-campians");
  };

  onSubmit = async (e) => {
    e.preventDefault();
    // alert(123);

    if (this.validator.allValid()) {
      let { campaignType, completionStatus } = this.state;
      let c = this.props.campaignStatus;
      for (let i = 0; i < c.length; i++) {
        const element = c[i];
        if (element.Name === completionStatus) {
          this.cid = element.CampaignStatuId;
        }
      }
      let d = this.props.campaignType;
      for (let i = 0; i < d.length; i++) {
        const element = d[i];
        if (element.Name === campaignType) {
          this.ctid = element.CampaignTypeId;
        }
      }
      //
      //

      await this.toggleComfirm();
    } else {
      this.createNotification("error", "Kindly supply all fields.");
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  goTo = (url, businessRules) => {
    // businessRules
    this.props.history.push({
      pathname: url,
      // search: "?query=abc",
      state: {
        businessRules: businessRules,
        campaignId: this.state.campaignId,
      },
    });
  };

  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  render() {
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <div className="sidebarContentContainer">
            <ToolBar1
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <SideDrawer show={this.state.sideDrawerOpen} />
            <main
              className="container-fluid px-5 "
              style={{
                flex: "1 1 0%",
              }}
            >
              <div className="contentBody  px-5">
                <div className="col-sm-12 my7">
                  <div style={{ display: "flex", flexDirection: "row" }}></div>
                  <h4
                    style={{
                      fontWeight: "bold",
                      paddingTop: "10px",
                      marginRight: "60%",
                    }}
                    className=""
                  >
                    Edit Campaign
                  </h4>
                </div>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6 mx-0 py-0">
                      <div className="card my-4 singleHeight">
                        <div className="card-header headerWHite">
                          Campaign Details
                        </div>
                        <div className="card-body">
                          <Form>
                            <FormGroup className="row card-font-size">
                              <Label
                                for="exampleEmail"
                                className="col-sm-4 labell "
                              >
                                Campaign title
                              </Label>
                              <Input
                                className="col-sm-7 textField"
                                type="text"
                                name="campainTitle"
                                id="campainTitle"
                                placeholder="Input campaign name"
                                value={this.state.campainTitle}
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}
                                autoComplete="off"
                              />
                              <div className="col-sm-4"></div>
                              <div className="col-sm-7 text-danger p-0 f8">
                                {this.validator.message(
                                  "campainTitle",
                                  this.state.campainTitle,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label
                                for="description"
                                className="col-sm-4 labell"
                              >
                                Description
                              </Label>
                              <Input
                                className="col-sm-7 textArea  textField"
                                type="textarea"
                                name="campainDescription"
                                id="description"
                                value={this.state.campainDescription}
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}
                                autoComplete="off"
                              />
                              <div className="col-sm-4"></div>
                              <div className="col-sm-7 text-danger p-0 f8">
                                {this.validator.message(
                                  "campainTitle",
                                  this.state.campainDescription,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                            <FormGroup className="row card-font-size">
                              <Label
                                for="exampleSelect"
                                className="col-sm-4 labell"
                              >
                                Campaign banner
                              </Label>
                              <Input
                                className="col-sm-7 textField"
                                type="text"
                                name="campainBarner"
                                id="campainBarner"
                                placeholder="Input barner url"
                                value={this.state.campainBarner}
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}
                                autoComplete="off"
                              />
                              <div className="col-sm-4"></div>
                              <div className="col-sm-7 text-danger p-0 f8">
                                {this.validator.message(
                                  "campainBarner",
                                  this.state.campainBarner,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                            {/* <FormGroup className="row">
                              <Label
                                for="exampleSelect"
                                className="col-sm-4 labell"
                              >
                                Campaign Type
                              </Label>

                              <Input
                                type="select"
                                name="campaignType"
                                id="campainType"
                                placeholder="Select Campaign Type"
                                className="col-sm-5 textField"
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}
                                value={this.state.campaignType}
                                autoComplete="off"
                              >
                                <option>Select Campaign Type</option>
                                {this.props.campaignType.map((value) => {
                                  return (
                                    <option key={value.campaignTypeId}>
                                      {value.name}
                                    </option>
                                  );
                                })}
                              </Input>
                              <div className="col-sm-4"></div>
                              <div className="col-sm-7 text-danger p-0 f8">
                                {this.validator.message(
                                  "campainTitle",
                                  this.state.campaignType,
                                  "required"
                                )}
                              </div>
                            </FormGroup> */}
                            <FormGroup className="row">
                              <Label
                                for="description"
                                className="col-sm-4 labell"
                              >
                                Start date
                              </Label>

                              <DatePicker
                                calendarClassName="textField col-sm-12"
                                clearAriaLabel
                                clearIcon=""
                                selected={this.state.startDate}
                                onChange={this.handleStartDateChange}
                                value={this.state.startDate}
                                minDate={this.state.startDate}
                              />
                              <div className="col-sm-4"></div>
                              <div className="col-sm-7 text-danger p-0 f8">
                                {this.validator.message(
                                  "campainTitle",
                                  this.state.startDate,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label
                                for="examplePassword"
                                className="col-sm-4 labell"
                              >
                                End date
                              </Label>
                              <DatePicker
                                calendarClassName="textField col-sm-12"
                                clearAriaLabel
                                clearIcon=""
                                selected={this.state.endDate}
                                onChange={this.handleEndDateChange}
                                value={this.state.endDate}
                                minDate={this.state.startDate}
                                // calendarIcon=""
                              />
                              <div className="col-sm-4"></div>
                              <div className="col-sm-7 text-danger p-0">
                                {this.validator.message(
                                  "campainTitle",
                                  this.state.endDate,
                                  "required"
                                )}
                              </div>
                            </FormGroup>

                            <FormGroup className="row card-font-size">
                              <Label for="exampleSelect" className="col-sm-4 labell">
                                  Reward Interval
                              </Label>
                              <Input
                                className="col-sm-7 textField"
                                type="number"
                                name="rewardInterval"
                                id="rewardInterval"
                                min= "1"
                                max="100"
                                placeholder="Reward Interval"
                                defaultValue={this.state.rewardInterval}
                                onChange={this.onChange}
                                autoComplete="off"
                              />
                               </FormGroup>
                          </Form>
                        </div>
                      </div>
                      <div className="float-right w-25">
                        <button
                          className="btn button2 my-4 w-100"
                          onClick={this.onSubmit}
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? "Loading..." : "Update"}
                        </button>
                      </div>
                      <div className="float-right w-25">
                        <button
                          className="btn button2 my-4 w-100"
                          onClick={() => {
                            this.props.history.push("campiagns");
                          }}
                        >
                          {"Back"}
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-6 mx-0 py-0">
                      <div className="col-sm-12 mx-   ">
                        <div
                          className="card card my-4 singleHeight"
                          style={{ height: "auto" }}
                        >
                          <div className="card-header headerWHite">Reward</div>
                          <div className="card-body">
                            <Form>
                              <FormGroup className="row">
                                <Label
                                  for="exampleSelect"
                                  className="col-sm-4 labell"
                                >
                                  Campaign Category
                                </Label>
                                <Input
                                  type="select"
                                  name="rewardStyle"
                                  id="rewardStyle"
                                  placeholder="Select Campaign Type"
                                  className="col-sm-7 textField "
                                  onChange={this.onChange}
                                  onKeyPress={this.onKeyPress}
                                  value={this.state.rewardStyle}
                                  autoComplete="off"
                                  disabled
                                >
                              
                                  <option>Wallet transfer</option>
                                  {/* <option>Call credit top up </option> */}
                                </Input>
                                <div className="col-sm-4"></div>
                                <div className="col-sm-7 text-danger p-0 f8">
                                  {this.validator.message(
                                    "Reward style",
                                    this.state.rewardStyle,
                                    "required"
                                  )}
                                </div>
                              </FormGroup>

                              <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Budget Limit
                                </Label>

                                <Input
                                  className="col-sm-7 textField"
                                  type="number"
                                  name="budgetLimit"
                                  id="campainTitle"
                                  placeholder="N1,000,000"
                                  onChange={this.onChange}
                                  onKeyPress={this.onKeyPress}
                                  value={this.state.budgetLimit}
                                  autoComplete="off"
                                />
                                <div className="col-sm-4"></div>
                                <div className="col-sm-7 text-danger p-0 f8">
                                  {this.validator.message(
                                    "Budget Limit",
                                    this.state.budgetLimit,
                                    "required|numeric"
                                  )}
                                </div>
                              </FormGroup>
                         

                                <FormGroup className="row">
                                  <Label
                                    for="exampleEmail"
                                    className="col-sm-4 labell"
                                  >
                                    Budget Threshold
                                  </Label>

                                  <Input
                                    className="col-sm-7 textField"
                                    type="number"
                                    name="threshold"
                                    id="threshold"
                                    placeholder="N1,000,000"
                                    onChange={this.onChange}
                                    onKeyPress={this.onKeyPress}
                                    value={this.state.threshold}
                                    autoComplete="off"
                                  />
                                  <div className="col-sm-4"></div>
                                  <div className="col-sm-7 text-danger p-0 f8">
                                    {this.validator.message(
                                      "Budget Limit",
                                      this.state.threshold,
                                      "required|numeric"
                                    )}
                                  </div>
                                </FormGroup>
                              <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Client
                                </Label>

                                <Input
                                  type="select"
                                  name="ClientId"
                                  id="campainType"
                                  placeholder="Select Campaign Type"
                                  className="col-sm-7 textField"
                                  onChange={this.onChange}
                                  onKeyPress={this.onKeyPress}
                                  value={this.state.ClientId}
                                  autoComplete="off"
                                >
                                  <option>Select Client</option>
                                  {this.state.clientIds.map((value) => {
                                    return (
                                      <option key={value.id}>
                                        {value.name}
                                      </option>
                                    );
                                  })}
                                </Input>
                                <div className="col-sm-4"></div>
                                <div className="col-sm-7 text-danger p-0 f8">
                                  {this.validator.message(
                                    "Client",
                                    this.state.ClientId,
                                    "required"
                                  )}
                                </div>
                              </FormGroup>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.toggleComfirm}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ color: "#000" }}>
            {"Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ color: "#000" }}
            >
              Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleComfirm} color="danger">
              No
            </Button>
            <Button
              onClick={this.handleAgreed}
              style={{ background: "#212529" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  campains: state.campain.campaigns,
  campaignType: state.campain.campaignType,
  campaignStatus: state.campain.campaignStatus,
  clients: state.client.clients,
});
const mapDispatchToProps = () => ({
  addCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaigns,
  getClientList,
});

export default connect(mapStateToProps, mapDispatchToProps())(EditCampaign);

import { GET_CLIENTS_LIST, CLIENTS_LIST_LOADING, ADD_CLIENTS } from "./types";
import HTTP from "../http-trips";
import { tokenConfig } from "../../dependency/UtilityFunctions";

export const getClientList = (pge = 1, limit = 10) => (dispatch, getState) => {
  dispatch(clientListLoading());
  try {
    return HTTP.get(
      `api/Clients/getallclients?pageindex=${pge}&pagesize=${limit}`,
      tokenConfig(getState)
    )
      .then((res) => {
        dispatch({
          type: GET_CLIENTS_LIST,
          payload: res.data,
        });
        // ;
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  } catch (error) {}
};

export const addClient = (newClient) => (dispatch, getState) => {
  return HTTP.post("api/Clients/CreateClient", newClient, tokenConfig(getState))
    .then((res) => {
      newClient.campaignId = res.data.returnObjects;

      dispatch({
        type: ADD_CLIENTS,
        payload: newClient,
      });
      return res;
    })
    .catch((err) => {
      // alert("Error : error");

      return err.response;
    });
};

export const updateClient = (newClient) => (dispatch, getState) => {
  return HTTP.post(
    "api/clients/updateclientdetails",
    newClient,
    tokenConfig(getState)
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // alert("Error : error");

      return err.response;
    });
};

export const clientListLoading = () => {
  return {
    type: CLIENTS_LIST_LOADING,
  };
};

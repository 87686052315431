import React, { Component } from "react";
import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { Form, FormGroup, Label, Input } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import "./newsubaccount.css";
import { connect } from "react-redux";
import { getAgents } from "../../../../../redux/agent/agentActions";
import { NotificationManager } from "react-notifications";
import HTTP from "../../../../../redux/http-trips";
class newSubAccount extends Component {
  state = {
    sideDrawerOpen: true,
    Name: "",
    ReferralCode: "",
    AgentId: "",
    AgentName: "",
    loading: false,
  };
  validator = new SimpleReactValidator();
  componentDidMount() {
    this.props.getAgents();
    const cid = this.props.location.state;

    this.setState({ AgentId: cid });
  }
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.createNotification("error", "Kindly supply all fields.");
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.validator.hideMessages();
    let { Name, ReferralCode, AgentId } = this.state;

    let newSubAgent = {
      Name,
      ReferralCode,
      AgentId,
    };
    //: AgentName.match(/\(([^)]+)\)/)[1].trim()

    this.toggleLoading();
    HTTP.post("/api/agents/AddAgentSubAccount", newSubAgent).then((res) => {
      this.toggleLoading();
      if (res.status === 200) {
        if (res.data.hasErrors === false) {
          this.createNotification("success", res.data.description);
          this.props.history.push({
            pathname: "/marketer/agent/sub-agent",
            state: this.state.AgentId,
          });
        } else {
          this.createNotification("error", res.data.description);
        }
      } else {
        this.createNotification(
          "error",
          res.data.title
            ? res.data.title
            : res.data.description
            ? res.data.description
            : "An error ocurred, please try again,"
        );
      }
    });
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />

          <main
            className=" px-5 main pb-5 container-fluid"
            style={{
              marginTop: "64px",
              fontFamily: "Poppins",
              width: "85%",
              paddingLeft: "0pt",
              marginLeft: "14%",
              zIndex: "1",
              background: "#FFF",
            }}
          >
            <div className="col-sm-12 my7 d-flex ">
              <h4
                style={{
                  fontWeight: "bold",
                  paddingTop: "10px",
                  fontSize: "30px",
                }}
              >
                Add a Subaccount
              </h4>
              <div className="" style={{ flex: "1" }}></div>
            </div>

            <div className=" container-fluid d-flex justify-content-around">
              <div className=" col-sm-6">
                <div className="new-sub-account-img my-0">
                  <img
                    src="https://res.cloudinary.com/mentor-africa-project/image/upload/v1573336248/NoPath_-_Copy_4_2x.png"
                    // src="https://res.cloudinary.com/mentor-africa-project/image/upload/v1573336330/NoPath_-_Copy_4.png"
                    alt="Barner"
                  />
                </div>
                <div className="new-sub-account-desc my-0 p-5 float-right">
                  <h4 className="new-sub-acc-desc-head ">
                    What is a subaccount?
                  </h4>
                  <p>
                    Referred users become activated once they complete a bill
                    payment, funds transfer or airtime recharge (minimum of
                    NGN500). Encourage friends who sign up with your link to
                    complete their first transaction.
                    <br />
                    <br />
                    Send unique referral links to friends and family members.
                    First 10,000 Referrers would earn NGN1,000 for the first 5
                    people activated. This would be redeemed via Verve eCash.
                    <br />
                    <br />
                    Enjoy the reward of your hard work. Keep an eye on the
                    Referrals Leaderboard and remember to stay in the race for
                    the NGN1,000,000 grand prize. Everyone is a winner!
                    <br />
                    <br />
                    Earn NGN20 for every funds transfer. NGN20 for every bill
                    payment and 2% for airtime recharge done by your network.
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-auto  my-0">
                  <div className="card-header headerWHite">Details</div>
                  <div className="card-body">
                    <Form className="new-sub-acc-form">
                      <FormGroup className="row card-font-size">
                        <Label for="exampleEmail" className="col-sm-4 labell ">
                          Name
                        </Label>

                        <Input
                          className="col-sm-7 textField"
                          type="text"
                          name="Name"
                          id="Name"
                          placeholder="Name"
                          value={this.state.Name}
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                          autoComplete="off"
                        />
                        <div className="col-sm-4"></div>
                        <div className="col-sm-7 text-danger p-0 f8">
                          {this.validator.message(
                            "Name",
                            this.state.Name,
                            "required"
                          )}
                        </div>
                      </FormGroup>

                      <FormGroup className="row card-font-size">
                        <Label for="Nickname" className="col-sm-4 labell ">
                          ReferralCode
                        </Label>

                        <Input
                          className="col-sm-7 textField"
                          type="text"
                          name="ReferralCode"
                          id="ReferralCode"
                          placeholder="ReferralCode"
                          value={this.state.ReferralCode}
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                          autoComplete="off"
                        />
                        <div className="col-sm-4"></div>
                        <div className="col-sm-7 text-danger p-0 f8">
                          {this.validator.message(
                            "ReferralCode",
                            this.state.ReferralCode,
                            "required"
                          )}
                        </div>
                      </FormGroup>
                      {/* <FormGroup className="row">
                        <Label for="exampleEmail" className="col-sm-4 labell">
                          Agent
                        </Label>
                        <Input
                          type="select"
                          name="AgentName"
                          id="AgentName"
                          placeholder="Select Campaign Type"
                          className="col-sm-7 textField"
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                          value={this.state.AgentName}
                        >
                          <option>Select Agent</option>
                          {this.props.agents.map((value) => {
                            return (
                              <option
                                key={value.customerId}
                              >{`${value.firstName} ${value.lastName} ( ${value.customerId} ) `}</option>
                            );
                          })}
                        </Input>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-7 text-danger p-0 f8">
                          {this.validator.message(
                            "Agent",
                            this.state.AgentName,
                            "required"
                          )}
                        </div>
                      </FormGroup> */}
                      {/* <FormGroup className="row card-font-size">
                        <Label for="exampleEmail" className="col-sm-4 labell ">
                          Lastname
                        </Label>

                        <Input
                          className="col-sm-7 textField"
                          type="text"
                          name="lastname"
                          id="lastname"
                          placeholder="Lastname"
                          value={this.state.lastname}
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                        />
                        <div className="col-sm-4"></div>
                        <div className="col-sm-7 text-danger p-0 f8">
                          {this.validator.message(
                            "campainTitle",
                            this.state.lastname,
                            "required|alpha"
                          )}
                        </div>
                      </FormGroup> */}
                      {/* <FormGroup className="row card-font-size">
                        <Label for="exampleEmail" className="col-sm-4 labell ">
                          Phone number
                        </Label>
                        <div className="col-sm-8 row">
                          <Input
                            type="select"
                            placeholder="+234"
                            className="col-sm-3 h-100"
                          >
                            <option>+234</option>
                            <option>+235</option>
                          </Input>
                          <Input
                            className="col-sm-8 textField ml-2"
                            type="tel"
                            name="campainTitle"
                            id="campainTitle"
                            placeholder=""
                            value={this.state.campainTitle}
                            onChange={this.onChange}
                            onKeyPress={this.onKeyPress}
                          />
                        </div>
             
                      </FormGroup> */}
                      {/* <FormGroup className="row card-font-size">
                        <Label for="exampleEmail" className="col-sm-4 labell ">
                          Email
                        </Label>
                  
                        <Input
                          className="col-sm-7 textField"
                          type="email"
                          name="campainTitle"
                          id="campainTitle"
                          placeholder="Email"
                          value={this.state.campainTitle}
                          onChange={this.onChange}
                          onKeyPress={this.onKeyPress}
                        />
                    
                      </FormGroup> */}
                      {/* <FormGroup className="row card-font-size">
                        <div className="col-sm-4"></div>
                        <div
                          className="pl-4"
                          style={{
                            fontSize: "12px",
                            fontWeight: "normal",
                            color: "#495057"
                          }}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input "
                            id={"value.id"}
                            name="permission"
                            onChange={this.onChange}
                            value={"value.id"}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"value.id"}
                          >
                            {"value.description"}
                          </label>
                        </div>
                      </FormGroup> */}

                      <div className="row ">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-7 p-0 d-flex">
                          <button
                            className="btn  "
                            onClick={() =>
                              this.props.history.push({
                                pathname: "/marketer/agent/sub-agent",
                                state: this.state.AgentId,
                              })
                            }
                          >
                            {"Back"}
                          </button>
                          <button
                            className="btn  "
                            onClick={this.onSubmit}
                            disabled={this.state.loading}
                          >
                            {this.state.loading ? "Loading..." : "Add"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  agents: state.agent.agents,
  loading: state.agent.loading,
  totalRecordCount: state.agent.totalRecordCount,
  getAgentErr: state.agent.getAgentErr,
});
const mapDispatchToProps = () => ({ getAgents });

export default connect(mapStateToProps, mapDispatchToProps())(newSubAccount);

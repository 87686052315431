import {
  GET_WALLET_BALANCE,
  GET_TRANSACTION_HISTORY,
  GET_CAMPAIGN_SUMMARY,
  GET_ALL_TRANSACTION_HISTORY,
} from "./types";
import { createNotification } from "../../dependency/UtilityFunctions";
import HTTP from "../http-trips";

//GET_CAMPAIGNS_LIMITS,

export const getWalletHistory =
  (pageindex = 1, pagesize = 10, keyword, startDate, endDate, callback) =>
  async (dispatch) => {
    return HTTP.get(
      `api/Wallet/GetWalletTransactions?KeyWord=${keyword}&StartDate=${startDate}&EndDate=${endDate}&pageindex=${pageindex}&pagesize=${pagesize}`
    )

      .then((res) => {
        dispatch(setData(res));

        return res;
      })
      .catch((err) => {
        if (err.response) {
          return err.response;
        }

        let error = JSON.stringify(err);
        return error;
      });
  };

export const getAllWalletHistory = () => async (dispatch) => {
  return HTTP.get(`api/Wallet/GetWalletTransactions`)

    .then((res) => {
      dispatch({
        type: GET_ALL_TRANSACTION_HISTORY,
        payload: res.data,
      });

      return res;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const getWalletBalance = () => async (dispatch) => {
  return HTTP.get(`api/Wallet/GetBalance`)

    .then((res) => {
      dispatch({
        type: GET_WALLET_BALANCE,
        payload: res.data.payload,
      });
      return res;
    })
    .catch((err) => {
      if (err.response) {
        createNotification(
          "error",
          "could not get wallet balance, Please try again later"
        );
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const getCampaignSummary =
  (pageindex = 1, pagesize = 10) =>
  async (dispatch) => {
    return HTTP.get(
      `api/Campaign/GetCampaignSummary?pageindex=${pageindex}&pagesize=${pagesize}`
    )

      .then((res) => {
        dispatch({
          type: GET_CAMPAIGN_SUMMARY,
          payload: res.data?.payload,
        });

        return res;
      })
      .catch((err) => {
        if (err.response) {
          createNotification(
            "error",
            "could not fetch campaign details, Please try again later"
          );
          return err.response;
        }

        let error = JSON.stringify(err);
        return error;
      });
  };

export const setData = (res) => {
  return {
    type: GET_TRANSACTION_HISTORY,
    payload: res.data,
  };
};

export const mockCampaignsData = [
  { id: 1, loading: "loading.." },
  { id: 2, loading: "loading.." },
  { id: 3, loading: "loading.." },
];


import ls from "local-storage";
import { NotificationManager } from "react-notifications";
import axios from 'axios'
import HTTP from "../redux/http-trips"
// import store from "../redux/store";

export const getConfig = () => {
  let cachedConfig = window.sessionStorage.getItem('config');
  if(cachedConfig){
      let parsedConfig = JSON.parse(cachedConfig);
      HTTP.defaults.baseURL = `${parsedConfig?.referralBaseUrl}/`
      return parsedConfig;
  }

  return axios.get("/config")
    .then((res) => {
      window.sessionStorage.setItem("config", JSON.stringify(res.data));
      HTTP.defaults.baseURL = `${res.data?.referralBaseUrl}/`
      return res.data;
    })

    .catch((err) => {
      return err.response;
    });
};

export const getFutureDate = (numberOfDaysToAdd) => {
  var now = new Date();
  now.setDate(now.getDate() + numberOfDaysToAdd);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  if (month.toString().length === 1) {
    month = "0" + month;
  }
  if (day.toString().length === 1) {
    day = "0" + day;
  }
  if (hour.toString().length === 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length === 1) {
    minute = "0" + minute;
  }
  if (second.toString().length === 1) {
    second = "0" + second;
  }
  // var dateTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
  var futuredate = year + "-" + month + "-" + day;
  // var futuredate = day+'/'+month+'/'+year;
  return futuredate;
};

export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item._date] = item;
    return obj;
  }, {});
export const resetLs = async () => {
  await ls.remove("LoggedInState"); //loggedInUserDetails
  await ls.remove("loggedInUserDetails"); //
};
export const LogoutNow = async () => {
  window.location.href = "/login";
  await ls.remove("activeB");
  resetLs();
  //window.location.reload();
};
export const ReadableDate = (isoformat) => {
  var readable = new Date(isoformat); // When we pass the ISO format to the JS Date constructor, the return is "Fri Jul 04 2014 21:06:08 GMT-0400 (Eastern Daylight Time)"
  var m = readable.getMonth(); // returns 6 (note that this number is one less than the number of the month in isoformat)
  var d = readable.getDate(); // returns 15
  var y = readable.getFullYear(); // returns 2012

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // we get the text name of the month by using the value of m to find the corresponding month name
  var mlong = months[m];

  var fulldate = mlong + " " + d + ", " + y;
  return fulldate;
};

export const GetPersistData = (key) => {
  let ReturnedString = ls.get(key);
  if (ReturnedString) {
    return ReturnedString;
  } else {
    return false;
  }
};
export const GetCurrentUserDetails = () => {
  let Userdetails = GetPersistData("loggedInUserDetails");
  return Userdetails;
};

export const isSignedIn = () => {
  let ReturnedString = GetPersistData("LoggedInState");
  if (ReturnedString !== false) {
    return ReturnedString.details.isLoggedIn;
  } else {
    return false;
  }
};
export const getUserType = () => {
  let ReturnedString = GetPersistData("LoggedInState");

  if (ReturnedString !== false) {
    return ReturnedString.details.major;
  } else {
    return "";
  }
};
export const getAccessToken = () => {
  let ReturnedString = GetPersistData("LoggedInState");

  // return;
  if (ReturnedString !== false) {
    return ReturnedString.details.access_token;
  } else {
    return "";
  }
};

export const getActiveClient = () => {
  let ReturnedString = GetPersistData("LoggedInState");
  if (ReturnedString !== false) {
    return ReturnedString.details.activeClient;
  } else {
    return "";
  }
};
export const getClients = () => {
  let ReturnedString = GetPersistData("LoggedInState");
  if (ReturnedString !== false) {
    return ReturnedString.details.clients;
  } else {
    return "";
  }
};
export const getPermissions = () => {
  let ReturnedString = GetPersistData("LoggedInState");
  if (ReturnedString !== false) {
    return ReturnedString.details.permission;
  } else {
    return "";
  }
};
export const PersistData = (Mykey, data, construct_data = true) => {
  if (construct_data === true) {
    let data2save = {
      isAvailable: true,
      details: data,
    };
    ls.set(Mykey, data2save);
  } else {
    ls.set(Mykey, data);
  }
};
export const updatePersistData = (data) => {
  let myKey = "LoggedInState";
  let ReturnedString = GetPersistData(myKey);
  ReturnedString.details.activeClient = data;
  ls.set(myKey, ReturnedString);
};

export const tokenConfig = (getState) => {
  const access_token = getAccessToken();
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (access_token) {
    config.headers["Authorization"] = "Bearer " + access_token;
  }

  return config;
};

export const createNotification = (type, msg = null) => {
  // return () => {
  switch (type) {
    case "info":
      NotificationManager.info("Info message");
      break;
    case "success":
      NotificationManager.success(msg, "Success!", 3000);
      break;
    case "warning":
      NotificationManager.warning(msg, "Warning!", 3000);
      break;
    case "error":
      NotificationManager.error(msg, "Oops!", 3000);
      break;
    case "xxx":
      NotificationManager.error(msg, "Oops!", 5000, () => {
        alert("callback");
      });
      break;
    default:
    // alert();
    // }
  }
};

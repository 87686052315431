import {
  GET_USERS,
  ADD_USERS,
  DELETE_USER,
  USER_LOADING,
  UPDATE_USERS,
  PERMISSION_LOADING,
  GET_PERMISSION,
  GET_USER_CLIENTS,
  SEARCH,
} from "../types";
import { getAccessToken } from "../../../dependency/UtilityFunctions";
const initislState = {
  users: [],
  totalCount: 0,
  permissions: [],
  loading: false,
  uCLoading: false,
  userClients: [],
  searchField: "",
  access_token: getAccessToken(),
};

export default function (state = initislState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload.payload,
        totalCount: action.payload.totalCount
          ? action.payload.totalCount
          : state.totalCount,
        loading: false,
      };
    case SEARCH:
      return {
        ...state,
        searchField: action.payload,
        users: state?.searchField
          ? state.users.filter(
              ({id, givenName}) =>
                id?.toLowerCase().includes(state.searchField.toLowerCase()) ||
                givenName
                  ?.toLowerCase()
                  .includes(state.searchField.toLowerCase())
            )
          : state.users,

        loading: false,
      };
    case GET_PERMISSION:
      return {
        ...state,
        permissions: action.payload.payload,
        loading: false,
      };
    case GET_USER_CLIENTS:
      return {
        ...state,
        userClients: action.payload.payload,
        uCLoading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        UPDATE_CAMPAIGN: state.campaigns.filter(
          (campaign) => campaign.id !== action.payload
        ),
      };
    case ADD_USERS:
      return {
        ...state,
      };
    case UPDATE_USERS:
      return {
        ...state,
        campaigns: [action.payload, ...state.campaigns],
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PERMISSION_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

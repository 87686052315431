import React from 'react';

const ProgressBar = ({percentage}) => {
  const fullPercentage = 100 - percentage;
  return <div
  className="progress-component"
>
  <div
    className="progress"
    style={{
      height: "0.5em",
      borderRadius: "0%",
    }}
  >
    <div
      className="progress-bar"
      role="progressbar"
      style={{
        width: `${fullPercentage}%`,
        backgroundColor:
        fullPercentage < 50
            ? "#EC9B40"
            : "#74C965",
        borderRadius: "none",
      }}
      aria-valuenow="30"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
    <div
      className="campaign_progress2 progress-bar"
      role="progressbar"
      style={{
        width: `${percentage}%`,
        backgroundColor:
        fullPercentage < 50
            ? "#FDED94"
            : "#BEF2B9",
        borderRadius: "none",
      }}
      aria-valuenow="20"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>

  <div
    className="campaign-control"

  ></div>
</div>;
};

export default ProgressBar;

import {
  GET_DASH_DETAILS,
  DASH_DETAILS_LOADING,
  GET_LEADER_BOARD_DETAILS,
  GET_LEADER_BOARD_DETAILS_LOADING,
  GET_LEADER_BOARD_DETAILS_ERR,
  GET_DASH_DETAILS_FAIL,
} from "./types";
import HTTP from "../http-trips";
import {
  getActiveClient,
  tokenConfig,
} from "../../dependency/UtilityFunctions";

export const getDashDetails = (cid) => (dispatch, getState) => {
  let activeClient = getActiveClient();
  if (cid || activeClient) {
    dispatch(setDashDetailsLoading());
    return HTTP.get(
      `api/clients/GetClientCampaignSummary?CampaignTypeId=1&ClientId=${
        cid ? cid : activeClient.id
      }`,
      tokenConfig(getState)
    )
      .then((res) => {
        dispatch({
          type: GET_DASH_DETAILS,
          payload: res.data,
        });
        return res;
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: GET_DASH_DETAILS_FAIL,
            payload: err.response,
          });
          return err.response;
        }

        let error = JSON.stringify(err);
        return error;
      });
  }
};

export const getDashLeaderBoardDetails = (cid) => (dispatch, getState) => {
  dispatch(setLeaderDetailsLoading());
  let clientId = "d72b08c9-ee4c-46f7-a142-6f3a9f58fffe";
  let url = `api/clients/GetClientLeaderBoardStats?clientId=${
    cid ? cid : clientId
  }&pageIndex=1&pageSize=10`;
  return HTTP.get(url, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_LEADER_BOARD_DETAILS,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: GET_LEADER_BOARD_DETAILS_ERR,
        payload: err.response,
      });
      return err.response;
    });
};

export const setDashDetailsLoading = () => {
  return {
    type: DASH_DETAILS_LOADING,
  };
};
export const setLeaderDetailsLoading = () => {
  return {
    type: GET_LEADER_BOARD_DETAILS_LOADING,
  };
};

import React, { Component } from "react";
import { NotificationContainer } from "react-notifications";
import { getConfig } from "./dependency/UtilityFunctions";
import "./App.css";
import Routes from "./routes";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Provider } from "react-redux";
import store from "./redux/store";
import { AbilityContext } from "./config/Can";
import ability from "./config/ability";

class App extends Component {

 componentDidMount(){
    getConfig()
  }

  render() {
    return (
      <div className="App" style={{ height: "100%", background: "#fff" }}>
        <Provider store={store}>
          {/* <Provider store={configureStore()}> */}
          <AbilityContext.Provider value={ability}>
            <PerfectScrollbar>
              <Routes />
            </PerfectScrollbar>
            <NotificationContainer />
          </AbilityContext.Provider>
        </Provider>
      </div>
    );
  }
}

export default App;

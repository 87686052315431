import React, { Component } from "react";
import { connect } from "react-redux";
import ToolBar1 from "../Client/Navs/ToolBar/ToolBar1";
import SideDrawer from "../Client/Navs/SideDrawer/SideDrawer";
import "./participant.css";
import {
  getParticipants,
  getParticipant,
  getParticipantAnalytics,
  getParticipantsAnalytics
} from "../../../redux/participants/participantsActions";
import ViewParticipants from "./components/ViewParticipants";
import peopleIconBlue from "./images/people-blue.svg";
import peopleIconGreen from "./images/people-green.svg";
import peopleIconRed from "./images/people-red.svg";
import ViewParticipant from "./components/ViewParticipant";
import { createNotification, getActiveClient } from "../../../dependency/UtilityFunctions";

export class index extends Component {
  myRef = React.createRef();
  timeout;

  state = {
    selectedPage: 1,
    pageSize: 10,
    sideDrawerOpen: true,
    startDate: new Date(),
    endDate: new Date(),
    showDateRange: false,
    types: [10, 20, 50, 100],
    loader: false,
    showProfile: false,
    selectedPage: 1,
    pageSize: 10,
    search: "",
    searchReferralParticipant: "",
    email: "",
    searchCount: 0,
    lastName: "",
    firstName: "",
    types: [10, 20, 50, 100],
  };

   componentDidMount() {
    let altCid = getActiveClient();

    if (!altCid) {
      createNotification("error", "No assigned client");
      return;
    }

    let { selectedPage, pageSize } = this.state;
    const [search] = [""];

    localStorage.setItem("activeB", "participants");

     this.props.getParticipants(altCid.id, search, selectedPage, pageSize);
     this.props.getParticipantsAnalytics(altCid.id);
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  getClientId = (id) => {
    let { selectedPage, pageSize, search, showProfile, searchReferralParticipant, email} = this.state;
    if(showProfile){
     this.props.getParticipant( id, email, searchReferralParticipant, selectedPage, pageSize);
    }else
    {
      this.props.getParticipants(id, search, selectedPage, pageSize);
    }
   
  };

  handleSelected =  (selectedPage, from) => {
    let altCid = getActiveClient();
    const [search] = [""];
    if(from === "participants--pagination"){
     this.props.getParticipants(
      altCid.id,
      search,
      selectedPage,
      this.state.pageSize
    );
    this.setState({ selectedPage: selectedPage });
  }
    if(from === "participant--pagination"){
      this.props.getParticipant(altCid.id, this.state.email, search, selectedPage, this.state.pageSize);
      this.setState({ selectedPage: selectedPage, searchReferralParticipant: "" });
    }
  };

  changePageSize =  (value, name) => {
    let altCid = getActiveClient();
    const [search] = [""];
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      if(name === "participants--pageSize"){
      this.props.getParticipants(altCid.id, search, selectedPage, pageSize);}
      if(name === "participant--pageSize"){
        this.props.getParticipant(altCid.id, this.state.email, search, selectedPage, pageSize);}
    });
  };

  onChange = (e) => {
    let altCid = getActiveClient();
    let [ selectedPage ] = [1]
    this.setState({selectedPage: selectedPage});
    if (!altCid) {
      createNotification("error", "No assigned client");

      return;
    }
    let { pageSize, email } =
      this.state;
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "search") {
      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(
       this.runSearchForParticipants(altCid.id, e.target.value || "", selectedPage, pageSize),   
        600
      );
    }

    if (e.target.name === "searchReferralParticipant") {
      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(
       this.runSearchForParticipant(altCid.id, email,  e.target.value || "", selectedPage, pageSize),
        600
      );
    }
  };

  runSearchForParticipants = (id, searchValue, selectedPage, pageSize) => {
    this.props.getParticipants(id, searchValue, selectedPage, pageSize)
  }

  runSearchForParticipant = (id, email, searchValue, selectedPage, pageSize) => {
    this.props.getParticipant(id, email, searchValue, selectedPage, pageSize)
  }

  showProfile =  (email, firstName, lastName) => {
    let altCid = getActiveClient();

    if (!altCid) {
      createNotification("error", "No assigned client");

      return;
    }

    let {searchReferralParticipant } = this.state;
    let [ selectedPage, pageSize ] = [1, 10]
    this.setState({selectedPage: selectedPage, pageSize: pageSize});
    this.setState({
      showProfile: true,
      email: email,
      firstName: firstName,
      lastName: lastName,
      clientId: altCid.id
    });
     this.props.getParticipant(
      altCid.id,
      email,
      searchReferralParticipant,
      selectedPage,
      pageSize
    );
    this.props.getParticipantAnalytics(altCid.id, email)
  };

  goBack =  () => {
    this.setState({
      showProfile: false,
      search: "",
      searchReferralParticipant: ""
    });

    let altCid = getActiveClient();

    if (!altCid) {
      createNotification("error", "No assigned client");

      return;
    }

    let [ selectedPage, pageSize ] = [1, 10]
    this.setState({selectedPage: selectedPage, pageSize: pageSize});
    const [search] = [""];

    localStorage.setItem("activeB", "participants");

     this.props.getParticipants(altCid.id, search, selectedPage, pageSize);
  };

  render() {
    const { selectedPage, pageSize, email, firstName, lastName, types } = this.state;
    const {
      participantAnalytics,
      participantsAnalytics,
      totalParticipantCount,
      totalParticipantsCount,
      participants,
      participant,
      loading
    } = this.props.participant;

    return (
      <>
        <div className="participant-container">
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler}  sendClientId={this.getClientId}/>
          <SideDrawer show={this.state.sideDrawerOpen}  goBackParticipants={this.goBack}/>

          {this.state.showProfile ? (
            <ViewParticipant
              goBack={this.goBack}
              searchReferralParticipant={this.state.searchReferralParticipant}
              participant={participant}
              email={email}
              firstName={firstName}
              lastName={lastName}
              loading={loading}
              onChange={this.onChange}
              participantAnalytics={participantAnalytics}
              handleSelected={this.handleSelected}
              selectedPage={selectedPage}
              pageSize={pageSize}
              types={types}
              changePageSize={this.changePageSize}
              totalParticipantCount={totalParticipantCount}
            />
          ) : (
            <main className="container-fluid px-5">
              <div className="contentBody">
                <div className="col-sm-12 my7 px-3">
                  <div className="participant_header">
                    <h4 className="participant_heading pb-1">
                      Participant Referrals
                    </h4>
                  </div>

                  <div className="participants__referal--cards d-flex justify-content-between">
                    <div className="column">
                      <div className="participants__referal--icon-container">
                        <img
                          src={peopleIconBlue}
                          alt="icon"
                          className="participants__referal--icon"
                        />
                      </div>

                      <span className="participants__referal--text-label">
                        Total Referrals
                      </span>
                      <h5 className="participants__referal--amount">{participantsAnalytics?.totalReferral ||  0}</h5>
                    </div>
                    <div className="column">
                      {" "}
                      <div className="participants__referal--icon-container">
                        <img
                          src={peopleIconRed}
                          alt="icon"
                          className="participants__referal--icon"
                        />
                      </div>
                      <span className="participants__referal--text-label">
                        Total Referrers
                      </span>
                      <h5 className="participants__referal--amount">{participantsAnalytics?.totalReferrers || 0}</h5>
                    </div>
                    <div className="column">
                      {" "}
                      <div className="participants__referal--icon-container">
                        <img
                          src={peopleIconGreen}
                          alt="icon"
                          className="participants__referal--icon"
                        />
                      </div>
                      <span className="participants__referal--text-label">
                        Total Referred Users
                      </span>
                      <h5 className="participants__referal--amount">{participantsAnalytics?.totalReferree ||  0}</h5>
                    </div>
                  </div>

                  <div className="participant_header mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="participant_heading">All participants</h5>

                      <input
                        type="text"
                        className="participants__search"
                        placeholder="Search list"
                        name="search"
                        autoComplete="off"
                        onChange={(e) => this.onChange(e)}
                        value={this.state.search}
                      />
                    </div>
                    <ViewParticipants
                      showProfile={this.showProfile}
                      participants={participants.returnObjects}
                      totalParticipantsCount={totalParticipantsCount}
                      loading={loading}
                      handleSelected={this.handleSelected}
                      selectedPage={selectedPage}
                      pageSize={pageSize}
                      types={types}
                      changePageSize={this.changePageSize}
                    />
                  </div>
                </div>
              </div>
            </main>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  participant: state.participant,
  participants: state.participants,
  totalParticipantsCount: state.totalParticipantsCount,
  totalParticipantCount: state.totalParticipantCount,
  loading: state.loading,
  participantAnalytics: state.participantAnalytics,
  participantsAnalytics: state.participantsAnalytics
});
const mapDispatchToProps = () => ({
  getParticipants,
  getParticipant,
  getParticipantAnalytics,
  getParticipantsAnalytics
});

export default connect(mapStateToProps, mapDispatchToProps())(index);

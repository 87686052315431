import {
  GET_DASH_DETAILS,
  DASH_DETAILS_LOADING,
  GET_LEADER_BOARD_DETAILS,
  GET_LEADER_BOARD_DETAILS_LOADING,
  GET_LEADER_BOARD_DETAILS_ERR,
  GET_DASH_DETAILS_FAIL,
} from "../types";
import { getAccessToken } from "../../../dependency/UtilityFunctions";

const initislState = {
  dash: [],
  leaderBoard: [],
  loading: false,
  l_loading: false,
  getLeadershipErr: "",
  getDashDetailsErr: "",
  access_token: getAccessToken(),
};
export default function (state = initislState, action) {
  switch (action.type) {
    case GET_DASH_DETAILS:
      return {
        ...state,
        dash: [action.payload.payload.returnObjects],
        loading: false,
      };
    case GET_LEADER_BOARD_DETAILS:
      return {
        ...state,
        leaderBoard: action.payload.payload.returnObjects,
        l_loading: false,
      };

    case DASH_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_LEADER_BOARD_DETAILS_LOADING:
      return {
        ...state,
        l_loading: true,
        getLeadershipErr: "",
        leaderBoard: [],
      };
    case GET_LEADER_BOARD_DETAILS_ERR:
      //
      //
      //
      if (action.payload) {
        if (action.payload.data !== "") {
          return {
            ...state,
            leaderBoard: [],
            getLeadershipErr: action.payload.data.description
              ? action.payload.data.description
              : action.payload.data.payload.message
              ? action.payload.data.payload.message
              : "An error occured, please try again.",
          };
        }
      } else {
        return {
          ...state,
        };
      }
      break;
    case GET_DASH_DETAILS_FAIL:
      if (action.payload.data !== "") {
        return {
          ...state,
          dash: [],
          // getDashDetailsErr: action.payload.data.description
          //   ? action.payload.data.description
          //   : action.payload.data.payload.message
          //   ? action.payload.data.payload.message
          //   : "An Error Occured, Please try again."
        };
      } else {
        return {
          ...state,
        };
      }
    default:
      return state;
  }
}

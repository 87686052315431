import React, {useState} from "react";
import { CSVLink } from "react-csv";
import { FormGroup, Label } from "reactstrap";
import DatePicker from "react-date-picker";
import PaginationComponent from "react-reactstrap-pagination";
import fileImg from "../images/fileImg.svg";
import moment from "moment";
import TransactionDetails from "./TransactionDetails";
import filterImg from "../images/filter.svg";
import Loader from "react-loader-spinner";

const TransactionHistory = ({
  searchField,
  onChangeInput,
  ref,
  handleSelected,
  pageSize,
  selectedPage,
  totalCount,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  showDateRange,
  onClickDateFilter,
  walletHistory,
  allWalletHistory,
  onMouseLeaveField,
  onSearchField,
  changePageSize,
  types
}) => {
   const [masterCheck, setMasterCheck] = useState(false);
   const [selectedList, setSelectedList] = useState([]);
   const [list, setList] = useState([]);
 
  const onMasterCheck = (e) => {
    let tempList = walletHistory.map((wallet) => (wallet.isSelected = e.target.checked));
    setMasterCheck(e.target.checked);
    setList(tempList);
    setSelectedList(walletHistory.filter((e) => e.isSelected));
  };

  const onItemCheck = (e, item) => {
    let tempList = walletHistory.map((wallet) => {
    
      if (wallet.id === item.id) {
        wallet.isSelected = e.target.checked;
      }
      return wallet;
    });

    const totalItems = walletHistory.length;
    const totalCheckedItems = tempList.filter((e) => e.isSelected).length;

    setMasterCheck(totalItems === totalCheckedItems);
    setList(tempList);
    setSelectedList(walletHistory.filter((e) => e.isSelected));
  };

  const getSelectedRows = () => {
    if(walletHistory.find(e => e.isSelected)){  
       setSelectedList(walletHistory.filter((e) => e.isSelected));
    }else{
      setSelectedList(walletHistory)
    }
    
  };
  return (
    <div className="transaction_history_card my-3" ref={ref}>
      <p className="campaign_heading p-3">All Debit transactions </p>

      <div className="d-flex justify-content-between align-items-center">
        <div className="form-group col-3 d-flex">
          <input
            type="text"
            name="searchField"
            className="form-control"
            onChange={onChangeInput}
            value={searchField}
            autoComplete="off"
            onKeyPress={onSearchField}
            onKeyUp={onSearchField}
          />

          <button className="trans_filter_btn d-flex align-items-center ml-3"
            onClick={onClickDateFilter}> 
            <img
              src={filterImg}
              alt=""
              className="mr-1"
              height="15"
            />
            Date
          </button>
        </div>

        <div className="export-section">
          
          {walletHistory?.length > 0 && (
            <CSVLink
              className="wallet_export_button py-2 px-5 mb-3 mr-3"
              data={masterCheck? walletHistory : selectedList}
              filename="report.csv"
              target="_blank"
              onClick={()=>getSelectedRows()}
             
            >
              <img
                src={fileImg}
                alt=""
                className="wallet_img mr-2 mb-1"
                height="15"
              />
              Export CSV
            </CSVLink>
          )}
        </div>
      </div>

      {showDateRange && <div className="trans__filter-result-section">
        <FormGroup className="daterange pr-4">
          <Label for="" className="C_labell">
            From:
          </Label>

          <DatePicker
            calendarClassName="textField"
            clearAriaLabel=""
            clearIcon=""
            selected={startDate}
            onChange={handleStartDateChange}
            value={startDate}
            onKeyUp={onMouseLeaveField}
            onMouseLeave={onMouseLeaveField}
          />
        </FormGroup>
        <FormGroup className="daterange">
          <Label for="" className="C_labell">
            To:
          </Label>
          <DatePicker
            calendarClassName="textField"
            clearAriaLabel=""
            clearIcon=""
            selected={endDate}
            onChange={handleEndDateChange}
            value={endDate}
            onKeyUp={onMouseLeaveField}
            onMouseLeave={onMouseLeaveField}
          />
        </FormGroup>
      </div>}

      <div className="mx-3 mt-4">
        <table className="wallet_table table table-responsive{-sm|-md|-lg|-xl}">
          <thead className="wallet_table-header">
            <tr className="">
              <th>
                <input
                  type="checkbox"
                  checked={masterCheck}
                  id="mastercheck"
                  onChange={(e) => onMasterCheck(e)}
                />
              </th>
              <th>Date</th>
              <th>Reference ID</th>
              <th>Customer Name</th>
              <th>Customer Email</th>
              <th>Reward Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {walletHistory?.length > 0 ? (
              walletHistory.map((his) => {
                return (
                  <tr key={his.id}>
                    <td>
                      {" "}
                      <input
                        type="checkbox"
                        //this should be his.activated
                        checked={his.isSelected}
                        id="rowcheck{his.id}"
                        onChange={(e) => onItemCheck(e, his)}
                      />
                    </td>

                    <td>{moment(his.createdOn).format("MMM DD, YYYY")}</td>
                    <td>{his.transactionReference}</td>
                    <td>{his.customerName}</td>
                    <td>{his.email}</td>
                    <td>{his.rewardAmount}</td>
                    <td>
                    <TransactionDetails
                      customerId={his.customerId}
                      rewardAmount={his.rewardAmount}
                      transactionReference={his.transactionReference}
                      customerName={his.customerName}
                      email={his.email}
                      date={moment(his.createdOn).format("MMM DD, YYYY")}
                    />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                {walletHistory?.length !== 0  ?
                   <td colSpan="6">
                   <div
                     style={{
                       textAlign: "center",
                       fontSize: "12pt",
                       marginLeft: "auto",
                       marginRight: "auto",
                     }}
                     className=" mt-5 col-sm-6  "
                   >
                     <Loader
                       type="RevolvingDot"
                       color="#00425f"
                       height={100}
                       width={100}
                     />
                   </div>
                 </td>
               : <td colSpan="12">
               <h6 className="text-center pt-3">No Records Found</h6>
             </td>}
               
              </tr>
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
          <small className="mr-2 mt-1">
            {selectedPage} / {pageSize} of {totalCount} records{" "}
          </small>
          <PaginationComponent
            totalItems={totalCount || 0}
            pageSize={pageSize}
            onSelect={handleSelected}
            activePage={selectedPage}
            className="wallet_pag"
          />
            <select
            title="page size"
            className="form-select wallet_page__size"
            onChange={(e) => changePageSize(e.target.value)}
            >
            {types?.map((value) => (
              <option
                value={value}
                key={value}
                name="types"
              >
                {value}
              </option>
            ))}
        </select>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;

import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import addTransaction from "../../images/addTransaction.svg";
import remove from "../../images/removeTransaction.svg";
const TransactionCampaign = ({
  validator,
  transactionBasedTypes,
  transactionRewardablebands,
  handleMultiFormChange,
  addFormFields,
  transactionValues,
  removeFormFields,
  state
}) => {

  return (
    <div className={parseInt(state?.campaignType) === 3 ? "transbasedtype py-2" : "transbasedtype-3 py-2"}>
      <div className="col-6 pt-2 pb-2">
        <p className="h6">Transaction Based Type</p>
      </div>
      <div className="row ml-3">
        {transactionValues?.map((element, index) => (
          <>
            <div className="column mr-3 mb-2" key={index}>
              {" "}
              <FormGroup>
                <Label className="C_labell">Transaction Type</Label>
                <select
                  className="form-select form-control textField"
                  id={`transIdentifier${index}`}
                  value={element.transactionBasedType}
                  onChange={(e) => handleMultiFormChange(index, e)}
                  onInput={(e) => handleMultiFormChange(index, e)}
                  name="transactionBasedType"
                >
                  <option value="">Choose Type</option>

                  {transactionBasedTypes?.map(({ id, title }) => (
                    <option value={title} key={id} data-key={id} name="transactionBasedType">
                      {title}
                    </option>
                  )
                )}
               
              </select>
              <div className="col-sm-3"></div>
                <div className="text-danger p-0 f8">
                  {validator.message(
                    "transactionBasedType",
                    element.transactionBasedType,
                    "required"
                  )}
                </div>
              </FormGroup>
            </div>

            <div className="column mr-3 mb-2">
              {" "}
              <FormGroup>
                <Label className="C_labell">Choose Reward Bands</Label>
                <select
                  className="form-select form-control textField"
                  id={`bandsIdentifier${index}`}
                  value={element.transactionBands}
                  onChange={(e) => handleMultiFormChange(index, e)}
                  onInput={(e) => handleMultiFormChange(index, e)}
                  name="transactionBands"
                >
                  <option value="">Choose Bands</option>

                  {transactionRewardablebands?.map(({ id, title }) => (
                    <option value={title} key={id} data-key={id} name="transactionBands">
                      {title}
                    </option>
                  ))}
                </select>
                <div className="col-sm-3"></div>
                <div className="text-danger p-0 f8">
                  {validator.message(
                    "transactionBands",
                    element.transactionBands,
                    "required"
                  )}
                </div>
              </FormGroup>
            </div>

            <div className="column mr-3 mb-2">
              <FormGroup className="">
                <Label className="C_labell">{parseInt(state?.campaignType) === 3 ? "Reward Referrer Amount" : "Reward Amount" }</Label>

                <Input
                className="textField"
                  type="number"
                  name="referrer"
                  placeholder="Input Amount"
                  value={element.referrer}
                  onChange={(e) => handleMultiFormChange(index, e)}
                  autoComplete="off"
                />
                <div className="col-sm-3"></div>
                <div className="text-danger p-0 f8">
                  {validator.message("referrer", element.referrer, "required")}
                </div>
              </FormGroup>
            </div>
           {parseInt(state?.campaignType) === 3 && <div className="column mr-2 mb-2">
              <FormGroup className="">
                <Label className="C_labell">Reward Referred Amount</Label>

                <Input
                 className="textField"
                  type="number"
                  name="referred"
                  placeholder="Input Reward"
                  value={element.referred}
                  onChange={(e) => handleMultiFormChange(index, e)}
                  autoComplete="off"
                />
                <div className="col-sm-3"></div>
                <div className="text-danger p-0 f8">
                  {validator.message("referred", element.referred, "required")}
                </div>
              </FormGroup>
            </div>}
            {(index === transactionValues.length - 1) && <div className="d-flex justify-item-center align-items-center">
              <img
                src={addTransaction}
                alt="add"
                className="transCampaign_circle_addbtn"
                onClick={() => addFormFields()}
              />
            </div>}
            {transactionValues.length > 1 &&
              <div className="d-flex justify-item-center align-items-center">
                <img
                  src={remove}
                  alt="remove"
                  className="transCampaign_circle_addbtn"
                  onClick={() => {
                    removeFormFields(index);
                  }}
                />
              </div>
            }
          </>
        ))}
      </div>
    </div>
  );
};

export default TransactionCampaign;

import {
  GET_CRITERIA,
  ADD_CRITERIA,
  ADD_BUSS_RULE,
  DELETE_CAMPAIGN,
  CAMPAIGN_LOADING,
  UPDATE_CAMPAIGN,
  GET_CRITERIA_FAIL,
} from "../types";
import { getAccessToken } from "../../../dependency/UtilityFunctions";

const initislState = {
  criterias: [],
  totalRecordCount: 0,
  loading: false,
  getCriteriaErr: "",
  access_token: getAccessToken(),
  campaignType: [
    "{}",
    {
      CampaignTypeId: "1",
      Name: "Referral Campaign",
      DateCreated: new Date(),
    },
    {
      CampaignTypeId: "2",
      Name: "Instant Campaign",
      DateCreated: new Date(),
    },
  ],
  campaignStatus: [
    {
      CampaignStatuId: "1",
      Name: "In Draft",
      DateCreated: new Date(),
    },
    {
      CampaignStatuId: 2,
      Name: "Activated",
      DateCreated: new Date(),
    },
    {
      CampaignStatuId: 3,
      Name: "Not Activated",
      DateCreated: new Date(),
    },
    {
      CampaignStatuId: 4,
      Name: "Stopped",
      DateCreated: new Date(),
    },
    {
      CampaignStatuId: 5,
      Name: "Expired",
      DateCreated: new Date(),
    },
  ],
};

export default function (state = initislState, action) {
  switch (action.type) {
    case GET_CRITERIA:
      return {
        ...state,
        criterias: action.payload.payload.returnObjects,
        totalRecordCount: action.payload.payload.totalRecordCount,
        loading: false,
      };
    case DELETE_CAMPAIGN:
      return {
        ...state,
        UPDATE_CAMPAIGN: state.criterias.filter(
          (campaign) => campaign.id !== action.payload
        ),
      };
    case ADD_CRITERIA:
      return {
        ...state,
        // campaigns: [action.payload, ...state.campaigns]
      };
    case ADD_BUSS_RULE:
      return {
        ...state,
        // campaigns: [action.payload, ...state.campaigns]
      };
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        campaigns: [action.payload, ...state.criterias],
      };
    case CAMPAIGN_LOADING:
      return {
        ...state,
        loading: true,
        getCriteriaErr: "",
      };
    case GET_CRITERIA_FAIL:
      //
      //
      //
      if (action.payload.data !== "") {
        return {
          ...state,
          getCriteriaErr: action.payload.data.description
            ? action.payload.data.description
            : action.payload.data.payload.message
            ? action.payload.data.payload.message
            : "An Error Occured, Please try again.",
        };
      } else {
        return {
          ...state,
        };
      }
    default:
      return state;
  }
}

import React, { Component } from "react";
import ToolBar1 from "../../../Client/Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import Tooltip from "@material-ui/core/Tooltip";

import { connect } from "react-redux";
import * as moment from "moment";
import PaginationComponent from "react-reactstrap-pagination";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Loader from "react-loader-spinner";

import { getClientList } from "../../../../../redux/client/clientActions";
import "./index.css";

class Index extends Component {
  state = {
    sideDrawerOpen: true,
    selectedDate: "",
    startDate: new Date(),
    endDate: new Date(),
    criteria: "",
    criterias: [],
    selectedPage: 1,
    pageSize: 10,
  };
  componentDidMount = () => {
    localStorage.setItem("activeB", "client");
    let { selectedPage, pageSize } = this.state;
    this.props.getClientList(selectedPage, pageSize);
  };
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  goTo = (url, client) => {
    this.props.history.push({
      pathname: url,
      state: { client },
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  newClient = () => {
    this.props.history.push("add-client");
  };
  handleSelected = (selectedPage) => {
    let { pageSize } = this.state;
    this.props.getClientList(selectedPage, pageSize);
    this.setState({ selectedPage: selectedPage });
  };
  changePageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      this.props.getClientList(selectedPage, pageSize);
    });
  };
  render() {
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />

          <main
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7 ">
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    fontSize: "30px",
                  }}
                >
                  Clients
                </h4>
                <div className="" style={{ flex: "1" }}></div>
                <div className="btnCOntainer">
                  <div className="header33" onClick={this.newClient}>
                    Add Client
                  </div>
                </div>
              </div>

              <div className=" subacc-table container-fluid px-0 pb-0">
                <div className="paginations d-flex justify-content-end w-100  ">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Tooltip title="Page size" aria-label="Page size">
                          <div
                            style={{
                              background: "#FFF",
                              padding: "5pt",
                              borderRadius: "5px",
                              boxShadow: "2px 2px 2px 2px #efefef",
                              height: "36px",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            {...bindTrigger(popupState)}
                          >
                            {this.state.pageSize}
                          </div>
                        </Tooltip>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(10)}
                          >
                            10
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(25)}
                          >
                            25
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(50)}
                          >
                            50
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(100)}
                          >
                            100
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                  <PaginationComponent
                    totalItems={this.props.totalRecordCount}
                    pageSize={this.state.pageSize}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                  />
                </div>
                <table className="table table-borderless table-hover ">
                  <thead>
                    <tr className="theaded">
                      <th scope="col theaded">Client</th>
                      <th scope="col theaded">Description</th>
                      <th scope="col theaded">Date</th>
                    </tr>
                  </thead>
                  <tbody className="py-5">
                    {!this.props.loading ? (
                      this.props.clients.map((value) => {
                        return (
                          <tr key={value.clientId}>
                            <td style={{ fontWeight: "normal" }}>
                              {value.name}
                            </td>
                            <td>{value.description}</td>
                            <td>
                              {" "}
                              {moment(value.createdOn).format("MMM DD, YYYY")}
                            </td>
                            <td>
                              <PopupState
                                variant="popover"
                                popupId="demo-popup-menu"
                              >
                                {(popupState) => (
                                  <React.Fragment>
                                    <button
                                      type="button"
                                      className="btn "
                                      variant="contained"
                                      {...bindTrigger(popupState)}
                                      style={{
                                        color: "#00b8de",
                                        borderColor: "#00b8de",
                                      }}
                                    >
                                      Action
                                    </button>
                                    <Menu {...bindMenu(popupState)}>
                                      <MenuItem
                                        onMouseDown={popupState.close}
                                        onClick={() =>
                                          this.goTo("edit-client", value)
                                        }
                                      >
                                        Edit
                                      </MenuItem>
                                      {/* <MenuItem onMouseDown={popupState.close}>
                                        Delete
                                      </MenuItem> */}
                                    </Menu>
                                  </React.Fragment>
                                )}
                              </PopupState>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            <Loader
                              type="RevolvingDot"
                              color="#00425f"
                              height={100}
                              width={100}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="paginations d-flex justify-content-end w-100  ">
                  <PaginationComponent
                    totalItems={this.props.totalRecordCount}
                    pageSize={this.state.pageSize}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  campains: state.campain.campaigns,
  campaignType: state.campain.campaignType,
  campaignStatus: state.campain.campaignStatus,
  clients: state.client.clients,
  totalRecordCount: state.client.totalRecordCount,
  loading: state.client.loading,
});
const mapDispatchToProps = () => ({
  getClientList,
});

export default connect(mapStateToProps, mapDispatchToProps())(Index);

import React, { Component } from "react";
import ToolBar1 from "../../../Client/Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import Loader from "react-loader-spinner";

import { connect } from "react-redux";
import PaginationComponent from "react-reactstrap-pagination";

import { getUsers, deleteUser, setData} from "../../../../../redux/user/userActions";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./marketer.css";

class Index extends Component {
  state = {
    sideDrawerOpen: true,
    selectedPage: 1,
    pageSize: 10,
    selectedDate: "",
    startDate: new Date(),
    endDate: new Date(),
    criteria: "",
    criterias: [],
  };

  componentDidMount() {
    localStorage.setItem("activeB", "users");
    const state = this.props.location.state;
    let { selectedPage, pageSize } = this.state;

    this.props
      .getUsers(selectedPage, pageSize, state ? state : "all")
      .then((res) => {})
      .catch((err) => {});
  }
  handleSelected = (selectedPage) => {
    this.props.getUsers(selectedPage);
    this.setState({ selectedPage: selectedPage });
  };
  changePageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      this.props.getUsers(selectedPage, pageSize);
    });
  };
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  goTo = (user) => {
    this.setState({
      selectedUser: user,
    });
    this.props.history.push({
      pathname: "edit-user",
      state: { user },
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  addCriteria = (e) => {
    e.preventDefault();
    alert("Adding Criteria");
    var newCriterias = [this.state.criteria].concat(this.state.criteria);
    this.setState({ criterias: newCriterias });
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };
  newMarketer = () => {
    this.props.history.push("add-user");
  };

  deleteUser = (id) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="user_alert">
            <h1 className="title">Are you sure?</h1>
            <p className="body">You want to delete this user?</p>
            <button onClick={onClose} className="btn alert__btn--no">
              No
            </button>
            <button
              onClick={() => {
                this.props.deleteUser(id, resp => {
                  const data = this.props.users.map((user)=> user.id === id ? ({...user, activated: false}) : user);
                  this.props.setData({data: {payload: data}});
                });
                onClose();
              }}
              className="btn alert__btn--yes"
            >
              Yes, Delete it!
            </button>
          </div>
        );
        
      },
    });

   
  };

  render() {
  
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />

          <main
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7 d-flex ">
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    fontSize: "30px",
                  }}
                >
                  Users
                </h4>
                <div className="" style={{ flex: "1" }}></div>
                <div className="btnCOntainer">
                  <div className="header33" onClick={this.newMarketer}>
                    Add User
                  </div>
                </div>
              </div>

              <div className=" subacc-table container-fluid px-0 pb-0">
                <div className="paginations d-flex justify-content-end w-100  ">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Tooltip title="Page size" aria-label="Page size">
                          <div
                            style={{
                              background: "#FFF",
                              padding: "5pt",
                              borderRadius: "5px",
                              boxShadow: "2px 2px 2px 2px #efefef",
                              height: "36px",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            {...bindTrigger(popupState)}
                          >
                            {this.state.pageSize}
                          </div>
                        </Tooltip>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(10)}
                          >
                            10
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(25)}
                          >
                            25
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(50)}
                          >
                            50
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(100)}
                          >
                            100
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>

                  <PaginationComponent
                    totalItems={this.props.totalCount}
                    pageSize={this.state.pageSize}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                  />
                </div>

                <table className="table table-hover table-borderless ">
                  <thead>
                    <tr className="theaded">
                      <th scope="col theaded">Name</th>
                      <th scope="col theaded">Role</th>
                      <th scope="col theaded"> Status</th>
                      {/* <th scope="col theaded">Date</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {!this.props.loading ? (
                      this.props.users.map((user) => {
                        return (
                          <tr key={user.id}>
                            <td>
                              {user.lastName} {user.givenName}
                            </td>
                            <td>{user.role}</td>
                            <td>
                              <span className={"badge "}>
                                {user.activated ? "Active" : "Inactive"}
                              </span>
                            </td>
                            {/* <td>
                              {moment(
                                user.createdOn ? user.createdOn : new Date()
                              ).format("MMM DD, YYYY")}
                            </td> */}
                            <td>
                              <PopupState
                                variant="popover"
                                popupId="demo-popup-menu"
                              >
                                {(popupState) => (
                                  <React.Fragment>
                                    <button
                                      type="button"
                                      className="btn "
                                      variant="contained"
                                      {...bindTrigger(popupState)}
                                      style={{
                                        color: "#00b8de",
                                        borderColor: "#00b8de",
                                      }}
                                    >
                                      Action
                                    </button>
                                    <Menu {...bindMenu(popupState)}>
                                      
                                      <MenuItem
                                      onClick={()=>{
                                        popupState.close()
                                        this.goTo(user)
                                      }}
                                      >
                                        Edit
                                      </MenuItem >
                          
                                      <MenuItem
                                        onClick={()=>{
                                          popupState.close()
                                          this.deleteUser(user.id)
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                     
                                    </Menu>

                                  </React.Fragment>
                                )}
                              </PopupState>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            <Loader
                              type="RevolvingDot"
                              color="#00425f"
                              height={100}
                              width={100}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="paginations d-flex justify-content-end w-100  ">
                  <PaginationComponent
                    totalItems={this.props.totalCount}
                    pageSize={this.state.pageSize}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.user.users,
  totalCount: state.user.totalCount,
  loading: state.user.loading,
});
const mapDispatchToProps = () => ({
  getUsers,
  deleteUser,
  setData
});

export default connect(mapStateToProps, mapDispatchToProps())(Index);

import React, { Component } from "react";
import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import FilterListIcon from "@material-ui/icons/FilterList";
import { connect } from "react-redux";

import Loader from "react-loader-spinner";

import "./subaccount.css";
import { getSubAgents } from "../../../../../redux/agent/agentActions";
class Index extends Component {
  state = {
    sideDrawerOpen: true,
    selectedDate: "",
    startDate: new Date(),
    endDate: new Date(),
    criteria: "",
    criterias: [],
    agentId: "",
  };
  componentDidMount() {
    const cid = this.props.location.state;
    this.setState({ agentId: cid });
    this.props.getSubAgents(cid);
  }
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  addCriteria = (e) => {
    e.preventDefault();
    alert("Adding Criteria");
    var newCriterias = [this.state.criteria].concat(this.state.criteria);
    this.setState({ criterias: newCriterias });
  };
  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  newSubAccount = () => {
    this.props.history.push("new-subAgent");
    this.props.history.push({
      pathname: "new-subAgent",
      state: this.state.agentId,
    });
  };
  render() {
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />

          <main
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7 ">
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    fontSize: "30px",
                  }}
                >
                  Sub Agents
                </h4>
                <div className="" style={{ flex: "1" }}></div>
                <div className="btnCOntainer">
                  <div
                    style={{
                      background: "#FFF",
                      padding: "5pt",
                      borderRadius: "5px",
                      boxShadow: "2px 2px 2px 2px #efefef",
                      height: "36px",
                      marginRight: "10px",
                    }}
                  >
                    <FilterListIcon />
                  </div>
                  <div className="header33" onClick={this.newSubAccount}>
                    Add Sub Agent
                  </div>
                </div>
              </div>

              <div className="subacc-table container-fluid ">
                <table className="table table-borderless ">
                  <thead>
                    <tr className="theaded">
                      <th scope="col theaded">Name</th>
                      <th scope="col theaded">Referral Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!this.props.loading ? (
                      this.props.subAgents.map((sa) => {
                        return (
                          <tr key={sa.agentId}>
                            <td>{sa.name}</td>
                            <td>{sa.referralCode}</td>
                          </tr>
                        );
                      })
                    ) : !this.props.getSubAgentErr ? (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            <Loader
                              type="RevolvingDot"
                              color="#00425f"
                              height={100}
                              width={100}
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            {this.props.getSubAgentErr}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subAgents: state.agent.subAgents,
  loading: state.agent.loading,
  getSubAgentErr: state.agent.getSubAgentErr,
});
const mapDispatchToProps = () => ({ getSubAgents });

export default connect(mapStateToProps, mapDispatchToProps())(Index);

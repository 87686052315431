import HTTP from "../http-trips";
import {
  createNotification,
  tokenConfig,
} from "../../dependency/UtilityFunctions";
import {
  USER_LOADED,
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
} from "./types";

// Check token & load user

export const loadUser = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: USER_LOADING });

  HTTP.get("api/Auth/Token", tokenConfig(getState))
    .then((res) =>
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      })
    )
    .catch((err) => {
      // dispatch(returnErrors(err.response.data, err.response.status));
      // dispatch({ type: AUTH_ERROR });
    });
};

// Register User
export const register =
  ({ name, email, password }) =>
  (dispatch) => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //   Request body
    const body = JSON.stringify({ name, email, password });

    HTTP.post("api/Auth/Token", body, config)
      .then((res) =>
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        })
      )
      .catch((err) => {
        // dispatch(
        //   returnErrors(err.response.data, err.response.status, "REGISTER_FAILED")
        // );
        // dispatch({
        //   type: REGISTER_FAIL
        // });
      });
  };

// Login User
export const login =
  ({ email, password }) =>
  async (dispatch) => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //   Request body
    const body = JSON.stringify({ email, password });
    let successResponse = {};
    return  await HTTP.post("api/Auth/Token", body, config)
      .then((res) => {
        if (res?.data?.hasErrors) {
          successResponse = res.data
        }
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        return res;
      })
      .catch((err) => {
        createNotification("error", successResponse?.hasErrors ? successResponse.errors?.[0] : "An error occured, please try again.");
        return err.response;
      });
  };

// convert token to details
export const convertToken = () => (dispatch) => {};
// Logout User
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const getRolesAndPermissions = () => (dispatch, getState) => {
  return HTTP.get("api/Quickteller/GetRolesPermissions/permissions-config")
    .then((res) => {
      sessionStorage.setItem("permissionsAndRoles", JSON.stringify(res.data));
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const resetPassword = async (body, callback, setFalse) => {
    await HTTP.post("api/UsersMgmt/ResetPassword", body)
    .then((res) => {
     
        if(res.data?.hasErrors){
          setFalse();
          createNotification("error", res.data.description || "An error occured, please try again.");
        }else{
          callback();
          createNotification("success", res.data.description || "Check Email for new login Details")}
        return res;

    })
    .catch((err) => {
      setFalse();
      createNotification("error", "An error occured, please try again.");
      return err.response;
    });
};

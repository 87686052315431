import React, { Component } from "react";

import AssignmentIcon from "@material-ui/icons/Assignment";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import GroupIcon from "@material-ui/icons/Group";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Campains from "./Campaign/Campains";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { NotificationManager } from "react-notifications";
import Can from "../../../../config/Can";
import {
  getDashDetails,
  getDashLeaderBoardDetails,
} from "../../../../redux/dash/dashActions";
import {
  getCampaigns,
  getCampaignTypes,
  getCampaignStatuses,
} from "../../../../redux/campain/campaignActions";
import { getActiveClient } from "../../../../dependency/UtilityFunctions";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import * as moment from "moment";
import { Switch, Route } from "react-router-dom";
//
import { Card, CardTitle } from "reactstrap";
import "./contents.css";

class Main extends Component {
  state = {
    dashDetLoading: false,
    getLeadershipErr: "",
    selectedCampaign: []
  };

  componentDidMount() {
    let activeClient = getActiveClient();
    if (!activeClient) {
      this.createNotification("error", "No assigned client");

      return;
    }
    this.toggleDashDetLoading();
    if (activeClient){
      this.props
        .getDashDetails(activeClient.id)
        .then((res) => {
          if (res) {
            if (res.status === 401) {
              // let error = JSON.parse(res);
              this.createNotification("error", "UnAuthorised Error");
            }
            if (res.status === 500) {
              //this.createNotification("error", res.data.description);
            }
          }
        })
        .then(async () => {
          await this.props
            .getDashLeaderBoardDetails(activeClient.id)
            .then((res) => {
              this.toggleDashDetLoading();
              if (res) {
                let { status } = res;
                if (status !== 200) {
                  this.createNotification("error", res.data.description);
                  this.setState({
                    getLeadershipErr: res.data.description,
                  });
                }
              }
            });
        })
        .then(async () => {
          await this.props.getCampaigns(activeClient.id, 1, 5).then((res) => {
            // ;
          });
        })
        .then(async () => {
          await this.props.getCampaignTypes();
        })
        .then(async () => {
          this.props.getCampaignStatuses();
        });
      }
  }

  toggleDashDetLoading = () => {
    this.setState({
      dashDetLoading: !this.state.dashDetLoading,
    });
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
      // alert();
      // }
    }
  };
  goTo = (where) => {
    switch (where) {
      case "add-campiagns":
        localStorage.setItem("activeB", "campians");
        this.props.history.push(where);
        break;
      case "leaderBoard":
        localStorage.setItem("activeB", "dashboard");
        this.props.history.push(where);
        break;
      case "referredCustomers":
        localStorage.setItem("activeB", "dashboard");
        this.props.history.push(where);
        break;
      case "transactingReferredCustomers":
        localStorage.setItem("activeB", "dashboard");
        this.props.history.push(where);
        break;
      case "activeReferredCustomers":
        localStorage.setItem("activeB", "dashboard");
        this.props.history.push(where);
        break;
      default:
        localStorage.setItem("activeB", "dashboard");
        break;
    }
  };

  goToCampaign = (url, campaign) => {
    this.setState({
      selectedCampaign: campaign,
    });

    this.props.history.push({
      pathname: url,
      state: { campaign },
    });
  };

  render() {
    return (
      <main
        style={{
          flex: "1 1 0%",
        }}
      >
        <div className="contentBody  px-5">
          <div className="col-sm-12 my7 ">
            <h4
              style={{
                fontWeight: "bold",
                paddingTop: "10px",
                marginRight: "68%",
                marginLeft: "1.1%",
              }}
            >
              Dashboard
            </h4>
          </div>
          <Content
            goTo={this.goTo}
            goToCampaign={this.goToCampaign}
            campaigns={this.props.campains}
            getCampaignErr={this.props.getCampaignErr}
            campaignType={this.props.campaignType}
            loading={this.props.loading}
            leaderboard={this.props.leaderboard}
            leaderboardLoading={this.props.l_loading}
            getLeadershipErr={this.props.getLeadershipErr}
            dash={this.props.dash}
          />
        </div>
        {/* {JSON.stringify(this.props.sideDrawerOpen)} */}
      </main>
    );
  }
}
// export default connect(mapStateToProps)(Index);
const mapStateToProps = (state) => ({
  dash: state.dash.dash,
  campains: state.campain.campaigns,
  campaignType: state.campain.campaignType,
  loading: state.campain.loading,
  getCampaignErr: state.campain.getCampaignErr,
  getLeadershipErr: state.dash.getLeadershipErr,
  leaderboard : state.dash.leaderBoard,
  l_loading: state.dash.l_loading,
});
const mapDispatchToProps = () => ({
  getDashDetails,
  getCampaigns,
  getDashLeaderBoardDetails,
  getCampaignTypes,
  getCampaignStatuses,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps())
)(Main);

// export default withRouter(Main);
const Content = (props) => {
  let count = 0;
  return (
    <>
      <div className="container-fluid">
        <div className="container-fluid" style={{ padding: "0" }}>
          <div className=" " style={{ display: "" }}>
            <div
              className="row mb-3 displayFlex"
              style={{ flexDirection: "row", margin: "0" }}
            >
              <div className="col-xl-3 col-sm-6 py-2 ">
                <div className="card  h-100 ">
                  <div className="card-body refCards23">
                    <span>
                      <div className="displayFlex">
                        <div>
                          <span className="size16">Total Amount Spent</span>
                          <h1 className="display-5 bigi23">
                            {" "}
                            {props.dash[0]
                              ? props.dash[0]["totalAmountSpentOfBudget"]
                              : "0"}
                          </h1>
                        </div>
                        <div className="spacer"></div>
                        <div>
                          <div className="rotate circle23" style={{}}>
                            <div className="ct fa fa-naira fa-2x"></div>
                          </div>
                        </div>
                      </div>
                    </span>
                    {/* <div className="size10">Open Wallet</div> */}
                  </div>
                </div>
                <div className="bgLightBlue col-sm-12"></div>
              </div>
              <div className="col-xl-3 col-sm-6 py-2">
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Total Referred</span>
                        <h1 className="display-5 bigi23">
                          {" "}
                          {props.dash[0]
                            ? props.dash[0]["totalCustomersReferred"]
                            : "0"}
                        </h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <GroupIcon />
                          </span>
                          {/* <i className="fa fa-user-friends"></i> */}
                          {/* <span className="ct fas fa-user-friends fa-2x"></span> */}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      className="size10"
                      onClick={() => {
                        props.goTo("referredCustomers");
                      }}
                    >
                      View
                    </div>
                  </div>
                </div>
                <div className="bgDarkBlue col-sm-12 mr-0"></div>
              </div>
              <div className="col-xl-3 col-sm-6 py-2">
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Transacting Referrals</span>
                        <h1 className="display-5 bigi23">
                          {props.dash[0]
                            ? props.dash[0]["totalTransactingReferrals"]
                            : "0"}
                        </h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <CreditCardIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="size10"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.goTo("transactingReferredCustomers");
                      }}
                    >
                      View
                    </div>
                  </div>
                </div>
                <div className="bgRed col-sm-12"></div>
              </div>
              <div className="col-xl-3 col-sm-6 py-2">
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Activated Accounts</span>
                        <h1 className="display-5 bigi23">
                          {props.dash[0]
                            ? props.dash[0]["totalActivatedCustomers"]
                            : "0"}
                        </h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <VerifiedUserIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="size10"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.goTo("activeReferredCustomers");
                      }}
                    >
                      View
                    </div>
                  </div>
                </div>
                <div className="bgDarkBlue col-sm-12"></div>
              </div>
            </div>
          </div>
          {/* Let go ..... */}
          <div
            className=" mb-3 displayFlex  d-none"
            // style={{ flexDirection: "row", padding: "0" }}
          >
            <div className="displayFlex " style={{ width: "51%" }}>
              <div className="  no-padding mr" style={{ width: "50%" }}>
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Total Amount Spent</span>
                        <h1 className="display-5 bigi23">N14,000</h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <div className=" fa fa-naira fa-2x"></div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">Open Wallet</div>
                  </div>
                </div>
                <div className="bgLightBlue col-sm-12"></div>
              </div>

              <div className="  no-padding " style={{ width: "50%" }}>
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Total Referred</span>
                        <h1 className="display-5 bigi23">898</h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <GroupIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">View</div>
                  </div>
                </div>
                <div className="bgDarkBlue col-sm-12 mr-0"></div>
              </div>
            </div>
            <div className="displayFlex ml-4" style={{ width: "50%" }}>
              <div className="  no-padding mr" style={{ width: "50%" }}>
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Transacting Referrals</span>
                        <h1 className="display-5 bigi23">250</h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <CreditCardIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">View</div>
                  </div>
                </div>
                <div className="bgRed col-sm-12"></div>
              </div>
              <div className="  no-padding " style={{ width: "50%" }}>
                <div className="card  h-100  radius">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Activated Accounts</span>
                        <h1 className="display-5 bigi23">140</h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <VerifiedUserIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">View</div>
                  </div>
                </div>
                <div className="bgDarkBlue col-sm-12"></div>
              </div>
            </div>
          </div>
        </div>
        {/* End Hidden flexerBody2  */}
        <div>
          <Can I="manage" a="Campains">
            {() => (
              <div className="col-sm-12">
                <Card body className=" card-font-size  heightB4">
                  <CardTitle className="displayFlex bottomLine padd">
                    <span className="blak">
                      <AssignmentIcon className="mr-2" />
                      Campaigns{" "}
                    </span>
                    <div className="spacer"></div>
                    <Can I="create" a="AddCampains">
                      {() => (
                        <div
                          className="header2"
                          style={{ width: "150px" }}
                          onClick={() => {
                            props.goTo("add-campiagns");
                          }}
                        >
                          Add New Campaign
                        </div>
                      )}
                    </Can>
                  </CardTitle>

                  <table className="table table-borderless table-hover">
                    <thead className="campaignsTh">
                      <tr>
                        <th style={{ textAlign: "left" }}>
                          <span className="pr-2 card-font-size ">
                            <RadioButtonUncheckedIcon className="card-font-size " />
                          </span>
                          Campaign
                        </th>
                        {/* <th>Added</th> */}
                        <th>Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody className="campaignsBody">
                      {!props.loading ? (
                        props.campaigns.map((value, key) => {
                          return (
                            <tr key={key} style={{cursor: 'pointer'}} onClick={() =>
                              props.goToCampaign(
                                "view-campiagn",
                                value
                              )
                            }>
                              <td>
                                <span className="pr-2 card-font-size ">
                                  <RadioButtonUncheckedIcon className="card-font-size " />
                                </span>
                                <span>{value.title}</span>
                              </td>
                              {/* <td>
                                <span>
                                  {moment(value.dateOfCreation).format(
                                    "MMM DD, YYYY"
                                  )}
                                </span>
                              </td> */}
                              <td>
                                <span>
                                  {value.campaignTypeName}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {" "}
                                  {moment(value?.startDate).format(
                                    "MMM DD, YYYY"
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {" "}
                                  {moment(value?.endDate).format(
                                    "MMM DD, YYYY"
                                  )}
                                </span>
                              </td>

                              <td>
                                <span>{value?.campaignStatusName}</span>
                              </td>
                              <td>
                                <span>{value?.description}</span>
                              </td>
                            </tr>
                          );
                        })
                      ) : !props.getCampaignErr ? (
                        <tr>
                          <td colSpan="12" style={{ textAlign: "center" }}>
                            <span>Loading...</span>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="12" style={{ textAlign: "center" }}>
                            <span>{props.getCampaignErr}</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Card>
              </div>
            )}
            {/* <div className="p-2"></div> */}
          </Can>{" "}
          <div className="col-sm-12 my-4">
            <Card body className="card card-font-size card-height">
              <CardTitle className="displayFlex bottomLine padd">
                <span className="blak">
                  <GroupIcon className="mr-2" />
                  Leaderboard
                </span>
                <div className="spacer"></div>
                <div
                  className="header2"
                  onClick={() => {
                    props.goTo("leaderBoard");
                  }}
                >
                  View All
                </div>
              </CardTitle>
              {/* {!props.leaderboardLoading &&
                props.leaderboard.map((value, key) => {
                  return ( */}
                    <table>
                      <thead className="campaignsTh">
                        <tr>
                          <th>Rank</th>
                          <th>Name</th>
                          <th>Reward Value</th>
                        </tr>
                      </thead>
                      <tbody className="campaignsBody">
                        {!props.leaderboardLoading &&
                          props.leaderboard.map((value, key) => {
                            return (
                              <tr key={`${value?.firstName}-${value?.lastName}-${key}`}>
                                <td>{key+=1}</td>
                                <td>
                                  <span>{`${value.firstName} ${value.lastName}`}</span>
                                </td>
                                <td>
                                  <span>
                                    {value.totalRewardValue}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  {/* );
                })} */}
              <Grid className=" campaignsBody ">
                <List dense>
                  {props.leaderboard.length == 0 && !props.leaderboardLoading && (
                    <div className="text-center">No record found</div>
                  )}
                  {props.leaderboardLoading && (
                     <div className="text-center">Loading...</div>
                  )}
                </List>
              </Grid>
            </Card>
          </div>
        </div>
        <div
          className="flexerBody2  d-none"
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <div className="col-sm-8 ">
            <Card body className="card card-font-size card-height">
              <CardTitle className="displayFlex bottomLine padd">
                <span className="blak">
                  <MultilineChartIcon className="mr-2" />
                  Statistics
                </span>
              </CardTitle>

              <div className="statbody ">
                <div className="statPieChat col-sm-6">
                  <div className="lineChat col-sm-4">Coming Soon</div>
                  <div className="col-sm-4"> {/* <Stat1 /> */}</div>
                  <div className="col-sm-4">{/* <Stat2 /> */}</div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-sm-4">
            <Card body className="card card-font-size card-height">
              <CardTitle className="displayFlex bottomLine padd">
                <span className="blak">
                  <GroupIcon className="mr-2" />
                  Sub Agent
                </span>

                <div className="spacer"></div>
                <div className="header2">View All</div>
              </CardTitle>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div className="lineChat col-sm-4">Coming Soon</div>
                <div
                  style={{
                    width: "70%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                  }}
                  className="d-none"
                >
                  <div className="subaccountsubheading">Most Performing</div>
                  <img
                    alt="img"
                    className="subaccntImg"
                    style={{ borderRadius: "50%" }}
                    height="100"
                    width="100"
                    src="http://abs2018.lbsafricaclub.org/wp-content/uploads/2018/04/Screen-Shot-2018-04-25-at-23.21.59-262x272.png"
                  />
                  <div className="subaccountname">Banky W</div>
                  <div>
                    <span className="subaccountsubheading">
                      Number of link clicks: 2,500
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <Switch>
          <Route path="/campians" component={Campains}></Route>
          <Route path="/users"></Route>
          <Route path="/"></Route>
        </Switch>
      </div>
    </>
  );
};

// withRouter

import React from "react";
import { Form, FormGroup, Input } from "reactstrap";

const AcquisitionCampaign = ({
  state,
  validator,
  handleFormData,
  handleCheckBox,
}) => {
  let campaignID = parseInt(state?.campaignType);
  return (
    <div>
      <div className="py-4">
        <div className="acquisitionCampaign col-6 pt-4 pb-2">
          <p className="h6">Acquisition Campaign</p>
          <label className="C_labell pt-1">Reward mechanics</label>
        </div>
        <Form>
          <div className="col-6">
            <FormGroup className="">
              <div className="form-check acq_head_label">
                <input
                  className="acq_checkbox form-check-input h6"
                  type="checkbox"
                  onChange={() => {
                    validator.fields = [];      
                    handleCheckBox("refererChecked")}}
                  name="refererChecked"
                  checked={state.refererChecked}
                  disabled={campaignID === 3? true: false}
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Reward Referrer
                </label>
              </div>

              {state.refererChecked && (
                <>
                  {" "}
                  <Input
                    className="textField"
                    type="number"
                    name="referrer"
                    placeholder="Input Amount"
                    onChange={(e) => handleFormData(e, "referrer")}
                    value={state.referrer }
                    autoComplete="off"
                    disabled={campaignID === 3? true: false}
                  />
                  <div className=""></div>
                  <div className=" text-danger p-0 f8">
                    {validator.message(
                      "Referrer",
                      state.referrer,
                      "required|numeric"
                    )}
                  </div>{" "}
                </>
              ) }
            </FormGroup>

            <FormGroup className="">
              <div className="form-check acq_head_label">
                <input
                  className="acq_checkbox form-check-input h6"
                  type="checkbox"
                  name="referredChecked"
                  onChange={() => {
                    validator.fields = [];      
                    handleCheckBox("referredChecked")}}
                  checked={state.referredChecked}
                  disabled={campaignID === 3? true: false}
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Reward Referred User
                </label>
              </div>

              {state.referredChecked && (
                <>
                  {" "}
                  <Input
                    className="textField"
                    type="number"
                    name="referred"
                    placeholder="Input Reward"
                    onChange={(e) => handleFormData(e, "referred")}
                    value={state.referred}
                    autoComplete="off"
                    disabled={campaignID === 3? true: false}
                  />
                  <div className=""></div>
                  <div className=" text-danger p-0 f8">
                    {validator.message(
                      "referred",
                      state.referred,
                      "required|numeric"
                    )}
                  </div>
                </>
              )}
            </FormGroup>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AcquisitionCampaign;

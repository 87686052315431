import {
  CAMPAIGN_LOADING,
  DELETE_CAMPAIGN,
  GET_CRITERIA,
  UPDATE_CAMPAIGN,
  ADD_BUSS_RULE,
  GET_CRITERIA_FAIL,
} from "./types";
import HTTP from "../http-trips";
import { getActiveClient } from "../../dependency/UtilityFunctions";
export const getCritetias = (cid, pagesize = 10, pageindex = 1) => (
  dispatch,
  getState
) => {
  let activeClient = getActiveClient();
  dispatch(setItemsLoading());

  return HTTP.get(
    `/api/Clients/getrewardableTransactionTypes?pageSize=${pagesize}&pageIndex=${pageindex}&clientId=${
      cid ? cid : activeClient.id
    }`
  )
    .then((res) => {
      dispatch(setData(res));
      return res;
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_CRITERIA_FAIL,
          payload: err.response,
        });
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};
//
export const getRewardType = (cid) => (dispatch, getState) => {
  dispatch(setItemsLoading());

  return HTTP.get(
    `api/Clients/getrewardTypes/${
      cid ? cid : "d72b08c9-ee4c-46f7-a142-6f3a9f58fffe"
    }`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const addCriteria = (criteria) => (dispatch, getState) => {
  return HTTP.post("api/Clients/addrewardableTransactionType", criteria)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addBussinesRule = (rules) => (dispatch, getState) => {
  return HTTP.post("api/Campaign/BulkAddBusinessRules", rules)
    .then((res) => {
      dispatch({
        type: ADD_BUSS_RULE,
        payload: rules,
      });
      return res;
    })
    .catch((err) => {
      // alert("Error : error");

      return err.response;
    });
};
export const updateCampaign = (update) => (dispatch, getState) => {
  return HTTP.post("api/Admin/UpdateBusiness", update)
    .then((res) => {
      // ;

      dispatch({
        type: UPDATE_CAMPAIGN,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {});
};

export const deleteCampaign = (id) => (dispatch, getState) => {
  return HTTP.post(`api/Admin/DeleteBusiness`, id)
    .then((res) => {
      dispatch({
        type: DELETE_CAMPAIGN,
        payload: id,
      });
      return res.data;
    })

    .catch((err) => {
      return err;
    });
};

export const setData = (res) => {
  return {
    type: GET_CRITERIA,
    payload: res.data,
  };
};
export const setItemsLoading = () => {
  return {
    type: CAMPAIGN_LOADING,
  };
};

import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import reducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const thukMiddlewares = [thunk];

// export function configureStore(initialState) {
const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...thukMiddlewares))
  // initialState
);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept("./reducers", () => {
    const nextRootReducer = require("./reducers");
    store.replaceReducer(nextRootReducer);
  });
}

// return store;
// }

export default store;

import React, { Component } from "react";
import ProfileDisplayHeader from "./ProfileDisplayHeader";

export class ProfileDisplay extends Component {
  render() {
    let { payload, phoneNumber, onChangePhone, onSubmit, submitBtnClicked } =
      this.props;
    return (
      <>
        <ProfileDisplayHeader
          ProfileUserBtnText={submitBtnClicked ? "loading..." : "Update"}
          onClickAction={onSubmit}
          profileUserRole={payload?.role || "Loading..."}
          profileUserName={`${payload?.givenName || "Loading..."} ${
            payload?.lastName || ""
          }`}
          submitBtnClicked={submitBtnClicked ? false : true}
        />

        <div className="profile-main row mx-4 p-2">
          <div className="col-lg-6">
            <h6 className="py-3">Edit the users detail in the form below</h6>
            <form>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control textField"
                  value={payload?.givenName}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control textField"
                  value={payload?.lastName}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control textField"
                  value={payload?.username}
                  disabled
                />
              </div>
              <div class="form-group">
                <label>Phone Number</label>
                <input
                  name="phoneNumber"
                  type="number"
                  className="form-control textField"
                  value={phoneNumber ? phoneNumber : payload?.phoneNumber}
                  onChange={onChangePhone}
                  min="0"
                  autoComplete="off"
                />
              </div>
              <div class="form-group">
                <label>Role</label>
                <input
                  type="text"
                  className="form-control textField"
                  value={payload?.role}
                  disabled
                />
              </div>
            </form>
          </div>

          {/* Would be implemeted in future
          
          <div className="col-lg-6 p">
            <div className="mt-3">
              <label>Description</label>
              <p className="profile_description">
                Assigned only to DCEOs. Responsible for approving disbursements.
              </p>
            </div>

            <div className="pt-5">
              <label>Permissions</label>
              <p className="profile_description">
                {" "}
                Actions assigned to this user
              </p>

              {payload?.extraPermissions.map((perm) => (
                <button className="profile_roles_btn mr-2 mt-2" key={perm}>{perm}</button>
              )) || "Loading..."}
            </div>
          </div> */}

          
        </div>
      </>
    );
  }
}

export default ProfileDisplay;

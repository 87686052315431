import React, { Component } from "react";
import { connect } from "react-redux";
import ToolBar1 from "../Client/Navs/ToolBar/ToolBar1";
import SideDrawer from "../Client/Navs/SideDrawer/SideDrawer";
import ProfileDisplay from "./components/ProfileDisplay";
import Security from "./components/Security";
import {
  getProfileDetails,
  editProfileDetails,
  updatePassword,
} from "../../../redux/profile/profileActions";
import "./profile.css";
import { createNotification } from "../../../dependency/UtilityFunctions";

export class Profile extends Component {
  state = {
    onClickSubmitBtn: false,
    sideDrawerOpen: true,
    showProfile: true,
    showSecurity: false,
    givenName: "",
    lastName: "",
    username: "",
    phoneNumber: 0,
    role: "",
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("loggedInUserDetails"));
    this.props.getProfileDetails(user.details.userId);
  }
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  onclickProfile = () => {
    this.setState({ showProfile: true, showSecurity: false });
  };

  onclickSecurity = () => {
    this.setState({ showSecurity: true, showProfile: false });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSubmitUserDetails = () => {
    this.setState({ onClickSubmitBtn: true });
    const { payload } = this.props?.profile?.profile;
    const { phoneNumber } = this.state;
    const user = JSON.parse(localStorage.getItem("loggedInUserDetails"));

    const updatedDetails = {
      clientIds: [user.details.userId],
      givenName: payload?.givenName,
      lastName: payload?.lastName,
      middleName: payload?.givenName,
      username: payload?.username,
      email: payload?.username,
      phoneNumber: phoneNumber ? phoneNumber : payload?.phoneNumber,
      role: payload?.role,
    };

    if (phoneNumber.length < 11) {
      createNotification("error", "Enter a valid Phone Number");
      this.setState({ onClickSubmitBtn: false });
    } else if (phoneNumber.length > 13) {
      createNotification("error", "Phone Number must not exceed 13digits");
      this.setState({ onClickSubmitBtn: false });
    } else {
      this.props.editProfileDetails(updatedDetails).then(() => {
        this.setState({ onClickSubmitBtn: false });
      });
    }
  };

  onClickSecurity = () => {
    this.setState({ onClickSubmitBtn: true, onClictEdit: false });
  };

  updatePasswordInfo = () => {
    this.setState({ onClickSubmitBtn: true });
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    const pwrdDetails = { oldPassword, newPassword };
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (strongRegex.test(newPassword)) {
      if (newPassword !== confirmNewPassword) {
        createNotification("error", "Passwords do not match!");
        this.setState({
          onClickSubmitBtn: false,
        });
      } else {
        this.props.updatePassword(pwrdDetails).then(() => {
          this.setState({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            onClickSubmitBtn: false,
          });
        });
      }
    } else {
      createNotification("error", "Passwords failed validation", 2000);
      this.setState({
        onClickSubmitBtn: false,
      });
    }
  };
  render() {
    const { payload } = this.props?.profile?.profile;
    const {
      showProfile,
      showSecurity,
      phoneNumber,
      onClickSubmitBtn,
      oldPassword,
      newPassword,
      confirmNewPassword,
    } = this.state;

    return (
      <div className="profile-container">
        <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />
        <main className="container-fluid px-5">
          <div className="contentBody">
            <div className="col-sm-12 my7 px-4">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h4 className="profile_heading">Profile Management</h4>
              </div>
              <p>
                <small>
                  View your rights, permissions and manage passwords
                </small>
              </p>

              <div className="card my-4 singleHeight">
                <p className="profile_subheading px-3 pt-3">Manage Profile</p>

                <div className="profile_subnav  mx-4">
                  <span
                    className={`${
                      showProfile ? "profile_subnav_hover" : ""
                    } mr-3`}
                    onClick={this.onclickProfile}
                  >
                    Profile
                  </span>
                  <span
                    className={`${showSecurity ? "profile_subnav_hover" : ""}`}
                    onClick={this.onclickSecurity}
                  >
                    Security
                  </span>
                  <br></br>
                </div>

                {showProfile && (
                  <ProfileDisplay
                    payload={payload}
                    submitBtnClicked={onClickSubmitBtn}
                    phoneNumber={phoneNumber}
                    onChangePhone={this.onChange}
                    onSubmit={this.onSubmitUserDetails}
                  />
                )}
                {showSecurity && (
                  <Security
                    payload={payload}
                    submitBtnClicked={onClickSubmitBtn}
                    onChangePwd={this.onChange}
                    updatePwd={this.updatePasswordInfo}
                    oldPassword={oldPassword}
                    newPassword={newPassword}
                    confirmNewPassword={confirmNewPassword}
                  />
                )}
              </div>
            </div>
            {/*  */}
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile
});
const mapDispatchToProps = () => ({
  getProfileDetails,
  editProfileDetails,
  updatePassword
});

export default connect(mapStateToProps, mapDispatchToProps())(Profile);

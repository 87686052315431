import React from "react";
import AcquisitionCampaignDetails from "../acquisition/AcquisitionCampaignDetails";
import ConfirmTransactionCampaign from "../transactional/ConfirmTransactionCampaign";

const ConfirmCampaignType = ({
  handleFormData,
  nextStep,
  state,
  prevStep,
  onSaveSecondStep,
  goback,
  transactionValues,
}) => {
  const onSubmit = () => {
    onSaveSecondStep();
    nextStep();
  };
  return (
    <div className="campaign_types">
      <div className="col-6">
        {" "}
        <select className="form-select form-control textField" disabled>
          <option value={state.campaignType} name="campaignType">
            {parseInt(state.campaignType) === 1 && "Acquisition Campaign"}
            {parseInt(state.campaignType) === 2 && "Transaction Campaign"}
            {parseInt(state.campaignType) === 3 && "Acquisition & Transaction Campaign"}
          </option>
        </select>
      </div>
      <div className="campaign-type-segment mb-3">
        {parseInt(state?.campaignType) === 1 && (
          <AcquisitionCampaignDetails
            handleFormData={handleFormData}
            state={state}
          />
        )}

        {parseInt(state?.campaignType) === 2 && (
          <ConfirmTransactionCampaign
            handleFormData={handleFormData}
            state={state}
            transactionValues={transactionValues}
          />
        )}

        {parseInt(state?.campaignType) === 3 && (
          <>
            <AcquisitionCampaignDetails
              handleFormData={handleFormData}
              state={state}
            />
            <ConfirmTransactionCampaign
              handleFormData={handleFormData}
              state={state}
              transactionValues={transactionValues}
            />
          </>
        )}
      </div>

      <span className="wizard_footer d-flex justify-content-between align-items-center mx-2">
        <small
          className="wizard_footer_back ml-1 py-2 text-info h6"
          onClick={prevStep}
        >
          Back
        </small>

        <div className="mt-3">
          <button
            className="btn btn-outline-info py-2 px-3 mr-3"
            onClick={goback}
          >
            Cancel
          </button>
          <button className="btn btn-info py-2" onClick={onSubmit}>
            Save and Continue
          </button>
        </div>
      </span>
    </div>
  );
};

export default ConfirmCampaignType;

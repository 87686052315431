import {
  GET_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS
} from "../types";
import { getAccessToken } from "../../../dependency/UtilityFunctions";

const initislState = {
  profile: [],
  updatedProfileDetails: [],
  getDashDetailsErr: "",
  access_token: getAccessToken(),
};
export default function (state = initislState, action) {
  switch (action.type) {
    case GET_PROFILE_DETAILS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };
    case UPDATE_PROFILE_DETAILS:
      return {
        ...state,
        updatedProfileDetails: action.payload,
        l_loading: false,
      };

        default:
      return state;
  }
}

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import printIcon from "../images/print.svg";
import logo from "../../Login/999.png";

const TransactionDetails = ({
  customerId,
  email,
  date,
  customerName,
  transactionReference,
  rewardAmount,
}) => {
  const [open, setOpen] = useState(false);

  const printElement = (elem) => {
    let domClone = elem.cloneNode(true);

    let printSection = document.getElementById("printSection");

    if (!printSection) {
      let printSection = document.createElement("div");
      printSection.id = "printSection";
      document.body.appendChild(printSection);
    }

    printSection.innerHTML = "";
    printSection.appendChild(domClone);

    window.print();
  };

  const printInfo = () => {
    printElement(document.getElementById("printThis"));
  };

  return (
    <div>
      <span className="trans_view_receipt" onClick={() => setOpen(true)}>
        View
      </span>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="trans_details_heading">Transaction Details</span>
          <small className="trans_details_subheading">
            View Transaction Details
          </small>
        </ModalHeader>
        <ModalBody>
          <div className="trans_details_sec1 d-flex justify-content-between align-items-center py-5 pt-4">
            <div className="trans_details_sec1_1 ">
            </div>
            <div className="trans_details_sec1_2">
              <button
                className="trans_details_sec1_2_btn p-2"
                onClick={() => printInfo()}
              >
                Print Receipt <img src={printIcon} className="" alt="" />
              </button>
            </div>
          </div>
          <div id="printSection">
            <div id="printThis">
          <div className="d-flex justify-content-start">
                <img className="logoInter mt-3" alt="logo" src={logo} />
              </div>
            <div className="trans_details_sec2 row py-2" >
              <div className="col-6">
                <div className="py-3">
                  <span className="trans_refId_label">Date</span>
                  <span className="trans_refId">{date}</span>
                </div>

                <div className="py-3">
                  <span className="trans_refId_label">Recipient</span>
                  <span className="trans_refId">{customerName}</span>
                </div>
              </div>
              {/*  */}
              <div className="col-6 pb-4">
                <div className="py-3">
                  <span className="trans_refId_label">
                    Transaction Reference
                  </span>
                  <span className="trans_refId">{transactionReference}</span>
                </div>

                <div className="py-3">
                  <span className="trans_refId_label">Reward Amount</span>
                  <span className="trans_refId">{rewardAmount}</span>
                </div>

                {/* <div className="py-3">
                <span className="trans_refId_label pb-1">Payment Status</span>
                <br />
                <span className="trans_btn">{phone}</span>
              </div> */}
              </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <div className="d-flex justify-content-end">
          <button
            className="trans_btn_close col-2 mt-3 mb-5 mr-4"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TransactionDetails;

import { GET_PARTICIPANTS, GET_PARTICIPANT, SET_LOADING, PARTICIPANT_ANALYTICS, PARTICIPANTS_ANALYTICS } from "./types";
import HTTP from "../http-trips";

import {
  createNotification,
  getActiveClient,
} from "../../dependency/UtilityFunctions";
let activeCampaign = getActiveClient();

export const setParticipant = (res) => {
  return {
    type: GET_PARTICIPANT,
    payload: res.data,
  };
};

export const setParticipants = (res) => {
  return {
    type: GET_PARTICIPANTS,
    payload: res.data.payload,
  };
};

export const setParticipantAnalytics = (res) => {
  return {
    type: PARTICIPANT_ANALYTICS,
    payload: res.data,
  };
};

export const setParticipantsAnalytics = (res) => {
  return {
    type: PARTICIPANTS_ANALYTICS,
    payload: res.data,
  };
};

export const setLoading = (res) => {
  return {
    type: SET_LOADING,
    payload: res,
  };
};

export const getParticipants =
  (cid, keyword, pageindex = 1, pagesize = 10) =>
  async (dispatch, getState) => {
      dispatch(setLoading(true));
    let altCid = getActiveClient();
    if (cid || altCid) {
      return HTTP.get(
        `api/Participant/GetParticipants?Keyword=${keyword}&pageindex=${pageindex}&pagesize=${pagesize}&ClientId=${
          cid ? cid : altCid.id
        }&typeId=0`
      )
        .then((res) => {
          dispatch(setLoading(false));
          dispatch(setParticipants(res));
          return res.data;
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (err.response) {
            return err.response;
          }
          let error = JSON.stringify(err);
          return error;
        });
    }
  };

export const getParticipant =
  (cid, email, keyword, pageindex = 1, pagesize = 10) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    let altCid = getActiveClient();
    if (cid || altCid) {
      return HTTP.get(
        `api/Participant/GetParticipantReferral?Email=${email}&Keyword=${keyword}&pageindex=${pageindex}&pagesize=${pagesize}&ClientId=${
          cid ? cid : altCid.id
        }&typeId=0`
      )
        .then((res) => {
          dispatch(setLoading(false));
          dispatch(setParticipant(res));
          return res.data;
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (err.response) {
            return err.response;
          }
          let error = JSON.stringify(err);
          return error;
        });
    }
  };

  export const getParticipantsAnalytics =
  (cid) =>
  async (dispatch, getState) => {
    let altCid = getActiveClient();
    if (cid || altCid) {
      return HTTP.get(
        `api/Participant/GetParticipantsAnalytics`
      )
        .then((res) => {
          dispatch(setParticipantsAnalytics(res));
          return res.data;
        })
        .catch((err) => {
          if (err.response) {
            return err.response;
          }
          let error = JSON.stringify(err);
          return error;
        });
    }
  };

export const getParticipantAnalytics =
  (cid, email) =>
  async (dispatch, getState) => {
    let altCid = getActiveClient();
    if (cid || altCid) {
      return HTTP.get(
        `api/Participant/GetParticipantReferralAnalytics?email=${email}`
      )
        .then((res) => {
          dispatch(setParticipantAnalytics(res));
          return res.data;
        })
        .catch((err) => {
          if (err.response) {
            return err.response;
          }
          let error = JSON.stringify(err);
          return error;
        });
    }
  };


import React, { useState } from "react";
import Loader from "react-loader-spinner";
import PaginationComponent from "react-reactstrap-pagination";

function ViewParticipants({
  pageSize,
  selectedPage,
  handleSelected,
  showProfile,
  totalParticipantsCount,
  participants,
  loading,
  changePageSize,
  types
}) {

  return (
    <>
      <div className="mx-1 mt-4 participant__table">
        <table className="wallet_table table table-responsive{-sm|-md|-lg|-xl}">
          <thead className="wallet_table-header">
            <tr className="">
              <th>First Name</th>
              <th>Last Name</th>
              <th>User Name</th>
              <th>Reward Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {participants?.length > 0 && !loading  &&
              participants.map((participant, index) => {
                return (
                  <tr key={index}>
                    <td>{participant.firstName}</td>
                    <td>{participant.lastName}</td>
                    <td>{participant.email}</td>
                    <td>{participant.rewardValue}</td>
                    <td>
                      <small
                        className="participants__referal--view-action"
                        onClick={() =>
                          showProfile(
                            participant.email,
                            participant.firstName,
                            participant.lastName
                          )
                        }
                      >
                        View Profile
                      </small>
                    </td>
                  </tr>
                );
              })}

            {loading && (
              <tr>
                <td colSpan="6">
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "12pt",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    className=" mt-5 col-sm-6  "
                  >
                    <Loader
                      type="RevolvingDot"
                      color="#00425f"
                      height={100}
                      width={100}
                    />
                  </div>
                </td>
              </tr>
            )}
            {!participants?.length && !loading &&(
              <tr>
                <td colSpan="12">
                  <h6 className="text-center pt-3">No Records Found</h6>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-end mt-3 mr-1">
        <PaginationComponent
          totalItems={totalParticipantsCount || 0}
          pageSize={Number(pageSize)}
          onSelect={(value) => handleSelected(value, "participants--pagination")}
          activePage={selectedPage}
          className="wallet_pag"
          name="participants--pagination"
        />
          <select
            title="page size"
            className="form-select wallet_page__size"
            onChange={(e) => changePageSize(e.target.value, e.target.name)}
            name="participants--pageSize"
            >
            {types?.map((value) => (
              <option
                value={value}
                key={value}
                name="types"
              >
                {value}
              </option>
            ))}
        </select>
      </div>
    </>
  );
}

export default ViewParticipants;

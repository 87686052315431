import {
  GET_AGENTS,
  ADD_AGENT,
  DELETE_USER,
  USER_LOADING,
  UPDATE_AGENT,
  PERMISSION_LOADING,
  GET_PERMISSION,
  GET_USER_CLIENTS,
  GET_AGENTS_FAIL,
  GET_SUB_AGENTS,
  GET_SUB_AGENTS_FAIL,
} from "../types";
import { getAccessToken } from "../../../dependency/UtilityFunctions";
const initislState = {
  agents: [],
  subAgents: [],
  getAgentErr: "",
  getSubAgentErr: "",
  permissions: [],
  loading: false,
  uCLoading: false,
  userClients: [],
  totalRecordCount: 0,
  access_token: getAccessToken(),
};

export default function (state = initislState, action) {
  switch (action.type) {
    case GET_AGENTS:
      return {
        ...state,
        agents: action.payload.payload.returnObjects,
        totalRecordCount: action.payload.payload.totalRecordCount,
        getAgentErr: "",
        loading: false,
      };
    case GET_SUB_AGENTS:
      return {
        ...state,
        subAgents: action.payload.payload.returnObjects,
        loading: false,
      };
    case GET_PERMISSION:
      return {
        ...state,
        permissions: action.payload.payload,
        loading: false,
      };
    case GET_USER_CLIENTS:
      return {
        ...state,
        userClients: action.payload.payload,
        uCLoading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        UPDATE_CAMPAIGN: state.campaigns.filter(
          (campaign) => campaign.id !== action.payload
        ),
      };
    case ADD_AGENT:
      return {
        ...state,
      };
    case UPDATE_AGENT:
      return {
        ...state,
        campaigns: [action.payload, ...state.campaigns],
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PERMISSION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_AGENTS_FAIL:
      if (action.payload.data !== "") {
        return {
          ...state,
          getAgentErr: action.payload.data.description
            ? action.payload.data.description
            : action.payload.data.payload.message
            ? action.payload.data.payload.message
            : "An Error Occured, Please try again.",
        };
      } else {
        return {
          ...state,
        };
      }
    case GET_SUB_AGENTS_FAIL:
      if (action.payload.data !== "") {
        return {
          ...state,
          getSubAgentErr: action.payload.data.description
            ? action.payload.data.description
            : action.payload.data.payload.message
            ? action.payload.data.payload.message
            : "An Error Occured, Please try again.",
        };
      } else {
        return {
          ...state,
        };
      }
    default:
      return state;
  }
}

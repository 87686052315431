/* eslint-disable no-underscore-dangle */
import { Ability, AbilityBuilder } from "@casl/ability";
// import store from "../redux/store"; //src/redux/store.js
// import { config } from "./permissions";
import {
  getPermissions,
  getUserType,
  getClients,
} from "../dependency/UtilityFunctions"; //src/dependency/UtilityFunctions.js

// Defines how to detect object's type
export const subjectName = (item) => {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
};

const ability = new Ability([], { subjectName });
let currentAuth;

currentAuth = {
  usetType: getUserType(),
  permissions: getPermissions(),
  clients: getClients(),
};

if (currentAuth) {
  ability.update(defineRulesFor(currentAuth));
}

export function defineRulesFor(auth) {
  const { can, rules } = new AbilityBuilder(Ability);

  if (`${auth.usetType}` === `SUPER_ADMIN`) {
    can("view", "AdminDashboard");
  } else {
    can("view", "MarketerDashboard");
  }
 const config = JSON.parse(sessionStorage.getItem("permissionsAndRoles"));

  let userPermissions = [];

  if (auth.permissions) {
    auth.permissions.forEach((val) => {
      const filteredConfig =
        config?.permissions?.find((data) => data?.name === val)?.permissions || null;

      userPermissions.push(filteredConfig);
    });

    if (userPermissions) {
      for (let permission of userPermissions.flat()) {
        can(permission?.action, permission?.subject);
      }
    }
  }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "ISW_01")[0].name)
  // ) {
  //   can("manage", "Users");
  //   can("create", "NewUsers");
  //   can("update", "EditUser");
  //   can("create", "super_admin");
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "ISW_02")[0].name)
  // ) {
  //   can("manage", "ClientAdmin");
  //   can("update", "EditClientAdmin");
  //   can("create", "AddClient");
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "ISW_03")[0].name)
  // ) {
  //   can("assign", "Permissions");
  //   can("manage", "Role");
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "ISW_04")[0].name)
  // ) {
  //   can("delete", "Users");
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "ISW_05")[0].name)
  // ) {
  //   can("manage", "AdminDashboard");
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "ISW_06")[0].name)
  // ) {
  //   can("manage", "Role");
  //   can("manage", "AddRole");
  //   can("manage", "EditRole");
  //   can("manage", "ViewRole");
  // }
  // let roleData = config.filter((v) => v.name === "ISW_07");

  // if (
  //   auth.permissions.includes(roleData.length > 0 ? roleData[0].name : "ISW_07")
  // ) {
  //   can("manage", "Audit");
  // }

  // // Products
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "PDU_01")[0].name)
  // ) {
  //   can("manage", "Users");
  //   can("create", "NewUsers");
  //   can("update", "EditUser");
  // }

  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "PDU_02")[0].name)
  // ) {
  //   can("manage", "Campains");
  //   can("view", "ViewCampain");
  // }

  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "PDU_03")[0].name)
  // ) {
  //   can("manage", "AgentDashboard");
  //   can("view", "Agent");
  //   can("manage", "subAccount"); //create
  //   can("create", "NewAgent"); //
  //   can("create", "newSubAccount"); //
  // }

  // // End
  // // Manage Campaign
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "MKT_01")[0].name)
  // ) {
  //   can("manage", "Campains");
  //   can("view", "ViewCampain");
  //   can("update", "EditCampaign"); //create
  //   can("create", "AddCampains"); //
  // }
  // // Disable/Enable campaign.
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "MKT_02")[0].name)
  // ) {
  //   can("enableDisable", "campaign");
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "MKT_03")[0].name)
  // ) {
  //   can("activate", "campaign"); //approve and reject campaign
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "MKT_04")[0].name)
  // ) {
  //   can("view", "campaignReport");
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "MKT_05")[0].name)
  // ) {
  //   can("view", "customerReferralReport");
  //   // can("view", "AddBusinessRules");
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "MKT_06")[0].name)
  // ) {
  //   can("manage", "AddBusinessRules");
  // }
  // if (
  //   auth.permissions.includes(config.filter((v) => v.name === "MKT_07")[0].name)
  // ) {
  //   can(["create", "update", "view"], "AddBusinessRules");

  //   // can("manage", "AdminDashboard");
  // }
  // if (
  //   auth.permissions.includes(
  //     config.filter((v) => v.name === "MKT_05")[0].name
  //   ) &&
  //   auth.permissions.includes(config.filter((v) => v.name === "MKT_05")[0].name)
  // ) {
  //   // can("manage", "AdminDashboard");
  // }
  // if (auth.permissions.includes(config[1].name)) {
  //   can(["create", "read", "edit", "manage"], "ClientAdmin");
  // }
  // if (auth.permissions.includes(config[2].name)) {
  //   can("Assign", "Permission");
  // }
  // if (auth.permissions.includes(config[3].name)) {
  //   can("delete", "Users");
  // }
  if (auth.clients.length > 0) {
    can("delete", "Users");
  }
  return rules;
}

export default ability;

import React, { Component } from "react";
import GroupIcon from "@material-ui/icons/Group";

import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { NotificationManager } from "react-notifications";
import { getClientList } from "../../../../../redux/client/clientActions";
import { getUsers } from "../../../../../redux/user/userActions";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { PersistData } from "../../../../../dependency/UtilityFunctions";

import { Card, CardTitle } from "reactstrap";
import "./contents.css";
import HTTP from "../../../../../redux/http-trips";
class Main extends Component {
  state = {
    dashDetLoading: false,
    marketers: [],
    marketersLoading: true,
    marketersErr: "",
    clients: [],
    clientsLoading: true,
    clientsErr: "",
  };
  componentDidMount() {
    HTTP.get(`/api/usersmgmt/getusersinrolecount/market`)
      .then((res) => {
        if (res.status === 200) {
          let { returnObjects } = res.data.payload;
          this.setState({
            marketerCount: returnObjects,
          });
        } else this.createNotification("error", "An error ocurred");
      })
      .then(async () => {
        await HTTP.get(`/api/usersmgmt/getclientscount`).then((res) => {
          if (res.status === 200) {
            let { returnObjects } = res.data.payload;
            this.setState({
              clientCount: returnObjects,
            });
          } else this.createNotification("error", "An error ocurred");
        });
      })
      .then(async () => {
        await this.props
          .getUsers(1, 7, "marketer")
          .then((res) => {
            if (res.status === 200) {
              let { payload } = res.data;
              this.setState({
                marketers: payload,
                marketersLoading: false,
              });
            } else {
              this.setState({
                getMarketerErr: res.data.title
                  ? res.data.title
                  : res.data.description
                  ? res.data.description
                  : "An error ocurred, please try again,",
              });
              this.createNotification(
                "error",
                res.data.title
                  ? res.data.title
                  : res.data.description
                  ? res.data.description
                  : "An error ocurred, please try again,"
              );
            }
          })
          .then(() => {
            this.props.getClientList(1, 7).then((res) => {
              if (res.status === 200) {
                let { returnObjects } = res.data.payload;
                this.setState({
                  clients: returnObjects,
                  clientsLoading: false,
                });
              } else {
                this.setState({
                  clientsErr: res.data.title
                    ? res.data.title
                    : res.data.description
                    ? res.data.description
                    : "An error ocurred, please try again,",
                });
                this.createNotification(
                  "error",
                  res.data.title
                    ? res.data.title
                    : res.data.description
                    ? res.data.description
                    : "An error ocurred, please try again,"
                );
              }
            });
          });
      })
      .catch((err) => {
        this.createNotification("error", "An error ocurred");
      });
  }

  toggleDashDetLoading = () => {
    this.setState({
      dashDetLoading: !this.state.dashDetLoading,
    });
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
      // alert();
      // }
    }
  };
  goTo = (where) => {
    switch (where) {
      case "client":
        this.props.history.push(where);
        break;
      case "users":
        this.props.history.push({ pathname: where, state: "marketer" });
        break;

      default:
        PersistData("activeB", "dashboard", false);
        break;
    }
  };
  render() {
    return (
      <main
        style={{
          flex: "1 1 0%",
        }}
      >
        <div className="contentBody  px-5">
          <div className="col-sm-12 my7">
            <h4
              style={{
                fontWeight: "bold",
                paddingTop: "10px",
                marginRight: "68%",
              }}
            >
              Dashboard
            </h4>
          </div>
          <Content goTo={this.goTo} state={this.state} />
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  dash: state.dash.dash,
});
const mapDispatchToProps = () => ({
  getClientList,
  getUsers,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps())
)(Main);

const Content = (props) => {
  return (
    <>
      <div className="container-fluid">
        <div style={{ display: "flex" }}>
          <div className=" " style={{ width: "50%" }}>
            <div
              className="row mb-3 displayFlex"
              style={{ flexDirection: "row", margin: "0" }}
            >
              <div className="col-xl-6 col-sm-6 py-2 ">
                <div className="card text-white h-100 ">
                  <div className="card-body refCards23">
                    <span>
                      <div className="displayFlex">
                        <div>
                          <span className="size16">Number of Marketers</span>
                          <h1 className="display-5 bigi23">
                            {props.state.marketerCount
                              ? props.state.marketerCount
                              : 0}
                          </h1>
                        </div>
                        <div className="spacer"></div>
                        <div>
                          <div className="rotate circle23" style={{}}>
                            <div className="ct fa fa-naira fa-2x"></div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="bgLightBlue col-sm-12"></div>
              </div>
              <div className="col-xl-6 col-sm-6 py-2">
                <div className="card text-white h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Number of Clients</span>
                        <h1 className="display-5 bigi23">
                          {" "}
                          {props.state.clientCount
                            ? props.state.clientCount
                            : 0}
                        </h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <GroupIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bgDarkBlue col-sm-12 mr-0"></div>
              </div>
            </div>
            <div className="col-sm-12">
              <Card body className="card card-font-size card-height">
                <CardTitle className="displayFlex bottomLine padd">
                  <span className="blak">
                    <GroupIcon className="mr-2" />
                    Marketers
                  </span>
                  <div className="spacer"></div>
                  <div
                    className="header2"
                    onClick={() => {
                      props.goTo("users");
                    }}
                  >
                    View All
                  </div>
                </CardTitle>

                <Grid className=" campaignsBody w-100">
                  <table className="table table-borderless ">
                    <thead className="campaignsTh">
                      <tr>
                        <th style={{ textAlign: "left" }}>
                          <span className="pr-2 card-font-size ">
                            <RadioButtonUncheckedIcon className="card-font-size " />
                          </span>
                          Name
                        </th>
                        <th>Phone</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="campaignsBody">
                      {!props.state.marketersLoading ? (
                        props.state.marketers.map((value, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <span className="pr-2 card-font-size ">
                                  <RadioButtonUncheckedIcon className="card-font-size " />
                                </span>
                                <span>{`${value.lastName}  ${value.givenName} `}</span>
                              </td>
                              <td>
                                <span>{value.phoneNumber}</span>
                              </td>
                              <td>
                                <span>
                                  {value.activated
                                    ? "Activated"
                                    : "Not Activated"}
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      ) : !props.state.getMarketerErr ? (
                        <tr>
                          <td colSpan="3" style={{ textAlign: "center" }}>
                            <span>Loading...</span>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="3" style={{ textAlign: "center" }}>
                            <span>{props.state.getMarketerErr}</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Grid>
              </Card>
            </div>
          </div>
          <div className="col-sm-6">
            <Card body className="card card-font-size card-height">
              <CardTitle className="displayFlex bottomLine padd">
                <span className="blak">
                  <GroupIcon className="mr-2" />
                  Clients
                </span>
                <div className="spacer"></div>
                <div
                  className="header2"
                  onClick={() => {
                    props.goTo("client");
                  }}
                >
                  View All
                </div>
              </CardTitle>

              <Grid className=" campaignsBody ">
                <table className="table table-borderless ">
                  <thead className="campaignsTh">
                    <tr>
                      <th style={{ textAlign: "left" }}>
                        <span className="pr-2 card-font-size ">
                          <RadioButtonUncheckedIcon className="card-font-size " />
                        </span>
                        Name
                      </th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody className="campaignsBody">
                    {!props.state.clientsLoading ? (
                      props.state.clients.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <span className="pr-2 card-font-size ">
                                <RadioButtonUncheckedIcon className="card-font-size " />
                              </span>
                              <span>{`${value.name}  `}</span>
                            </td>
                            <td>
                              <span>{value.description}</span>
                            </td>
                          </tr>
                        );
                      })
                    ) : !props.state.clientsErr ? (
                      <tr>
                        <td colSpan="2" style={{ textAlign: "center" }}>
                          Loading...
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="2" style={{ textAlign: "center" }}>
                          {props.state.clientsErr}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Grid>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

// withRouter

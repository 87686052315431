import React, { Component } from "react";
import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";

import HTTP from "../../../../../redux/http-trips";

import * as moment from "moment";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import Loader from "react-loader-spinner";

// import BackDrop from "./BackDrop/BackDrop";
import {
  getCritetias,
  addCriteria,
} from "../../../../../redux/criteria/criteriaActions";
import {
  LogoutNow,
  getActiveClient,
} from "../../../../../dependency/UtilityFunctions";
import "./campains.css";

class transactingReferredCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      rolesErr: "",
      totalRecordCount: 0,
      loading: true,
      sideDrawerOpen: true,
      anchorEl: null,
      open: null,
      selectedPage: 1,
      pageSize: 10,
      selectedCampaign: [],
      campaignStatus: [
        {
          CampaignStatuId: "1",
          Name: "In Draft",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 2,
          Name: "Activated",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 3,
          Name: "Not Activated",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 4,
          Name: "Stopped",
          DateCreated: new Date(),
        },
        {
          CampaignStatuId: 5,
          Name: "Expired",
          DateCreated: new Date(),
        },
      ],
      cs: [
        "",
        { color: "badge-secondary", name: "In Draft" },
        { color: "badge-info", name: "Activated" },
        { color: "badge-warning", name: "Not Activated" },
        { color: "badge-danger", name: "Stopped" },
        { color: "badge-danger", name: "Expired" },
      ],
    };
  }

  componentDidMount() {
    let { pageSize, selectedPage } = this.state;
    let altCid = getActiveClient();

    if (!altCid) {
      this.createNotification("error", "No assigned client");

      return;
    }
    this.getRoles(altCid.id, selectedPage, pageSize);
  }
  getRoles = (altCid, selectedPage = 1, pageSize = 10) => {
    if (selectedPage < 1) {return};
    HTTP.get(
      `/api/campaign/GetCurrentCampaignActiveReferredCustomers?clientId=${altCid}&Keyword=&PageIndex=${selectedPage}&PageTotal=${pageSize}`
    )
      .then((res) => {
        if (res && res.status === 200) {
          let { returnObjects, totalRecordCount } = res.data.payload;

          this.setState({
            roles: returnObjects??[],
            totalRecordCount: totalRecordCount,
            loading: false,
            rolesErr: "",
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let { data } = err.response;
          this.createNotification(
            "error",
            data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,"
          );

          this.setState({
            rolesErr: data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,",
            loading: false,
          });
        }
      });
  };
  getClientId = (id) => {
    //
    this.props.getCritetias(id).then((res) => {});
  };
  handleClick = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  logOut = () => {
    LogoutNow();
  };

  goTo = (url, role) => {
    this.setState({
      selectedCampaign: role,
    });
    this.props.history.push({
      pathname: url,
      // search: "?query=abc",
      state: { role },
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.login();
    }
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
      // alert();
      // }
    }
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  toggle = () => this.setState({dropdownOpen: !this.state.dropdownOpen});

  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  addCampain = () => {
    this.props.history.push("newRole");
  };
  handleSelected = (selectedPage) => {
    let { pageSize } = this.state;
    let altCid = getActiveClient();
    this.getRoles(altCid.id, selectedPage, pageSize);
    this.setState({ selectedPage: selectedPage });
  };
  changePageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      let { selectedPage, pageSize } = this.state;
      let altCid = getActiveClient();
      this.getRoles(altCid.id, selectedPage, pageSize);
    });
  };
  render() {
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <SideDrawer show={this.state.sideDrawerOpen} />

          <main
            style={{
              flex: "1 1 0%",
            }}
          >
            <ToolBar1
              cl
              sendClientId={this.getClientId}
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h4
                    style={{
                      fontWeight: "bold",
                      paddingTop: "10px",
                      marginRight: "68%",
                      width: "100%",
                    }}
                    className="w-100"
                  >
                    Transacting Referrals
                  </h4>

                  <div className="btnCOntainer">
                    <div
                      className="header33"
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                    >
                      Back
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className=" subacc-table container-fluid ">
                <div className="paginations d-flex justify-content-end w-100  "> */}
                <div className=" subacc-table container-fluid py-2 px-0">
                  <div className="paginations d-flex justify-content-end w-100  ">
                  <h6 className="pt-2 pr-1 text-info">Page size:</h6>
                  <>
                  <div className="d-flex" >
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} direction={this.props.direction}>
                          <DropdownToggle className="btn-outline btn-outline-info bg-light text-info" caret>{this.state.pageSize}</DropdownToggle>
                          <DropdownMenu {...this.props.args}>
                            <DropdownItem  onClick={() => this.changePageSize(10)}>
                              10
                            </DropdownItem>
                            <DropdownItem  onClick={() => this.changePageSize(25)}>
                              25
                            </DropdownItem>
                            <DropdownItem onClick={() => this.changePageSize(50)}>
                              50
                            </DropdownItem>
                            <DropdownItem onClick={() => this.changePageSize(100)}>
                              100
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      </>

                  {/* <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Tooltip title="Page size" aria-label="Page size">
                          <div
                            style={{
                              background: "#FFF",
                              padding: "5pt",
                              borderRadius: "5px",
                              boxShadow: "2px 2px 2px 2px #efefef",
                              height: "36px",
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            {...bindTrigger(popupState)}
                          >
                            {this.state.pageSize}
                          </div>
                        </Tooltip>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(10)}
                          >
                            10
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(25)}
                          >
                            25
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(50)}
                          >
                            50
                          </MenuItem>
                          <MenuItem
                            onMouseDown={popupState.close}
                            onClick={() => this.changePageSize(100)}
                          >
                            100
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState> */}
                  {/* <PaginationComponent
                    totalItems={this.state.totalRecordCount}
                    pageSize={this.state.pageSize}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                    maxPaginationNumbers={2}
                  /> */}
                </div>
                <table className="table table-borderless  ">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!this.state.loading ? (
                      this.state.roles.map((c, key) => {
                        return (
                          <tr key={key}>
                            <td>{`${c.firstName} ${c.lastName}`}</td>
                            <td>{c.email}</td>
                            <td>
                              {moment(c.createdOn).format("MMM DD, YYYY")}
                            </td>
                          </tr>
                        );
                      })
                    ) : !this.state.rolesErr ? (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            <Loader
                              type="RevolvingDot"
                              color="#00425f"
                              height={100}
                              width={100}
                              //timeout={3000} //3 secs
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "12pt",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className=" mt-5 col-sm-6  "
                          >
                            {this.state.rolesErr}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="paginations d-flex justify-content-end w-100  ">
                  <PaginationComponent
                    totalItems={this.state.totalRecordCount}
                    pageSize={this.state.pageSize}
                    onSelect={this.handleSelected}
                    activePage={this.state.selectedPage}
                    maxPaginationNumbers={2}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}
// export default connect(mapStateToProps)(Index);
const mapStateToProps = (state) => ({
  criteria: state.criteria.criterias,
  loading: state.criteria.loading, //totalRecordCount
  totalRecordCount: state.criteria.totalRecordCount, //
  getCriteriaErr: state.criteria.getCriteriaErr,
});
const mapDispatchToProps = () => ({
  getCritetias,
  addCriteria,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(transactingReferredCustomers);

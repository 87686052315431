import React, { Component } from "react";
import ToolBar1 from "./ToolBar/ToolBar1";
import SideDrawer from "./SideDrawer/SideDrawer";
import Main from "../Main/Index";
import { getCampaigns } from "../../../../redux/campain/campaignActions";
import {
  getDashLeaderBoardDetails,
  getDashDetails,
} from "../../../../redux/dash/dashActions";

import { connect } from "react-redux";
class Navs extends Component {
  state = {
    sideDrawerOpen: true,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  getClientId = (id) => {
    // return;
    this.props.getDashDetails(id);
    this.props.getCampaigns(id);
    this.props.getDashLeaderBoardDetails(id);
  };
  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  render() {
    return (
      <div className="">
        <ToolBar1
          clients={this.props.clients}
          sendClientId={this.getClientId.bind(this)}
          drawerToggleClickHandler={this.drawerToggleClickHandler}
        />

        <SideDrawer show={this.state.sideDrawerOpen} />

        {/* <ResponsiveDrawer /> */}
        {/* {backDrop} */}
        <Main sideDrawerOpen={this.state.ssideDrawerOpen} />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  clients: state.auth.user_clients,
});
const mapDispatchToProps = () => ({
  getCampaigns,
  getDashLeaderBoardDetails,
  getDashDetails,
});

export default connect(mapStateToProps, mapDispatchToProps())(Navs);

import React, { Component } from "react";
import { connect } from "react-redux";

import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
// import { ValidatorComponent } from "react-form-validator-core";

// Dialogue Hanlder
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { getPermissions } from "../../../../../redux/user/userActions";

import HTTP from "../../../../../redux/http-trips";

import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getClients } from "../../../../../dependency/UtilityFunctions";
import SimpleReactValidator from "simple-react-validator";
import "./addcampain.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddCampains extends Component {
  constructor() {
    super();
    this.state = {
      sideDrawerOpen: true,
      selectedDate: "",
      clients: getClients(),
      name: "",
      permissions: "",
      permissionsValue: [],
      allPermisions: [],
      ClientId: "",
      RewardTypeId: null,
      rewardTypes: [],
      loading: false,
      cid: null,
      ctid: null,
      selectedOption: null,
      open: false,
      agreed: false,
      hasErrors: true,
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    this.props.getPermissions().then((res) => {
      if (res.status === 200) {
        let { returnObjects } = res.data.payload;
        this.setState({ rewardTypes: returnObjects });
      }
    });
  }
  getPermissions = () => {
    HTTP.get(`api/UsersMgmt/GetPermissions`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        // ;
        //
        if (err.response) {
          return err.response;
        }

        let error = JSON.stringify(err);
        return error;
      });
  };
  toggleComfirm = (e) => {
    this.setState({
      open: !this.state.open,
    });
  };

  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onChange = (e) => {
    if (e && !e.target) {
      this.setState(
        {
          permissions: e,
          permissionsValue: e.map((v) => v.value),
        },
        () => {}
      );
    } else if (e && e.target)
      this.setState({
        [e.target.name]: e.target.value,
      });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  criteriaDelete = (id) => {
    this.setState({
      criterias: this.state.criterias.filter((criteria) => criteria.id !== id),
    });
    this.createNotification("success", "Deleted Successfully.");
  };
  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  addRole = () => {
    this.toggleLoading();
    let { name, permissionsValue } = this.state;

    let role = {
      name,
      permissions: permissionsValue,
    };
    HTTP.post("/api/RoleMgmt/Create", role)
      .then((res) => {
        this.toggleLoading();
        if (res.status === 200) {
          if (res.data.hasErrors === false) {
            this.createNotification("success", res.data.description);
            this.props.history.push({
              pathname: "roles",
              state: "",
            });
          } else {
            this.createNotification("error", res.data.description);
          }
        } else {
          this.createNotification(
            "error",
            res.data.title
              ? res.data.title
              : res.data.description
              ? res.data.description
              : "An error ocurred, please try again,"
          );
        }
      })
      .catch((err) => {
        this.toggleLoading();
        if (err.response) {
          let { data } = err.response;
          this.createNotification(
            "error",
            data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,"
          );
        }
      });
  };
  onSubmit = async (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      this.validator.hideMessages();

      this.addRole();
    } else {
      this.createNotification("error", "Kindly supply all fields.");

      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  render() {
    return (
      <>
        <div className="sidebarContentContainer">
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          <main
            className="container-fluid px-5 "
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7">
                <div style={{ display: "flex", flexDirection: "row" }}></div>
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    marginRight: "60%",
                  }}
                  className=""
                >
                  New Role
                </h4>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6 mx-0 py-0">
                    <div className="card my-4 singleHeight">
                      <div className="card-header headerWHite"> Details</div>
                      <div className="card-body">
                        <Form>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Name
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Input role name"
                              value={this.state.name}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "name",
                                this.state.name,
                                "required"
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              for="exampleSelect"
                              className="col-sm-4 labell"
                            >
                              Permissions
                            </Label>
                            <Select
                              className="w-100 px-3"
                              name="permissions"
                              id="permissions"
                              options={this.props.permissions}
                              closeMenuOnSelect={false}
                              // defaultValue={}
                              isMulti
                              onChange={this.onChange}
                            />

                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "permissions",
                                this.state.permissions,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                    {/* Button */}
                    <div className="float-right w-25">
                      <button
                        className="btn button2 my-4 w-100"
                        onClick={this.onSubmit}
                        disabled={this.state.loading}
                      >
                        {this.state.loading ? "Loading..." : "Create"}
                      </button>
                    </div>
                    {/* Button */}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.toggleComfirm}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ color: "#000" }}>
            {"Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ color: "#000" }}
            >
              Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleComfirm} color="danger">
              No
            </Button>
            <Button
              onClick={this.handleAgreed}
              style={{ background: "#212529" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.user.permissions.map((p) => ({
    ...p,
    label: p.description,
    value: p.id,
  })),
});
const mapDispatchToProps = () => ({
  getPermissions,
});

export default connect(mapStateToProps, mapDispatchToProps())(AddCampains);

import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
// import { Ability, AbilityBuilder } from "@casl/ability";
import { login, getRolesAndPermissions } from "../../../redux/auth/authActions";
// import { defineRulesFor, subjectName } from "../../../config/ability";
import { Button, Form, FormGroup, Input } from "reactstrap";

import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
// import { config } from "../../../config/permissions";
import logo from "./999.png";
import "./login.css";
import { Link } from "react-router-dom";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      err: "",
      loading: false,
    };
  }

  componentDidMount() {
    
    localStorage.setItem("activeB", "dashboard");
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };
  login = () => {
    if (!navigator.onLine) {
      this.createNotification("error", "You're Not Connected to thee Internet");
      return;
    }
    if (!this.state.email || !this.state.password) {
      this.createNotification("error", "Kindly supply email and password.");
      return;
    }
    this.toggleLoading();
    let { email, password } = this.state;
    let login = {
      email,
      password,
    };
    try {
      this.props.getRolesAndPermissions();
      this.props.login(login).then((res) => {
        this.toggleLoading();
        if (typeof res !== "undefined" && typeof res === "object") {
          let { status, data } = res;

          if (status && status === 200) {
            this.createNotification("success", "Logged in successfully");
            let { role } = data.payload;

            let adminDashboardConfig = {
              route: "/marketer/admin-dashboard",
              nav: "admin-dashboard",
            };
            let mktDashboardConfig = {
              route: "/marketer/dashboard",
              nav: "dashboard",
            };
            let routeConfig =
              `${role}` === `SUPER_ADMIN`
                ? adminDashboardConfig
                : mktDashboardConfig;

            localStorage.setItem("activeB", routeConfig.nav);
            this.props.history.push(routeConfig.route);
            window.location.reload();
          } else if (status !== 200)
            this.createNotification(
              "error",
              data && data.description
                ? data.description
                : "An Error Occured, Please try again."
            );
        }
      });
    } catch (error) {}
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.login();
    }
  };
  createNotification = (type, msg = null) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  render() {
    return (
      <div className="login-body" style={{ height: "100%" }}>
        <div
          className="container d-flex justify-content-center align-items-center login-height "
          style={{ height: "100%" }}
        >
          <div className="col-sm-12 col-md-6  col-lg-5 lol">
            <div className="login-form-container">
              <Form>
                <div className="mb-4">Login</div>
                <FormGroup className="form-group has-search inputWithIcon">
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    className="text-field-props"
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    autoComplete="off"
                  />
                  <span className="form-control-feedbackUser">
                    <PersonIcon />
                  </span>
                </FormGroup>

                <FormGroup className="form-group has-search2 inputWithIcon">
                  <Input
                    type="password"
                    name="password"
                    id="Password"
                    placeholder="Password "
                    className="text-field-props"
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    autoComplete="off"
                  />
                  <span className="form-control-feedback">
                    <LockIcon />
                  </span>
                </FormGroup>
                <Button
                  className="login-btn btn col-sm-12"
                  onClick={this.login}
                  style={{ textAlign: "center" }}
                  disabled={this.state.loading}
                >
                  {this.state.loading ? "Loading..." : "Login to your account"}
                </Button>
                <small className="forgot__password-link text-center">Forgot Password? <Link to="/forgot-password/reset">Reset</Link></small>
                <div>
                  <img
                    className="logoInter mt-3"
                    alt="logo"
                    //"https://radar.techcabal.com/uploads/default/original/2X/b/b29ebad243a65dbb6bb163b8de923e5f5bdc2051.jpg"
                    src={logo}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = () => ({
  login,
  getRolesAndPermissions,
});

export default connect(mapStateToProps, mapDispatchToProps())(Index);

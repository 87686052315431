import { GET_PROFILE_DETAILS, UPDATE_PROFILE_DETAILS } from "./types";
import { createNotification } from "../../dependency/UtilityFunctions";
import HTTP from "../http-trips";

export const getProfileDetails = (id) => (dispatch) => {
  return HTTP.get(`api/UsersMgmt/GetUserInfo/${id}`)

    .then((res) => {
      dispatch({
        type: GET_PROFILE_DETAILS,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const editProfileDetails = (payload) => (dispatch) => {
  return HTTP.post(`api/UsersMgmt/MapUserInfo`, payload)

    .then((res) => {
      createNotification("success", "Details successfully updated");
      dispatch({
        type: UPDATE_PROFILE_DETAILS,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      if (err.response) {
        createNotification(
          "error",
          "could not update user details, Please try again later"
        );
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const updatePassword = (payload) => () => {
  return HTTP.post(`api/UsersMgmt/UpdatePassword`, payload)

    .then((res) => {
      if (res?.data?.hasErrors) {
        createNotification("error", res.data.payload);
      } else {
        createNotification("success", res.data.payload);
      }

      return res;
    })
    .catch((err) => {
      if (err.response) {
        if (err?.response?.data?.errors) {
          createNotification("error", "Bad Request: please supply all fields");
        } else {
          createNotification(
            "error",
            "something happened, unable  to process request"
          );
        }
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

import React, { Component } from "react";
import { connect } from "react-redux";

import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import EditIcon from "@material-ui/icons/Edit";

import HTTP from "../../../../../redux/http-trips";

import {
  getCampaigns,
  updateCampaign,
  deleteCampaign,
  addCampaign,
  addBussinesRule,
  getRewardableTransactionTypes,
} from "../../../../../redux/campain/campaignActions";
import { getCritetias } from "../../../../../redux/criteria/criteriaActions";
import { getClientList } from "../../../../../redux/client/clientActions";

import { getActiveClient } from "../../../../../dependency/UtilityFunctions";
import { Form, FormGroup, Label, Input } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import "./addcampain.css";
import moment from "moment";

class BusinessRule extends Component {
  constructor() {
    super();
    this.state = {
      sideDrawerOpen: true,
      selectedDate: "",
      startDate: new Date(),
      endDate: new Date(),
      Title: "",
      Description: "",
      CampaignId: "",
      RewardableTransactionTypeId: null,
      RewardableTransactionTypenNames: [],
      RewardableValueThreshold: null,
      RewardValue: null,
      RejectionAttributes: [],
      AgentRewardValue: null,
      transactionType: "",
      businessRuleId: "",
      emptyStatus: false,
      isEdit: false,
      billersCategory: [],
      billers: [],
      selectedBillerCategory: "",
      billersCategoryLoading: true,
      billersLoading: true,
      businessRulesLoading: true,
      selectedBillerCategoryValue: "",
      selectedBiller: "",
      selectedBillerValue: "",
      RewardableTransactionTypes: [
        {
          criteriaId: "1",
          Name: "Airtime purchase",
        },
        {
          criteriaId: 2,
          Name: "Global mall purchase",
        },
        {
          criteriaId: 3,
          Name: "Event ticket purchase",
        },
        {
          criteriaId: 4,
          Name: "Flight ticket",
        },
        {
          criteriaId: 5,
          Name: "Funds transfer",
        },
      ],
      loading: false,
      businesRules: [],

      selectedOption: null,
      open: false,
      agreed: false,
    };
    this.validator = new SimpleReactValidator();
  }
  async componentDidMount() {
    const { campaign } = this.props.location.state;
    if (!campaign && !campaign.campaignId) this.props.history.goBack();
    this.setState(
      {
        CampaignId: campaign.campaignId,
        CampaignTitle: campaign.title,
      },
      () => {}
    );
    await this.getBusinessRules(campaign.campaignId);

    let activeCLient = getActiveClient();
    if (!activeCLient) {
      this.createNotification("error", "No assigned client");

      return;
    }
    await this.props
      .getCritetias(activeCLient.id, 100, 1)
      .then((res) => {
        if (res.status === 200) {
          let RTT = res.data.payload.returnObjects;

          let tmp = [];
          RTT.map((v) => {
            let holder = {};
            holder.criteriaId = v.transactionTypeId;
            holder.Name = v.title;
            tmp.push(holder);
            return tmp;
          });
          this.setState({
            RewardableTransactionTypes: tmp,
          });
        }
      })
      .then(async () => await this.getBillersCategory());
  } //
  getBusinessRules = async (campaignId) => {
    await HTTP.get(
      `/api/Campaign/GetCampaignBusinessRules?campaignId=${campaignId}`
    )
      .then((res) => {
        this.toggleBusinessRulesLoading();
        let { returnObjects, totalRecordCount } = res.data.payload;
        this.setState(
          {
            businesRules: returnObjects.map((v) => ({
              ...v,
              transactionType: v.rewardableTransactionTypeTitle,
            })),
            totalRecordCount,
          },
          () => {}
        );
      })
      .catch((err) => {
        this.toggleBillersCategoryLoading();
        if (err.response) {
          let { data } = err.response;
          this.createNotification(
            "error",
            data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,"
          );
        }
      });
  };
  toggleBusinessRulesLoading = () => {
    this.setState({
      businessRulesLoading: !this.state.businessRulesLoading,
    });
  };
  toggleBillersCategoryLoading = () => {
    this.setState({
      billersCategoryLoading: !this.state.billersCategoryLoading,
    });
  };
  toggleBillersLoading = () => {
    this.setState({
      billersLoading: !this.state.billersLoading,
    });
  };
  getBillersCategory = () => {
    HTTP.get(`/api/Quickteller/GetBillerCategories`)
      .then((res) => {
        this.toggleBillersCategoryLoading();
        let { payload } = res.data;
        this.setState({
          billersCategory: payload.map((v) => ({
            ...v,
            label: v.categoryname,
            value: v.categoryid,
          })),
        });
      })
      .catch((err) => {
        this.toggleBillersCategoryLoading();
        if (err.response) {
          let { data } = err.response;
          this.createNotification(
            "error",
            data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,"
          );
        }
      });
  };
  getBillersByCategory = (id) => {
    HTTP.get(`/api/Quickteller/GetBillersByCategory/${id}`)
      .then((res) => {
        this.toggleBillersLoading();
        let { payload } = res.data;
        this.setState({
          billers: payload.map((v) => ({
            ...v,
            label: v.billername,
            value: v.billerid,
          })),
        });
      })
      .catch((err) => {
        this.toggleBillersLoading();
        if (err.response) {
          let { data } = err.response;
          this.createNotification(
            "error",
            data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,"
          );
        }
      });
  };
  toggleComfirm = (e) => {
    this.setState({
      open: !this.state.open,
    });
  };

  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onChange = (e) => {
    if (e && !e.target) {
      this.setState(
        {
          selectedBillerCategory: e,
          selectedBillerCategoryValue: e.value,
        },
        () => {
          this.setState({
            billersLoading: true,
          });
          this.getBillersByCategory(this.state.selectedBillerCategoryValue);
        }
      );
    } else if (e)
      this.setState({
        [e.target.name]: e.target.value,
        emptyStatus: false,
      });
  };
  setBiller = (e) => {
    if (e && !e.target) {
      this.setState(
        {
          selectedBiller: e,
          RejectionAttributes: e.map((v) => v.label),
        },
        () => {}
      );
    } else if (e)
      this.setState({
        [e.target.name]: e.target.value,
        emptyStatus: false,
      });
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  updateBussRule = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.createNotification("error", "Kindly supply all fields.");
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.validator.hideMessages();
    let {
      Title,
      Description,
      CampaignId,
      RewardableTransactionTypeId,
      RewardableValueThreshold,
      RewardValue,
      RejectionAttributes,
      AgentRewardValue,

      businessRuleId,
    } = this.state;

    let newBusinesRules = {
      businessRuleId,
      Title,
      Description,
      CampaignId,
      RewardableTransactionTypeId,
      RewardableValueThreshold: parseInt(RewardableValueThreshold),
      RewardValue: parseInt(RewardValue),
      RejectionAttributes,
      AgentRewardValue: parseInt(AgentRewardValue),
    };

    this.toggleLoading();
    HTTP.post(`/api/Campaign/Updatebusinessrule`, newBusinesRules)
      .then((res) => {
        this.toggleLoading();
        if (res && res.status === 200) {
          this.createNotification(
            "success",
            res.data.description
              ? res.data.description
              : res.data.payload.message
          );

          this.getBusinessRules(newBusinesRules.CampaignId);
          this.setState({
            Title: "",
            Description: "",
            RewardableTransactionTypeId: "",
            RewardableValueThreshold: "",
            RewardValue: "",
            RejectionAttributes: [],
            AgentRewardValue: "",
            transactionType: "",
            isEdit: false,
            selectedBillerCategory: "",
          });
        }
      })
      .catch((err) => {
        this.toggleLoading();
        if (err && err.response) {
          let { data } = err.response;
          this.createNotification(
            "error",
            data.description
              ? data.description
              : data.title
              ? data.title
              : "An error Occured, Please Try again"
          );
        }
      });
  };
  criteriaEdit = (id) => {
    let { businesRules, RewardableTransactionTypes } = this.state;

    let businesRule = businesRules.filter(
      (v) => v.businessRuleId === id || v.id === id
    )[0];
    // let businesRule =businesRules.filter(v=>v.businessRuleId===id)[0];

    let {
      businessRuleId,
      description,
      rejectionAttributes,
      rewardValue,
      rewardableTransactionTypeId,
      rewardableValueThreshold,
      title,
      agentRewardValue,
      Description,
      RejectionAttributes,
      RewardValue,
      RewardableTransactionTypeId,
      Title,
      AgentRewardValue,
    } = businesRule;

    let rType = rewardableTransactionTypeId
      ? rewardableTransactionTypeId
      : RewardableTransactionTypeId;
    let tType = RewardableTransactionTypes.filter(
      (v) => v.criteriaId === rType
    );

    this.setState(
      {
        Title: title || Title,
        Description: description || Description,
        RewardableTransactionTypeId:
          rewardableTransactionTypeId || RewardableTransactionTypeId,
        RewardableValueThreshold:
          rewardableValueThreshold || RewardableTransactionTypeId,
        RewardValue: rewardValue || RewardValue,
        RejectionAttributes: rejectionAttributes || RejectionAttributes,
        AgentRewardValue: agentRewardValue || AgentRewardValue,

        transactionType: tType.length > 0 ? tType[0].Name : "",
        businessRuleId,
        isEdit: true,
      },
      () => {}
    );
  };
  criteriaDelete = (id) => {
    this.setState({
      businesRules: this.state.businesRules.filter(
        (criteria) => criteria.id !== id
      ),
    });
    this.createNotification("success", "Deleted Successfully.");
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const { businesRules } = this.state;
    if (businesRules.length < 1) {
      this.createNotification("error", "Business Rules is empty");
      return;
    }
    let tmpBusinesRules = businesRules.map((x) => ({
      Title: x.Title,
      Description: x.Description,
      CampaignId: x.CampaignId,
      RewardableTransactionTypeId: x.RewardableTransactionTypeId,
      RewardableValueThreshold: x.RewardableValueThreshold,
      RejectionAttributes: x.RejectionAttributes,
      RewardValue: x.RewardValue,
      AgentRewardValue: x.AgentRewardValue,
    }));
    this.toggleLoading();
    this.props.addBussinesRule(tmpBusinesRules).then((res) => {
      let { status } = res;
      this.toggleLoading();

      if (status === 200) {
        this.createNotification(
          "success",
          res.data.description ? res.data.description : res.data.payload.message
        );
        this.setState({
          businesRules: [],
        });
        this.props.history.push("campiagns");
      } else {
        this.createNotification(
          "error",
          res.data.description
            ? res.data.description
            : "An error Occured, Please Try again"
        );
      }
    });
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  render() {
    return (
      <>
        <div className="" style={{ background: "#FFF" }}>
          <div className="sidebarContentContainer">
            <ToolBar1
              drawerToggleClickHandler={this.drawerToggleClickHandler}
            />
            <SideDrawer show={this.state.sideDrawerOpen} />
            <main
              className="container-fluid px-5 "
              style={{
                flex: "1 1 0%",
              }}
            >
              <div className="contentBody  px-5">
                <div className="col-sm-12 my7 row">
                  <h4
                    style={{
                      fontWeight: "bold",
                      paddingTop: "10px",
                      width: "80%",
                    }}
                    className=""
                  >
                    Business Rules :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {this.state.CampaignTitle}
                    </span>
                  </h4>
                  {/* <div className=" w-100"> */}
                  <button
                    style={{ width: "20%" }}
                    className="btn button2 float-right my-4 wid"
                    onClick={() => this.props.history.push("campaigns")}
                  >
                    {"Back"}
                  </button>
                  {/* </div> */}
                </div>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6 mx-0 py-0">
                      <div className="card my-4 singleHeight">
                        <div className="card-header headerWHite"> Details</div>
                        <div className="card-body">
                          <Form>
                            <FormGroup className="row card-font-size">
                              <Label
                                for="exampleEmail"
                                className="col-sm-4 labell "
                              >
                                Title
                              </Label>
                              <Input
                                className="col-sm-7 textField"
                                type="text"
                                name="Title"
                                id="businesRuleTitle"
                                placeholder="Title"
                                value={this.state.Title}
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}
                                autoComplete="off"
                              />
                              <div className="col-sm-4"></div>
                              <div className="col-sm-7 text-danger p-0 f8">
                                {this.validator.message(
                                  "Title",
                                  this.state.Title,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label
                                for="description"
                                className="col-sm-4 labell"
                              >
                                Description
                              </Label>
                              <Input
                                style={{ height: "130px !important" }}
                                className="col-sm-7 textArea  textField"
                                type="textarea"
                                name="Description"
                                id="description"
                                value={this.state.Description}
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}
                                autoComplete="off"
                              />
                              <div className="col-sm-4"></div>
                              <div className="col-sm-7 text-danger p-0 f8">
                                {this.validator.message(
                                  "Description",
                                  this.state.Description,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label
                                for="exampleSelect"
                                className="col-sm-4 labell"
                              >
                                Transaction Type
                              </Label>
                              <Input
                                type="select"
                                name="transactionType"
                                id="transactionType"
                                placeholder="Select Campaign Type"
                                className="col-sm-7 textField "
                                onChange={this.onChange}
                                onKeyPress={this.onKeyPress}
                                value={this.state.transactionType}
                                autoComplete="off"
                              >
                                <option>Select transaction type</option>
                                {this.state.RewardableTransactionTypes.map(
                                  (value) => {
                                    return (
                                      <option key={value.criteriaId}>
                                        {value.Name}
                                      </option>
                                    );
                                  }
                                )}
                              </Input>
                              <div className="col-sm-4"></div>
                              <div className="col-sm-7 text-danger p-0 f8">
                                {this.validator.message(
                                  "Campaign Type",
                                  this.state.transactionType,
                                  "required"
                                )}
                              </div>
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mx-0 py-0">
                      <div className="col-sm-12 mx-   ">
                        <div
                          className="card card my-4 singleHeight"
                          style={{ height: "auto" }}
                        >
                          <div className="card-header headerWHite">Rules</div>
                          <div className="card-body">
                            <Form>
                              <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Minimum Amount
                                </Label>

                                <Input
                                  className="col-sm-7 textField"
                                  type="number"
                                  name="RewardableValueThreshold"
                                  id="RewardableValueThreshold"
                                  placeholder="N1,000"
                                  onChange={this.onChange}
                                  onKeyPress={this.onKeyPress}
                                  value={this.state.RewardableValueThreshold}
                                  autoComplete="off"
                                />
                                <div className="col-sm-4"></div>
                                <div className="col-sm-7 text-danger p-0 f8">
                                  {this.validator.message(
                                    "Minimum Amount",
                                    this.state.RewardableValueThreshold,
                                    "required|numeric"
                                  )}
                                </div>
                              </FormGroup>

                              <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Reward Value
                                </Label>

                                <Input
                                  className="col-sm-7 textField"
                                  type="number"
                                  name="RewardValue"
                                  id="RewardValue"
                                  placeholder="N1,000"
                                  onChange={this.onChange}
                                  onKeyPress={this.onKeyPress}
                                  value={this.state.RewardValue}
                                  autoComplete="off"
                                />
                                <div className="col-sm-4"></div>
                                <div className="col-sm-7 text-danger p-0 f8">
                                  {this.validator.message(
                                    "Reward Value",
                                    this.state.RewardValue,
                                    "required|numeric"
                                  )}
                                </div>
                              </FormGroup>

                              <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Agent Reward Value
                                </Label>

                                <Input
                                  className="col-sm-7 textField"
                                  type="number"
                                  name="AgentRewardValue"
                                  id="AgentRewardValue"
                                  placeholder="N1,000"
                                  onChange={this.onChange}
                                  onKeyPress={this.onKeyPress}
                                  value={this.state.AgentRewardValue}
                                  autoComplete="off"
                                />
                                <div className="col-sm-4"></div>
                                <div className="col-sm-7 text-danger p-0 f8">
                                  {this.validator.message(
                                    " Agent Reward Value",
                                    this.state.AgentRewardValue,
                                    "required|numeric"
                                  )}
                                </div>
                              </FormGroup>
                              <FormGroup className="row">
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-4 labell"
                                >
                                  Billers Category
                                </Label>
                                <Select
                                  className="col-sm-7 pl-0 pr-0    "
                                  name="RejectionAttributes"
                                  id="RejectionAttributes"
                                  options={this.state.billersCategory}
                                  closeMenuOnSelect={true}
                                  // defaultValue={}
                                  // isMulti
                                  onChange={this.onChange}
                                  isLoading={this.state.billersCategoryLoading}
                                />
                                <Label
                                  for="exampleEmail"
                                  className="col-sm-11 labell"
                                >
                                  Rejection Criteria
                                </Label>
                                <Select
                                  className="col-sm-11 textField pr-0"
                                  name="RejectionAttributes"
                                  id="RejectionAttributes"
                                  options={this.state.billers}
                                  closeMenuOnSelect={false}
                                  value={
                                    this.state.RejectionAttributes
                                      ? this.state.RejectionAttributes.map(
                                          (v) => ({ label: v, value: v })
                                        )
                                      : []
                                  }
                                  // defaultValue={
                                  //   this.state.RejectionAttributes[0]
                                  // }
                                  isMulti
                                  onChange={this.setBiller}
                                  isLoading={this.state.billersLoading}
                                />
                              </FormGroup>

                              <div className="col-sm-12 d-flex justify-content-end">
                                {/* <div className="col-sm-7"></div> */}
                                <button
                                  className="btn  btn-danger ml-auto mt-3 "
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      Title: "",
                                      Description: "",
                                      RewardableTransactionTypeId: "",
                                      RewardableValueThreshold: "",
                                      RewardValue: "",
                                      rejectionAttributes: "",
                                      AgentRewardValue: "",
                                      transactionType: "",
                                      businessRuleId: "",
                                      isEdit: false,
                                    });
                                  }}
                                >
                                  Clear
                                </button>
                                <button
                                  className="btn button btn-default  mt-3 ml-2"
                                  onClick={this.updateBussRule.bind(this)}
                                  disabled={this.state.loading}
                                >
                                  {this.state.loading ? "Loading..." : "Update"}
                                </button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* 1234Test */}
                  </div>
                  <div
                    className="card addCampain my-4"
                    style={{ height: "auto" }}
                  >
                    <div className="card-header headerWHite">
                      <span>Reward Criteria</span>
                      {/* <span
                    className="remove-card-header col-sm-2"
                    style={{ marginLeft: "35%" }}AgentRewardValue
                  >
                    Remove selected
                  </span> */}
                    </div>
                    <div className="card-body">
                      <div>
                        <div>
                          <table className="table addCampaintable campainstable table-hover">
                            <thead className="buss-thead">
                              <tr>
                                <th style={{ textAlign: "left" }}>Title</th>
                                <th>Criteria</th>
                                <th>Minimum Amount</th>
                                <th>Date </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.businesRules.map((value) => {
                                return (
                                  <tr key={value.id}>
                                    <td>{value.Title || value.title}</td>
                                    <td>
                                      {value.RewardableTransactionTypeName ||
                                        value.rewardableTransactionTypeTitle ||
                                        value.rewardableTransactionTypeId}
                                    </td>
                                    <td>
                                      {value.RewardableValueThreshold ||
                                        value.rewardableValueThreshold}
                                    </td>
                                    <td>
                                      {moment(value.dateCreated).format(
                                        "YYYY-MM-DD, HH:mm"
                                      )}
                                    </td>
                                    <td>
                                      <span
                                        className="exit"
                                        onClick={this.criteriaEdit.bind(
                                          this,
                                          value.businessRuleId || value.id
                                        )}
                                      >
                                        <EditIcon className="exitSvg" />
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  campains: state.campain.campaigns,
  campaignType: state.campain.campaignType,
  campaignStatus: state.campain.campaignStatus,
  clients: state.client.clients,
});
const mapDispatchToProps = () => ({
  addCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaigns,
  getClientList,
  addBussinesRule,
  getRewardableTransactionTypes,
  getCritetias,
});

export default connect(mapStateToProps, mapDispatchToProps())(BusinessRule);

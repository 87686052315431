import React, { Component } from "react";

import AssignmentIcon from "@material-ui/icons/Assignment";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import GroupIcon from "@material-ui/icons/Group";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Campains from "./Campaign/Campains";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { NotificationManager } from "react-notifications";
import { getDashDetails } from "../../../../redux/dash/dashActions";
import { getCampaigns } from "../../../../redux/campain/campaignActions";
import { getSubAgents } from "../../../../redux/agent/agentActions";

import { getActiveClient } from "../../../../dependency/UtilityFunctions";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import * as moment from "moment";
import { Switch, Route } from "react-router-dom";

import { Card, CardTitle, CardText } from "reactstrap";
import "./contents.css";

class Main extends Component {
  state = {
    dashDetLoading: false,
    firstName: "",
    lastName: "",
    customerId: "",
  };
  componentDidMount() {
    const state = this.props.location.state;

    let { firstName, lastName, customerId } = state.agent;
    this.setState({
      firstName,
      lastName,
      customerId,
    });

    let activeClient = getActiveClient();
    if (!activeClient) {
      this.createNotification("error", "No assigned client");

      return;
    }
    try {
      this.props.getDashDetails(activeClient.id).then(async (res) => {
        await this.props.getCampaigns().then(async (res) => {
          await this.props.getSubAgents(customerId);
        });
      });
    } catch (error) {}
  }

  toggleDashDetLoading = () => {
    this.setState({
      dashDetLoading: !this.state.dashDetLoading,
    });
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
      // alert();
      // }
    }
  };
  goTo = (where) => {
    switch (where) {
      case "/marketer/add-campiagns":
        localStorage.setItem("activeB", "campians");
        this.props.history.push(where);
        break;
      case "sub-agent":
        localStorage.setItem("activeB", "agent");
        this.props.history.push({
          pathname: where,
          state: this.state.customerId,
        });
        break;

      default:
        localStorage.setItem("activeB", "dashboard");
        break;
    }
  };
  render() {
    return (
      <main
        className=" px-5 main pb-5"
        style={{
          marginTop: "64px",
          fontFamily: "Poppins",
          width: "85%",
          paddingLeft: "0pt",
          marginLeft: "14%",
          zIndex: "1",
        }}
      >
        <div className="col-sm-12 my7">
          <h4
            style={{
              fontWeight: "bold",
              paddingTop: "10px",
              marginRight: "68%",
            }}
          >
            {`${this.state.firstName} ${this.state.lastName}`}
          </h4>
        </div>
        <Content
          goTo={this.goTo}
          campaigns={this.props.campains}
          getCampaignErr={this.props.getCampaignErr}
          campaignType={this.props.campaignType}
          loading={this.props.loading}
          subAgents={this.props.subAgents}
          agentloading={this.props.agentloading}
          getSubAgentErr={this.props.getSubAgentErr}
          dash={this.props.dash}
        />
        {/* {JSON.stringify(this.props.sideDrawerOpen)} */}
      </main>
    );
  }
}
// export default connect(mapStateToProps   )(Index);
const mapStateToProps = (state) => ({
  dash: state.dash.dash,
  campains: state.campain.campaigns,
  getCampaignErr: state.campain.getCampaignErr,
  campaignType: state.campain.campaignType,
  loading: state.campain.loading,
  subAgents: state.agent.subAgents,
  agentloading: state.agent.loading,
  getSubAgentErr: state.agent.getSubAgentErr,
});
const mapDispatchToProps = () => ({
  getDashDetails,
  getCampaigns,
  getSubAgents,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps())
)(Main);

// export default withRouter(Main);
const Content = (props) => {
  let count = 0;
  let c = 0;
  return (
    <>
      <div className="container-fluid">
        <div className="container-fluid" style={{ padding: "0" }}>
          <div className=" " style={{ display: "" }}>
            <div
              className="row mb-3 displayFlex"
              style={{ flexDirection: "row", margin: "0" }}
            >
              <div className="col-xl-3 col-sm-6 py-2 ">
                <div className="card  h-100 ">
                  <div className="card-body refCards23">
                    <spa>
                      <div className="displayFlex">
                        <div>
                          <span className="size16">Total Amount Spent</span>
                          <h1 className="display-5 bigi23">
                            {" "}
                            {props.dash[0]
                              ? props.dash[0]["totalAmountSpentOfBudget"]
                              : "0"}
                          </h1>
                        </div>
                        <div className="spacer"></div>
                        <div>
                          <div className="rotate circle23" style={{}}>
                            <div className="ct fa fa-naira fa-2x"></div>
                          </div>
                        </div>
                      </div>
                    </spa>
                    <div className="size10">Open Wallet</div>
                  </div>
                </div>
                <div className="bgLightBlue col-sm-12"></div>
              </div>
              <div className="col-xl-3 col-sm-6 py-2">
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Total Referred</span>
                        <h1 className="display-5 bigi23">
                          {" "}
                          {props.dash[0]
                            ? props.dash[0]["totalCustomersReferred"]
                            : "0"}
                        </h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <GroupIcon />
                          </span>
                          {/* <i className="fa fa-user-friends"></i> */}
                          {/* <span className="ct fas fa-user-friends fa-2x"></span> */}
                        </div>
                      </div>
                    </div>
                    <div className="size10">View</div>
                  </div>
                </div>
                <div className="bgDarkBlue col-sm-12 mr-0"></div>
              </div>
              <div className="col-xl-3 col-sm-6 py-2">
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Transacting Referrals</span>
                        <h1 className="display-5 bigi23">
                          {props.dash[0]
                            ? props.dash[0]["totalTransactingReferrals"]
                            : "0"}
                        </h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <CreditCardIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">View</div>
                  </div>
                </div>
                <div className="bgRed col-sm-12"></div>
              </div>
              <div className="col-xl-3 col-sm-6 py-2">
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Activated Accounts</span>
                        <h1 className="display-5 bigi23">
                          {props.dash[0]
                            ? props.dash[0]["totalActivatedCustomers"]
                            : "0"}
                        </h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <VerifiedUserIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">View</div>
                  </div>
                </div>
                <div className="bgDarkBlue col-sm-12"></div>
              </div>
            </div>
          </div>
          {/* Let go ..... */}
          <div
            className=" mb-3 displayFlex  d-none"
            // style={{ flexDirection: "row", padding: "0" }}
          >
            <div className="displayFlex " style={{ width: "51%" }}>
              <div className="  no-padding mr" style={{ width: "50%" }}>
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Total Amount Spent</span>
                        <h1 className="display-5 bigi23">N14,000</h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <div className=" fa fa-naira fa-2x"></div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">Open Wallet</div>
                  </div>
                </div>
                <div className="bgLightBlue col-sm-12"></div>
              </div>

              <div className="  no-padding " style={{ width: "50%" }}>
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Total Referred</span>
                        <h1 className="display-5 bigi23">898</h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <GroupIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">View</div>
                  </div>
                </div>
                <div className="bgDarkBlue col-sm-12 mr-0"></div>
              </div>
            </div>
            <div className="displayFlex ml-4" style={{ width: "50%" }}>
              <div className="  no-padding mr" style={{ width: "50%" }}>
                <div className="card  h-100 ">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Transacting Referrals</span>
                        <h1 className="display-5 bigi23">250</h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <CreditCardIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">View</div>
                  </div>
                </div>
                <div className="bgRed col-sm-12"></div>
              </div>
              <div className="  no-padding " style={{ width: "50%" }}>
                <div className="card  h-100  radius">
                  <div
                    className="card-body refCards23 "
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="displayFlex">
                      <div>
                        <span className="size16">Activated Accounts</span>
                        <h1 className="display-5 bigi23">140</h1>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        <div className="rotate circle23" style={{}}>
                          <span className="ct">
                            <VerifiedUserIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="size10">View</div>
                  </div>
                </div>
                <div className="bgDarkBlue col-sm-12"></div>
              </div>
            </div>
          </div>
        </div>
        {/* End Hidden */}
        <div className=" flexerBody2  ">
          <div className="col-sm-6">
            <Card
              body
              className=" card-font-size heightB4"
              style={{ height: "auto" }}
            >
              <CardTitle className="displayFlex bottomLine padd">
                <span className="blak">
                  <AssignmentIcon className="mr-2" />
                  Recent Campaigns{" "}
                </span>
                <div className="spacer"></div>
                <div
                  className="header2"
                  style={{ width: "150px" }}
                  onClick={() => {
                    props.goTo("/marketer/add-campiagns");
                  }}
                >
                  Add Campaign
                </div>
              </CardTitle>
              <CardText style={{ overflow: "" }}>
                <table className="table table-borderless ">
                  <thead className="campaignsTh">
                    <tr>
                      <th style={{ textAlign: "left" }}>
                        <span className="pr-2 card-font-size ">
                          <RadioButtonUncheckedIcon className="card-font-size " />
                        </span>
                        Campaign
                      </th>
                      <th>Added</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody className="campaignsBody">
                    {!props.loading ? (
                      props.campaigns.map((value) => {
                        c++;
                        if (c <= 4) {
                          return (
                            <tr>
                              <td>
                                <span className="pr-2 card-font-size ">
                                  <RadioButtonUncheckedIcon className="card-font-size " />
                                </span>
                                <span>{value.title}</span>
                              </td>
                              <td>
                                <span>
                                  {moment(value.createdOn).format(
                                    "MMM DD, YYYY"
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {value.campaignTypeId === 1
                                    ? "Referral Campaign"
                                    : "Instant Campaign"}
                                </span>
                              </td>
                            </tr>
                          );
                        } else return "";
                      })
                    ) : !props.getCampaignErr ? (
                      <div>Loading...</div>
                    ) : (
                      <div>{props.getCampaignErr}</div>
                    )}
                  </tbody>
                </table>
              </CardText>
            </Card>
          </div>
          {/* <div className="p-2"></div> */}
          <div className="col-sm-6">
            <Card body className="card card-font-size heightB4 card-height">
              <CardTitle className="displayFlex bottomLine padd">
                <span className="blak">
                  {/* {JSON.stringify(props.subAgents)} */}
                  <GroupIcon className="mr-2" />
                  Sub Agent
                </span>
                <div className="spacer"></div>
                <div
                  className="header2"
                  onClick={() => {
                    props.goTo("sub-agent");
                  }}
                >
                  View All
                </div>
              </CardTitle>
              <CardText className=" campaignsBody " style={{ color: "#000" }}>
                <Grid>
                  <List dense>
                    {!props.agentloading ? (
                      props?.subAgents?.map((value) => {
                        const labelId = `https://res.cloudinary.com/mentor-africa-project/image/upload/v1603502333/Pngtree_user_vector_avatar_4830521.png`;
                        return (
                          // button
                          <ListItem key={value.agentId}>
                            {/* <ListItem key={value.agentId} button> */}
                            <span
                              style={{ paddingRight: "15px" }}
                            >{`${++count}`}</span>

                            <ListItemText
                              id={labelId}
                              primary={`${value.name}`}
                            />
                            <ListItemSecondaryAction>
                              {value.referralCode}
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })
                    ) : !props.getSubAgentErr ? (
                      <div>Loading...</div>
                    ) : (
                      <div> {props.getSubAgentErr}</div>
                    )}
                  </List>
                </Grid>
              </CardText>
            </Card>
          </div>
        </div>
        <div
          className="flexerBody2 d-none"
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          <div className="col-sm-8 ">
            <Card body className="card card-font-size card-height">
              <CardTitle className="displayFlex bottomLine padd">
                <span className="blak">
                  <MultilineChartIcon className="mr-2" />
                  Statistics
                </span>
              </CardTitle>
              <CardText>
                <div className="statbody ">
                  <div className="statPieChat col-sm-6">
                    <div className="lineChat col-sm-4">Coming Soon</div>
                    <div className="col-sm-4"> {/* <Stat1 /> */}</div>
                    <div className="col-sm-4">{/* <Stat2 /> */}</div>
                  </div>
                  {/* <div className="lineChat col-sm-4">Coming Soon</div> */}
                </div>
              </CardText>
            </Card>
          </div>
          <div className="col-sm-4">
            <Card body className="card card-font-size card-height">
              <CardTitle className="displayFlex bottomLine padd">
                <span className="blak">
                  <GroupIcon className="mr-2" />
                  Sub Agent
                </span>

                <div className="spacer"></div>
                <div className="header2">View All</div>
              </CardTitle>
              <CardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div className="lineChat col-sm-4">Coming Soon</div>
                  <div
                    style={{
                      width: "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "center",
                    }}
                    className="d-none"
                  >
                    <div className="subaccountsubheading">Most Performing</div>
                    <img
                      alt="img"
                      className="subaccntImg"
                      style={{ borderRadius: "50%" }}
                      height="100"
                      width="100"
                      src="http://abs2018.lbsafricaclub.org/wp-content/uploads/2018/04/Screen-Shot-2018-04-25-at-23.21.59-262x272.png"
                    />
                    <div className="subaccountname">Banky W</div>
                    <div>
                      <span className="subaccountsubheading">
                        Number of link clicks: 2,500
                      </span>
                    </div>
                  </div>
                </div>
              </CardText>
            </Card>
          </div>
        </div>
        <Switch>
          <Route path="/campians" component={Campains}></Route>
          <Route path="/users"></Route>
          <Route path="/"></Route>
        </Switch>
      </div>
    </>
  );
};

// withRouter

import React from "react";
import AcquisitionDetails from "../acquisition/AcquisitionCampaignSummary";
import BothCampaignsSummary from "../BothCampaignsSummary";
import TransactionCamapaignSummary from "../transactional/TransactionCamapaignSummary";

const ConfirmAllCampaignDetails = ({
  state,
  //addAquisitionCampaign,
  prevStep,
  submitCampaignDetails,
  handleFormData,
  goback,
  transactionValues,
  // onCreateTransaction,
}) => {
  const onSubmit = () => {
    // if (parseInt(state?.campaignType) === 1) {
    //   addAquisitionCampaign();
    // }
    // if (parseInt(state?.campaignType) === 2) {
    //   onCreateTransaction();
    // }
    // if (parseInt(state?.campaignType) === 3) {
    //   addAquisitionCampaign();
    //   onCreateTransaction();
    // }

    submitCampaignDetails();
  };
  return (
    <div className="col-12">
      {parseInt(state?.campaignType) === 1 && (
        <AcquisitionDetails handleFormData={handleFormData} state={state} />
      )}
      {parseInt(state?.campaignType) === 2 && (
        <TransactionCamapaignSummary
          transactionValues={transactionValues}
          handleFormData={handleFormData}
          state={state}
        />
      )}

      {parseInt(state?.campaignType) === 3 && (
        <>
          <BothCampaignsSummary
            transactionValues={transactionValues}
            handleFormData={handleFormData}
            state={state}
          />
        </>
      )}

      <span className="wizard_footer d-flex justify-content-between align-items-center mx-2">
        <small
          className="wizard_footer_back ml-1 py-2 text-info h6"
          onClick={prevStep}
        >
          Back
        </small>

        <div className="mt-3">
          <button
            className="btn btn-outline-info py-2 px-3 mr-3"
            onClick={goback}
          >
            Cancel
          </button>
          <button className="btn btn-info py-2" onClick={onSubmit}>
            Submit
          </button>
        </div>
      </span>
    </div>
  );
};

export default ConfirmAllCampaignDetails;

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const ADD_BUSS_RULE = "ADD_BUSS_RULE";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const CAMPAIGN_LOADING = "CAMPAIGN_LOADING";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL";
export const SET_CAMPAIGN_TYPE = "SET_CAMPAIGN_TYPE";
export const SET_CAMPAIGN_STATUS = "SET_CAMPAIGN_STATUS";
export const SET_CAMPAIGN_TRANSACTION_TYPES = "SET_CAMPAIGN_TRANSACTION_TYPES";
export const SET_REWARDABLE_BANDS = "SET_REWARDABLE_BANDS";
//

import React from "react";
import { Form, FormGroup, Input } from "reactstrap";

const AcquisitionCampaignDetails = ({ state }) => {
  return (
    <div>
      <div className="py-4">
        <div className="acquisitionCampaign col-6 pt-4 pb-2">
          <p className="h6">Acquisition Campaign</p>
          {parseInt(state?.campaignType) === 3 ? "Not available" :<small className="h6 pb-2 pt-4">Reward mechanics</small>}
        </div>
        <Form>
          <div className="col-6">
            
            <FormGroup className="">
            {state.refererChecked &&
              <div className="form-check">

                <input
                  className="acq_checkbox form-check-input h6"
                  type="checkbox"
                  name="refererChecked"
                  checked={state.refererChecked}
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Reward Referrer
                </label>
              </div>}

              {state.refererChecked &&
                <>
                  {" "}
                  <Input
                    className="textField"
                    type="number"
                    name="referrer"
                    value={state.referrer}
                    disabled
                  />
                  <div className=""></div>
                </>
              }
            </FormGroup>

            <FormGroup className="">
            {state.referredChecked &&<div className="form-check">
                <input
                  className="acq_checkbox form-check-input h6"
                  type="checkbox"
                  name="referredChecked"
                  checked={state.referredChecked}
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Reward Referred User
                </label>
              </div>}

              {state.referredChecked &&
                <>
                  {" "}
                  <Input
                    className="textField"
                    type="number"
                    name="referred"
                    value={state.referred}
                    disabled
                  />
                  <div className=""></div>
                </>
              }
            </FormGroup>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AcquisitionCampaignDetails;

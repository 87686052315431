import React, { Component } from "react";
import { connect } from "react-redux";

import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";
import { NotificationManager } from "react-notifications";
// Dialogue Hanlder

import { getPermissions } from "../../../../../redux/user/userActions";

import SearchInput, { createFilter } from "react-search-input";

import HTTP from "../../../../../redux/http-trips";

import { Form, FormGroup, Label, Input, Collapse } from "reactstrap";
import { getClients, LogoutNow } from "../../../../../dependency/UtilityFunctions";
import SimpleReactValidator from "simple-react-validator";
import "./addcampain.css";

const KEYS_TO_FILTERS = ["label", "value"];
class EditRole extends Component {
  constructor() {
    super();
    this.state = {
      sideDrawerOpen: true,
      selectedDate: "",
      clients: getClients(),
      name: "",
      permissions: [],
      permissionsValue: [],
      allPermisions: [],
      searchTerm: "",
      RewardTypeId: null,
      rewardTypes: [],
      permissionLabel: "",
      loading: false,
      id: "",
      selectedOption: null,
      open: false,
      agreed: false,
      isOpen: true,
    };
    this.validator = new SimpleReactValidator();
  }

  async componentDidMount() {
    const state = this.props.location.state;
    if (!state) {
      this.props.history.push("roles");
      return;
    }

    let { name, id } = state.role;

    this.setState({
      name,
      id,
    });

    await this.props.getPermissions();
    await this.getMyPermissions(id);
  }

  getMyPermissions = (id) => {
    let url = `/api/RoleMgmt/Get/${id}`;
    HTTP.get(url)
      .then((res) => {
        let { permissions } = res.data.payload;

        this.setState({
          permissions: permissions.map((v) => ({
            label: v.description,
            value: v.key,
          })),
          loading: false,
        });
      })
      .catch((err) => {
        if (err.response) {
          let { data } = err.response;
          this.setState({
            getPermissionsErr: data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,",
          });
          this.createNotification(
            "error",
            data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,"
          );
        }
      });
  };
  toggleComfirm = (e) => {
    this.setState({
      open: !this.state.open,
    });
  };
  searchUpdated = (term) => {
    this.setState({ searchTerm: term });
  };
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangePermissions = (e) => {
    let perms = [];
    // this.props.permissions.filter((v) => v.value == e.target.value);
    perms =
      this.state.permissions.filter((v) => `${v.value}` === `${e.target.value}`)
        .length > 0
        ? this.state.permissions.filter(
            (v) => `${v.value}` !== `${e.target.value}`
          )
        : [
            ...this.state.permissions,
            ...this.props.permissions.filter(
              (v) => `${v.value}` === `${e.target.value}`
            ),
          ];

    this.setState(
      {
        [e.target.name]: perms,
      },
      () => {}
    );
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  criteriaDelete = (id) => {
    this.setState({
      criterias: this.state.criterias.filter((criteria) => criteria.id !== id),
    });
    this.createNotification("success", "Deleted Successfully.");
  };
  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };
  updateRole = () => {
    this.toggleLoading();
    let { name, permissions, id } = this.state;

    let role = {
      name,
      permissions: permissions.map((v) => v.value),
      id,
    };

    HTTP.post("/api/RoleMgmt/update", role)
      .then((res) => {
        this.toggleLoading();
        if (res.status === 200) {
          if (res.data.hasErrors === false) {
            this.createNotification("success", `${res.data.description}: logging out to implement changes`, 2000);
            this.props.history.push({
              pathname: "roles",
              state: "",
            });
            LogoutNow();
          } else {
            this.createNotification("error", res.data.description);
          }
        } else {
          this.createNotification(
            "error",
            res.data.title
              ? res.data.title
              : res.data.description
              ? res.data.description
              : "An error ocurred, please try again,"
          );
        }
      })
      .catch((err) => {
        this.toggleLoading();
        if (err.response) {
          let { data } = err.response;
          this.createNotification(
            "error",
            data.title
              ? data.title
              : data.description
              ? data.description
              : "An error ocurred, please try again,"
          );
        }
      });
  };
  onSubmit = async (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      this.validator.hideMessages();

      this.updateRole();
    } else {
      this.createNotification("error", "Kindly supply all fields.");

      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
    }
  };
  render() {
    const filteredClients = this.props.permissions.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );

    return (
      <>
        <div className="sidebarContentContainer">
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          <main
            className="container-fluid px-5 "
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7">
                <div style={{ display: "flex", flexDirection: "row" }}></div>
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    marginRight: "60%",
                  }}
                  className=""
                >
                  Edit Role
                </h4>
              </div>
              <div className="col-sm-12">
                <div className="column">
                  <div className="col-sm-12 mx-0 py-0">
                    <div className="card my-4 singleHeight">
                      <div className="card-header headerWHite"> Details</div>
                      <div className="card-body">
                        <Form>
                          <FormGroup className="row card-font-size">
                            <Label
                              for="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Name
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Input campaign name"
                              value={this.state.name}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "name",
                                this.state.name,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div className={"w-100"}>
                    <div className="col-sm-12 mx-0 pt-0">
                      <div className="card" style={{ height: "auto " }}>
                        <div className="card-header headerWHite">
                          Assign permissions
                        </div>
                        <div className="card-body mx-5 my-3 assign-client">
                          <FormGroup className="row pl-4 py-4">
                            <Collapse
                              isOpen={this.state.isOpen}
                              className="col-sm-12 row"
                              style={{
                                color: "#3C3C3C",
                                fontSize: "12px",
                                fontWeight: "normal",
                              }}
                            >
                              <SearchInput
                                className="search-input col-sm-12 row w-100 h-25 searchBar "
                                onChange={this.searchUpdated}
                              />

                              <div
                                className="col-sm-12 row"
                                style={{
                                  color: "#3C3C3C",
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                }}
                              >
                                {filteredClients.map((value, key) => {
                                  return (
                                    <div
                                      key={key}
                                      className="custom-control py-2 custom-checkbox col-sm-6"
                                      style={{ fontSize: "10px" }}
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={value.value}
                                        name="permissions"
                                        value={value.value}
                                        onChange={this.onChangePermissions}
                                        checked={
                                          this.state.permissions.filter(
                                            (v) => v.value === value.value
                                          ).length === 1
                                            ? true
                                            : false
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={value.value}
                                      >
                                        {value.label}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </Collapse>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end">
                        <button
                          className="btn button2 my-4 w-25"
                          onClick={() => {
                            this.props.history.push("roles");
                          }}
                        >
                          {"Back"}
                        </button>
                        <button
                          className="btn button2 my-4 w-25"
                          onClick={this.onSubmit}
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? "Loading..." : "Update"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.user.permissions.map((p) => ({
    label: p.description,
    value: p.id,
  })),
});
const mapDispatchToProps = () => ({
  getPermissions,
});

export default connect(mapStateToProps, mapDispatchToProps())(EditRole);

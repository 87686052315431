import { GET_CLIENTS_LIST, CLIENTS_LIST_LOADING, ADD_CLIENTS } from "../types";
import { getAccessToken } from "../../../dependency/UtilityFunctions";

const initislState = {
  clients: [],
  loading: false,
  totalRecordCount: 0,
  access_token: getAccessToken(),
};
export default function (state = initislState, action) {
  switch (action.type) {
    case GET_CLIENTS_LIST:
      //

      return {
        ...state,
        clients: action.payload.payload.returnObjects,
        totalRecordCount: action.payload.payload.totalRecordCount,
        loading: false,
      };
    case ADD_CLIENTS:
      return {
        ...state,
        // campaigns: [action.payload, ...state.campaigns]
      };
    case CLIENTS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

import {
  GET_AGENTS,
  ADD_AGENT,
  USER_LOADING,
  UPDATE_AGENT,
  PERMISSION_LOADING,
  GET_PERMISSION,
  GET_AGENTS_FAIL,
  GET_SUB_AGENTS,
  GET_SUB_AGENTS_FAIL,
} from "./types";
import HTTP from "../http-trips";
import {
  getActiveClient,
  tokenConfig,
} from "../../dependency/UtilityFunctions";
export const getAgents = (id, pageindex = 1, pagesize = 10) => (
  dispatch,
  getState
) => {
  let activeClient = getActiveClient();
  dispatch(setAgentsLoading());
  return HTTP.get(
    `api/Agents/GetAgents??pageindex=${pageindex}&pagesize=${pagesize}&ClientId=${
      id ? id : activeClient.id
    }`,
    tokenConfig(getState)
  )
    .then((res) => {
      dispatch(setData(res));
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_AGENTS_FAIL,
          payload: err.response,
        });
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const getSubAgents = (id) => (dispatch, getState) => {
  let activeClient = getActiveClient();
  dispatch(setAgentsLoading());
  return HTTP.get(
    `api/Agents/GetAgentSubAccounts?AgentId=${id ? id : activeClient.id}`,
    tokenConfig(getState)
  )
    .then((res) => {
      dispatch(setSubAgentData(res));
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_SUB_AGENTS_FAIL,
          payload: err.response,
        });
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

// Get Permission
export const getPermissions = (id) => (dispatch, getState) => {
  dispatch(setPermissionLoading());
  return HTTP.get(`api/UsersMgmt/GetPermissions/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch(setPermissionData(res));
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

// Get Permission
export const getClientCampaign = (id) => (dispatch, getState) => {
  // dispatch(setPermissionLoading());
  return HTTP.get(
    `api/campaign/GetClientCampaigns?pageindex=1&pagesize=10&ClientId=${id}&typeId=0 `,
    tokenConfig(getState)
  )
    .then((res) => {
      // dispatch({
      //   type: GET_USER_CLIENTS,
      //   payload: res.data
      // });
      return res;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const addAgent = (newAgent) => (dispatch, getState) => {
  return HTTP.post("api/Agents/AddAgent", newAgent, tokenConfig(getState))
    .then((res) => {
      newAgent.campaignId = res.data.returnObjects;

      dispatch({
        type: ADD_AGENT,
        payload: newAgent,
      });
      return res;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const addSubAgent = (id, ref, newAgent) => (dispatch, getState) => {
  return HTTP.post(
    `api/agents/getagentsubaccount?agentId=${id}&SubAccountReferralCode=${ref}`,
    newAgent,
    tokenConfig(getState)
  )
    .then((res) => {
      newAgent.campaignId = res.data.returnObjects;

      return res;
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }

      let error = JSON.stringify(err);
      return error;
    });
};

export const updateUser = (update) => (dispatch, getState) => {
  return HTTP.post("api/UsersMgmt/MapUserInfo", update, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_AGENT,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {});
};

export const setData = (res) => {
  return {
    type: GET_AGENTS,
    payload: res.data,
  };
};
export const setSubAgentData = (res) => {
  return {
    type: GET_SUB_AGENTS,
    payload: res.data,
  };
};
export const setPermissionData = (res) => {
  return {
    type: GET_PERMISSION,
    payload: res.data,
  };
};
export const setAgentsLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const setPermissionLoading = () => {
  return {
    type: PERMISSION_LOADING,
  };
};

import React, { Component } from "react";
import { connect } from "react-redux";

import ToolBar1 from "../../Navs/ToolBar/ToolBar1";
import SideDrawer from "../../Navs/SideDrawer/SideDrawer";

import { NotificationManager } from "react-notifications";
import SearchInput, { createFilter } from "react-search-input";
// Dialogue Hanlder
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import HTTP from "../../../../../redux/http-trips";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {
  getPermissions,
  addUsers,
} from "../../../../../redux/user/userActions";
import { getClientList } from "../../../../../redux/client/clientActions";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Collapse } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

import "./newMarketer.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const KEYS_TO_FILTERS = ["value.id", "name", "description"];
class AddCampains extends Component {
  constructor() {
    super();
    this.state = {
      sideDrawerOpen: true,
      selectedDate: "",
      criteria: "",
      searchTerm: "",
      roles: [],
      tval: false,
      roleId: null,
      permission: null,
      clientId: null,
      userName: "",
      email: "",
      lastName: "",
      givenName: "",
      middleName: "",
      extraPermissions: [],
      phoneNumber: "",
      role: "SUPER_ADMIN",
      clientIds: [],
      clientNames: [],
      permLoading: false,
      loading: false,
      selectedOption: null,
      open: false,
      isOpen: false,
      agreed: false,
      hasErrors: false,
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    this.props.getClientList().then((res) => {
      this.getRoles(1, 100);
    });
  }
  getRoles = (selectedPage = 1, pageSize = 10) => {
    HTTP.get(
      `/api/RoleMgmt/Get?Keyword=&PageIndex=${selectedPage}&PageTotal=${pageSize}`
    )
      .then((res) => {
        if (res.status === 200) {
          let { payload, totalCount } = res.data;
          this.setState({
            roles: payload,
            totalRecordCount: totalCount,
            loading: false,
            rolesErr: "",
          });
        }
      })
      .catch((err) => {
        this.setState({
          rolesErr: "An Error Occured",
          loading: false,
        });
      });
  };
  toggleComfirm = (e) => {
    this.setState({
      open: !this.state.open,
    });
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  togglePermLoading = () => {
    this.setState({
      permLoading: !this.state.permLoading,
    });
  };

  searchUpdated = (term) => {
    this.setState({ searchTerm: term });
  };
  send = (agreed) => {
    if (agreed) {
      this.toggleLoading();
      let {
        startDate,
        endDate,
        campainTitle,
        campainDescription,

        budgetLimit,
      } = this.state;
      // alert(resp);
      let newCampaign = {
        title: campainTitle,
        description: campainDescription,
        campaignTypeId: parseInt(this.ctid),
        statusId: parseInt(this.cid),
        modifiedBy: null,
        imageUrl: "http://kdbkbk.img",
        dateOfModification: null,
        startDate: startDate,
        budget: parseInt(budgetLimit),
        endDate: endDate,
        ClientId: "d8760c41-b58b-4fb1-ab5c-a2b9088ac296",
        // clientId: "193A9488-AD75-41D6-A3E0-DB3F10B6468F",
        dateOfCreation: new Date(),
        CampaignCategoryId: 2,
      };
      this.props.addCampaign(newCampaign).then((res) => {
        this.toggleLoading();
        let { status, data } = res;
        if (res) {
          if (status === 200) {
            if (data.payload.successful === true) {
              this.createNotification("success", data.payload.message);
              this.setState({
                selectedDate: "",
                startDate: "",
                endDate: "",
                criteriaAmount: "",
                campainTitle: "",
                campainDescription: "",
                campainBarner: "",
                campaignType: "",
                campaignBillsPayment: "",
                rewardAmount: "",
                rewardStyle: "",
                budgetLimit: "",
                productClient: "",
                completionStatus: "",
              });
              // } else {
              //   this.createNotification("error", data.message);
            }
          } else if (status === 400) {
            if (data.payload.message) {
              this.createNotification("error", data.payload.message);
            } else {
              this.createNotification("error", "Bad Request");
            }
          } else {
            this.createNotification("error", data.message);
          }
        } else {
          this.createNotification(
            "error",
            "An Error Occourd, Please, try again"
          );
        }
      });
    }
  };
  handleAgreed = () => {
    this.toggleComfirm();
    this.setState(
      {
        agreed: true,
      },
      () => {
        this.send(true);
      }
    );
  };
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onChange = (e) => {
    const result = this.state.roles.filter(
      (role) => role.Name === e.target.value
    );
    this.setState(
      {
        [e.target.name]: e.target.value,
        tval: result.length > 0 ? true : false,
        roleId: result.length > 0 ? result[0].roleId : null,
      },
      () => {
        this.clientIdSetter();
      }
    );
  };
  filterClientName = () => {
    this.props.clients.filter((c) => c.clientId === this.state.clientId);
  };
  clientIdSetter = () => {
    let r = this.props.clients.filter(
      (c) => c.clientId === this.state.clientId
    );
    const crs = this.state.clientIds.filter(
      (cid) => cid === this.state.clientId
    );
    if (crs.length < 1) {
      if (this.state.clientId !== null) {
        this.setState(
          {
            clientIds: [...this.state.clientIds, this.state.clientId],
            clientNames: [
              ...this.state.clientNames,
              { id: this.state.clientId, name: r[0]["name"] },
            ],
          },
          () => {
            this.setState({ clientId: null });
          }
        );
      }
    } else {
      this.setState(
        {
          clientIds: this.state.clientIds.filter(
            (clientId) => clientId !== this.state.clientId
          ),
          clientNames: this.state.clientNames.filter(
            (clientName) => clientName.id !== r[0]["clientId"]
          ),
        },
        () => {}
      );
    }
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    // alert(this.state.sideDrawer);
    this.setState({ sideDrawerOpen: false });
  };

  onSubmit = (e) => {
    if (this.validator.allValid()) {
      let {
        userName,
        email,
        lastName,
        givenName,
        // middleName,
        phoneNumber,
        role,
        clientIds,
      } = this.state;
      let newUser = {
        userName: email,
        email,
        lastName,
        givenName,
        middleName: lastName,
        phoneNumber,
        role,
        clientIds,
      };
      if (newUser.lastName === "") {
        this.createNotification("error", "Last name is required");
        return;
      }
      if (newUser.role === "SUPER_ADMIN") {
        // newUser.clientIds = [];
      }

      this.toggleLoading();
      this.props.addUsers(newUser).then((res) => {
        this.toggleLoading();
        if (res.status === 200) {
          this.createNotification("success", res.data.description);
          this.setState({
            userName,
            email: "",
            lastName: "",
            givenName: "",
            middleName: "",
            phoneNumber: "",
            role: "SUPER_ADMIN",
            clientIds: [],
          });
          this.props.history.push("users");
        } else {
          this.createNotification("error", res.data.errors[0]);
        }
      });
    } else {
      this.createNotification("error", "Kindly supply all fields.");
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  createNotification = (type, msg = null) => {
    // return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(msg, "Success!", 3000);
        break;
      case "warning":
        NotificationManager.warning(msg, "Warning!", 3000);
        break;
      case "error":
        NotificationManager.error(msg, "Oops!", 3000);
        break;
      case "xxx":
        NotificationManager.error(msg, "Oops!", 5000, () => {
          alert("callback");
        });
        break;
      default:
      // alert();
      // }
    }
  };
  render() {
    const filteredClients = this.props.clients.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );

    return (
      <>
        <div className="newUserBody">
          <ToolBar1 drawerToggleClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          <main
            className="container-fluid px-5 newUserMain"
            style={{
              flex: "1 1 0%",
            }}
          >
            <div className="contentBody  px-5">
              <div className="col-sm-12 my7">
                <div style={{ display: "flex", flexDirection: "row" }}></div>
                <h4
                  style={{
                    fontWeight: "bold",
                    paddingTop: "10px",
                    marginRight: "60%",
                  }}
                  className=""
                >
                  Add New User
                </h4>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 mx-0 py-0">
                    <div className="card my-4 singleHeight">
                      <div className="card-header headerWHite"> Details</div>
                      <div className="card-body">
                        <Form>
                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Last Name
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="lastName"
                              id="lastName"
                              placeholder="Last Name"
                              value={this.state.lastName}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Last Name",
                                this.state.lastName,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              First Name
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="text"
                              name="givenName"
                              id="givenName"
                              placeholder="First Name "
                              value={this.state.givenName}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "First Name",
                                this.state.givenName,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              htmlFor="description"
                              className="col-sm-4 labell"
                            >
                              Email
                            </Label>
                            <Input
                              className="col-sm-7  textField"
                              placeholder="Email"
                              type="email"
                              name="email"
                              id="description"
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              value={this.state.email}
                              autoComplete="off"
                            />
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "email",
                                this.state.email,
                                "required|email"
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleEmail"
                              className="col-sm-4 labell "
                            >
                              Phone number
                            </Label>

                            <Input
                              className="col-sm-7 textField"
                              type="tel"
                              name="phoneNumber"
                              id="phoneNumber"
                              placeholder="+234"
                              value={this.state.phoneNumber}
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              autoComplete="off"
                              maxLength={11}
                            />
                            {/* </div> */}
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "phoneNumber",
                                this.state.phoneNumber,
                                "required|phone|min:7"
                              )}
                            </div>
                            {/* {JSON.stringify(this.props.campaignType)} */}
                          </FormGroup>
                          <FormGroup className="row card-font-size">
                            <Label
                              htmlFor="exampleSelect"
                              className="col-sm-4 labell"
                            >
                              Role
                            </Label>
                            <Input
                              type="select"
                              name="role"
                              id="campainType"
                              placeholder="Role "
                              className="col-sm-7 textField"
                              onChange={this.onChange}
                              onKeyPress={this.onKeyPress}
                              value={this.state.role}
                              autoComplete="off"
                            >
                              {this.state.roles.map((value, key) => {
                                return <option key={key}>{value.name}</option>;
                              })}
                            </Input>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-7 text-danger p-0 f8">
                              {this.validator.message(
                                "Email",
                                this.state.role,
                                "required"
                              )}
                            </div>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>

                  <div className={"w-100"}>
                    <div className="col-sm-12 mx-0 pt-0">
                      <div className="card" style={{ height: "auto " }}>
                        <div className="card-header headerWHite">
                          Assign Clients
                        </div>
                        <div className="card-body mx-5 my-3 assign-client">
                          <FormGroup className="row pl-4">
                            <div className="row col-sm-12">
                              <div className="col-sm-8 py-2">
                                {this.state.clientNames.map((c, key) => (
                                  <span
                                    key={key}
                                    className="badge  px-3 py-2 mx-2 my-2 agenlist"
                                  >
                                    {c.name}
                                  </span>
                                ))}
                              </div>
                              <div className="col-sm-4 row h-25">
                                <span className="badge  px-3 py-2 mx-2 my-2 agenlist">
                                  {this.state.clientIds.length}
                                </span>
                                <div
                                  style={{
                                    color: "#000",
                                    textAlign: "right",
                                    width: "60%",
                                  }}
                                  className="float-right w-60 h-50 pt-2"
                                >
                                  {this.state.isOpen ? (
                                    <ArrowDropUpIcon
                                      onClick={this.toggle}
                                      style={{
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    />
                                  ) : (
                                    <ArrowDropDownIcon
                                      onClick={this.toggle}
                                      style={{
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <Collapse
                              isOpen={this.state.isOpen}
                              className="col-sm-12 row"
                              style={{
                                color: "#3C3C3C",
                                fontSize: "12px",
                                fontWeight: "normal",
                              }}
                            >
                              <SearchInput
                                className="search-input col-sm-12 row w-100 h-25 searchBar "
                                onChange={this.searchUpdated}
                              />
                              {/* {filteredClients.map(value => {
                              return (
                                <div className="mail" key={email.id}>
                                  <div className="from">{email.user.name}</div>
                                  <div className="subject">{email.subject}</div>
                                </div>
                              );
                            })} */}
                              <div
                                className="col-sm-12 row"
                                // style={{ fontSize: "12px", color: "#000" }}
                                style={{
                                  color: "#3C3C3C",
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                }}
                              >
                                {this.props.clients.length > 0 ? (
                                  this.state.clients ? (
                                    <div>Loading...</div>
                                  ) : (
                                    filteredClients.map((value, key) => {
                                      return (
                                        <div
                                          key={key}
                                          className="custom-control py-2 custom-checkbox col-sm-6"
                                          style={{ fontSize: "10px" }}
                                        >
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={value.clientId}
                                            name="clientId"
                                            value={value.clientId}
                                            onChange={this.onChange}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={value.clientId}
                                          >
                                            {value.name}
                                          </label>
                                        </div>
                                      );
                                    })
                                  )
                                ) : (
                                  <div>Loading...</div>
                                )}
                              </div>
                            </Collapse>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-25" style={{ display: "flex" }}>
                  <button
                    className="btn button2 my-4"
                    onClick={() => {
                      this.props.history.push("users");
                    }}
                  >
                    {"Back"}
                  </button>
                  <button
                    className="btn button2 my-4"
                    onClick={this.onSubmit}
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? "Loading..." : "Save"}
                  </button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </main>
        </div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.toggleComfirm}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ color: "#000" }}>
            {"Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ color: "#000" }}
            >
              Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleComfirm} color="danger">
              No
            </Button>
            <Button
              onClick={this.handleAgreed}
              style={{ background: "#212529" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.client.clients,
  permissions: state.user.permissions,
});
const mapDispatchToProps = () => ({
  getPermissions,
  getClientList,
  addUsers,
});

export default connect(mapStateToProps, mapDispatchToProps())(AddCampains);

import React, { Component } from "react";
import ProfileDisplayHeader from "./ProfileDisplayHeader";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

export class Security extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const {
      payload,
      submitBtnClicked,
      onChangePwd,
      oldPassword,
      newPassword,
      confirmNewPassword,
      updatePwd,
    } = this.props;
    return (
      <>
        <ProfileDisplayHeader
          ProfileUserBtnText={submitBtnClicked ? "loading..." : "Update"}
          onClickAction={updatePwd}
          profileUserRole={payload?.role || "Loading..."}
          profileUserName={`${payload?.givenName || "Loading..."} ${
            payload?.lastName || ""
          }`}
          submitBtnClicked={submitBtnClicked ? false : true}
        />
        <div className="profile-main mx-4">
          <div className=" col-lg-6 col-sm-6">
            <h6 className="py-3">Change Password</h6>

            <form>
              <div className="form-group">
                <label>Old Password</label>
                <input
                  type="password"
                  name="oldPassword"
                  class="form-control"
                  onChange={onChangePwd}
                  value={oldPassword}
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label>New Password</label>

                <input
                  type="password"
                  name="newPassword"
                  class="form-control"
                  value={newPassword}
                  onChange={onChangePwd}
                  onClick={this.handleClick}
                  autoComplete="off"
                />

                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  className="pop-over-modal mt-4"
                >
                  <Typography sx={{ p: 2 }}>
                    <div class="container" role="alert">
                      <ul className="text-info mt-2">
                        The password must contain:
                        <li> at least 1 lowercase alphabetical character</li>
                        <li> at least 1 uppercase alphabetical character</li>
                        <li> at least 1 numeric character</li>
                        <li>at least 1 special character </li>
                        <li>must be 8 characters or longer </li>
                      </ul>
                    </div>
                  </Typography>
                </Popover>
              </div>
              <div className="form-group">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  name="confirmNewPassword"
                  class="form-control"
                  value={confirmNewPassword}
                  onChange={onChangePwd}
                  autoComplete="off"
                />
              </div>
            </form>
          </div>{" "}
        </div>
      </>
    );
  }
}

export default Security;

import axios from "axios";
import { getAccessToken, LogoutNow } from "../dependency/UtilityFunctions";

const localConfig = JSON.parse(window.sessionStorage.getItem('config'));
//import { NotificationManager } from "react-notifications";
const instance = axios.create({
 baseURL: `${localConfig?.referralBaseUrl}/`
  // baseURL:  `${ config?.referralBaseUrl? config?.referralBaseUrl: process.env.REACT_APP_REFERRAL_BASEURL}/`
  // baseURL: `${process.env.REACT_APP_REFERRAL_BASEURL || process.env.BASE_URL}/`,
  // baseURL: "https://referral.walurecapital.com/",
  // baseURL: "https://referral.iposbi.com/",
});

instance.defaults.headers.common["Content-Type"] = "application/json";

instance.interceptors.request.use(
  (request) => {
    let access_token = getAccessToken();
    request.headers["Authorization"] = "Bearer " + access_token;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      LogoutNow();
    } else if (403 === error.response.status) {
      // createNotification("error", "Forbidden request");

      // window.history.back();
    } else return Promise.reject(error);
  }
);

// const createNotification = (type, msg = null) => {
//   // return () => {
//   switch (type) {
//     case "info":
//       NotificationManager.info("Info message");
//       break;
//     case "success":
//       NotificationManager.success(msg, "Success!", 3000);
//       break;
//     case "warning":
//       NotificationManager.warning(msg, "Warning!", 3000);
//       break;
//     case "error":
//       NotificationManager.error(msg, "Oops!", 3000);
//       break;
//     case "xxx":
//       NotificationManager.error(msg, "Oops!", 5000, () => {
//         alert("callback");
//       });
//       break;
//     default:
//     alert();

//   }
// };
export default instance;

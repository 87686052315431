import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from "../types";
import { getAccessToken, resetLs } from "../../../dependency/UtilityFunctions";
import { PersistData } from "../../../dependency/UtilityFunctions";

const initialState = {
  isAuthenticated: null,
  isLoading: false,
  user: null,
  user_clients: [],
  userData: [],
  access_token: getAccessToken(),  
  // passport_token: localStorage.getItem("passport_token")
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      let raw = action.payload.payload;
      let clients = action.payload.payload.clients;
      if (clients.length > 0) {
        let temp = clients.filter((v) => v.name === "Quickteller");
        var activeClient = temp.length > 0 ? temp[0] : clients[0];
      }
      let data2save = {
        isLoggedIn: true,
        refreshToken: action.payload.payload.refreshToken,
        permission: action.payload.payload.permission,
        clients: action.payload.payload.clients,
        access_token: action.payload.payload.access_token,
        major: action.payload.payload.role,
        activeClient,
      };
      PersistData("LoggedInState", data2save);
      PersistData("loggedInUserDetails", raw);

      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        access_token: action.payload.payload.access_token,
        passport_token: action.payload.payload.refreshToken,
        user_clients: action.payload.payload.clients,
        userData: data2save,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      resetLs();
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    default:
      return state;
  }
}

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./SideDrawer.css";

//import Dashboard from "@material-ui/icons/Dashboard";
import AppsIcon from "@material-ui/icons/Apps";

import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import StoreIcon from "@material-ui/icons/Store";
import CardMembership from "@material-ui/icons/CardMembership";
import { Link } from "react-router-dom";
import { LogoutNow } from "../../../../../../src/dependency/UtilityFunctions";
import Can from "../../../../../config/Can"; //src/config/Can.js
import { People } from "@material-ui/icons";
function SideDrawer(props) {
  let [activeB, setActive] = useState("");

  activeB = localStorage.getItem("activeB");

  let drawerClasses = ["side-drawer"];
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  function handleLogOut() {
    LogoutNow();
  }
  const { history } = props;
  return (
    <>
      <nav className={drawerClasses}>
        <div className="drawerHeader ml-4">
          <span>Switch Referral</span>

          <span className="toggleNavBar">
            <MenuIcon />
          </span>
        </div>
        <ul>
          <Can I="view" a="MarketerDashboard">
            {() => (
              <li
                className={
                  activeB === "dashboard" || activeB === "" ? "active" : ""
                }
                onClick={(e) => {
                  localStorage.setItem("activeB", "dashboard");
                  history.push("/marketer/dashboard");
                }}
              >
                <AppsIcon className="drawerIcons" />

                <Link
                  onClick={(e) => {
                    setActive("dashboard");
                  }}
                  to="/marketer/dashboard"
                >
                  Dashboard
                </Link>
              </li>
            )}
          </Can>
          {/* <Can I="view" a="AdminDashboard">
            {() => (
              <li
                className={
                  activeB === "admin-dashboard" || activeB === ""
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  localStorage.setItem("activeB", "admin-dashboard");
                  history.push("/marketer/admin-dashboard");
                }}
              >
                <Dashboard className="drawerIcons" />

                <Link
                  onClick={(e) => {
                    setActive("admin-dashboard");
                  }}
                  to="/marketer/admin-dashboard"
                >
                  Dashboard (A)
                </Link>
              </li>
            )}
          </Can> */}
          <Can I="manage" a="Campains">
            {() => (
              <li
                className={activeB === "campians" ? "active" : ""}
                onClick={(e) => {
                  localStorage.setItem("activeB", "campians");
                  history.push("/marketer/campaigns");
                  e.preventDefault();
                  setActive("campians");
                }}
              >
                <BusinessCenterIcon className="drawerIcons" />

                <Link to="campiagns">Campaigns</Link>
              </li>
            )}
          </Can>
          <Can I="manage" a="Role">
            {() => (
              <li
                className={activeB === "roles" ? "active" : ""}
                onClick={(e) => {
                  localStorage.setItem("activeB", "roles");
                  history.push("/marketer/roles");
                  e.preventDefault();
                  setActive("roles");
                }}
              >
                <AssignmentTurnedInIcon className="drawerIcons" />
                <Link to="roles">Role</Link>
              </li>
            )}
          </Can>
          <Can I="manage" a="Users">
            {() => (
              <li
                className={activeB === "users" ? "active" : ""}
                onClick={(e) => {
                  localStorage.setItem("activeB", "users");
                  history.push("users");
                  e.preventDefault();
                  setActive("users");
                }}
              >
                <AccountCircleIcon className="drawerIcons" />
                <Link to="users">Users</Link>
              </li>
            )}
          </Can>
         
          <Can I="manage" a="Agent">
            {() => (
              <li
                className={activeB === "agent" ? "active" : ""}
                onClick={(e) => {
                  localStorage.setItem("activeB", "agent");
                  e.preventDefault();
                  history.push("/marketer/agent");
                  setActive("agent");
                }}
              >
                <StoreIcon className="drawerIcons" />

                <Link to="sub-account">Agents</Link>
              </li>
            )}
          </Can>
          
          <Can I="manage" a="Audit">
            {() => (
              <li
                className={activeB === "card" ? "active" : ""}
                onClick={(e) => {
                  localStorage.setItem("activeB", "card");
                  e.preventDefault();
                  history.push("/marketer/card");
                  setActive("card");
                }}
              >
                <CardMembership className="drawerIcons" />

                <Link to="sub-account">Card</Link>
              </li>
            )}
          </Can>
          <Can I="manage" a="Audit">
            {() => (
              <li
                className={activeB === "audit" ? "active" : ""}
                onClick={(e) => {
                  localStorage.setItem("activeB", "audit");
                  e.preventDefault();
                  history.push("/marketer/audit");
                  setActive("audit");
                }}
              >
                <StoreIcon className="drawerIcons" />

                <Link to="sub-account">Audit</Link>
              </li>
            )}
          </Can>

          <Can I="manage" a="Users">
            {() => (
              <li
                className={activeB === "participants" ? "active" : ""}
                onClick={(e) => {
                  localStorage.setItem("activeB", "participants");
                  e.preventDefault();
                  history.push("/marketer/participants");
                  props.goBackParticipants && props.goBackParticipants();
                  setActive("participants");
                }}
              >
                <People className="drawerIcons" />

                <Link to="participants">Participants</Link>
              </li>
            )}
          </Can>

        </ul>
        <div className="footerNavs">
          <hr />
          <ul>
            <li
              onClick={(e) => {
                e.preventDefault();
                setActive("logout");
                handleLogOut();
              }}
            >
              <PowerSettingsNewIcon className="drawerIcons" />
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default withRouter(SideDrawer);

import React, { Component } from "react";
import peopleIconBlue from "../images/people-blue.svg";
import person from "../images/person.svg";
import backBtn from "../images/backSvg.svg";
import statusCircle from "../images/statusCircle.svg";
import naira from "../images/naira.svg";
import SlidersHorizontal from "../images/SlidersHorizontal.svg";
import Loader from "react-loader-spinner";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";

export class ViewParticipant extends Component {
  state = {
    dropdownOpen: false,
    status: "",
  };
  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

  handleClick = (value) => {
    this.setState({
      status: value,
    });
  };

  cancelStatus = () => {
    this.setState({
      status: "",
    });
  };

  render() {
    let filteredParticipants = [];

    switch (this.state.status) {
      case "Active":
        filteredParticipants = this.props?.participant?.filter(data => true === data.isActivated);
        break;
      case  "Active & transacted":
        filteredParticipants = this.props?.participant?.filter(data => (data.hasTransacted && data.isActivated) === true);
        break;
      case "Transacted":
        filteredParticipants = this.props?.participant?.filter(data => true === data.hasTransacted);
          break;
      case "Inactive":
        filteredParticipants = this.props?.participant?.filter(data => (data.hasTransacted || data.isActivated || (data.hasTransacted && data.isActivated)) === false);
        break;
      default:
        filteredParticipants = this.props?.participant;
        break;
    }

    const {firstName, lastName, email, totalEarnings, totalReferred} = this.props?.participantAnalytics;

    return (
      <main className="container-fluid px-5">
        <div className="contentBody">
          <div className="col-sm-12 my7 px-4">
            <div
              className="participant_header"
              onClick={() => this.props.goBack()}
            >
              <img
                src={backBtn}
                alt="icon"
                className="participants__referal--goBack"
              />
            </div>

            <div className="participants__referal--cards  d-flex justify-content-between">
              <div className="column-first participant-card">
                <div className="participants__referal--icon-container d-flex justify-content-between">
                  <img
                    src={person}
                    alt="icon"
                    className="participants__referal--icon"
                  />

              {/* <span className="participant__status px-1" style={{border: "1.5px solid #74C965"}}>
                  <span>
                    <img
                      src={statusCircle}
                      alt="icon"
                      height="7"
                      width="7"
                    />
                  </span>
                  <span className="ml-1 pt-1">Active</span>
                </span> */}
                </div>

                <h6 className="participants__referal--name pt-1">
                  {firstName || "_ _"} {lastName || "_ _"}
                </h6> 
                <h6 className="participants__referal--nickName">
                  {email ||  "_ _"}
                </h6>
              </div>

              <div className="column">
                {" "}
                <div className="participants__referal--icon-container">
                  <img
                    src={peopleIconBlue}
                    alt="icon"
                    className="participants__referal--icon"
                  />
                </div>
                <span className="participants__referal--text-label">
                  Total Referrals
                </span>
                <h5 className="participants__referal--amount">
                  {totalReferred ||  "_ _"}
                </h5>
              </div>
              <div className="column">
                {" "}
                <div className="participants__referal--icon-container">
                  <img
                    src={naira}
                    alt="icon"
                    className="participants__referal--icon"
                  />
                </div>
                <span className="participants__referal--text-label">
                  Amount Earned
                </span>
                <h5 className="participants__referal--amount">
                  {!isNaN(totalEarnings) ? (totalEarnings)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
                </h5>
              </div>
            </div>

            <div className="participant_header mt-2 mx-1">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="participant_heading">Referred Users</h5>

                <div className="d-flex align-items-center">
                  {this.state.status && (
                    <p className="participant__status--show d-flex align-items-center justify-content-between p-2 mt-3">
                      <span>{this.state.status}</span>
                      <span
                        onClick={this.cancelStatus}
                        className="participant__status__cancel"
                      >
                        x
                      </span>
                    </p>
                  )}
                  <Dropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                    direction={this.props.direction}
                  >
                    <DropdownToggle
                      className="btn-outline btn-outline-info bg-light text-info"
                      style={{
                        borderColor: "#e8e8e8",
                        fontSize: "11px",
                        padding: "0.7em 1em",
                        marginRight: "1em",
                        color: "#848F9F",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img src={SlidersHorizontal}></img>
                      <span style={{ color: "#848F9F" }}>Filter</span>
                    </DropdownToggle>
                    <DropdownMenu {...this.props.args}>
                      <DropdownItem onClick={() => this.handleClick("Active")}>
                        Active
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.handleClick("Active & transacted")}
                      >
                        Active & transacted
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.handleClick("Transacted")}
                      >
                        Transacted
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.handleClick("Inactive")}
                      >
                        Inactive
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <input
                    type="text"
                    name="searchReferralParticipant"
                    className="participant__search"
                    placeholder="Search list"
                    onChange={(e) => this.props.onChange(e)}
                    value={this.props.searchReferralParticipant}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="mx-1 mt-4 participant__table">
                <table className="wallet_table table table-responsive{-sm|-md|-lg|-xl}">
                  <thead className="wallet_table-header">
                    <tr className="">
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Client Name</th>
                      {/* <th>Campaign Name</th> */}
                      <th>Status</th>
                      <th>Amount Earned</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredParticipants?.length > 0 && !this.props.loading ? (
                      (filteredParticipants).map((participant, index) => {
                        return (
                          <tr key={index}>
                            <td>{participant.firstName}</td>
                            <td>{participant.lastName}</td>
                            <td>{participant.email}</td>
                            <td>{participant.clientName}</td>
                            {/* <td>{participant.campaignName}</td> */}
                            <td>
                              <span className="participant__status">
                                {" "}
                                <span>
                                  {" "}
                                  <img
                                    src={statusCircle}
                                    alt="icon"
                                    height="7"
                                    width="7"
                                  />
                                </span>{" "}
                                {participant.isActivated &&
                                participant.hasTransacted ? (
                                  <span>Active & Transacted </span>
                                ) : participant.hasTransacted ? (
                                  <span>Transacted </span>
                                ) : participant.isActivated ? (
                                  <span>Active </span>
                                ) : (
                                  <span>Inactive </span>
                                )}
                              </span>
                            </td>
                            <td>{participant.rewardValue}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {this.props.loading ? (
                          <td colSpan="6">
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "12pt",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              className=" mt-5 col-sm-6  "
                            >
                              <Loader
                                type="RevolvingDot"
                                color="#00425f"
                                height={100}
                                width={100}
                              />
                            </div>
                          </td>
                        ) : (
                          <td colSpan="12">
                            <h6 className="text-center pt-3">
                              No Records Found
                            </h6>
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>{" "}
        </div>

        <div className="d-flex justify-content-end mt-3 mr-1">
        <PaginationComponent
          totalItems={this.props?.totalParticipantCount || 0}
          pageSize={Number(this.props?.pageSize)}
          onSelect={(value) => this.props.handleSelected(value, "participant--pagination")}
          activePage={this.props?.selectedPage}
          className="wallet_pag"
          name="participant--pagination"
        />
          <select
            title="page size"
            className="form-select wallet_page__size"
            onChange={(e) => this.props.changePageSize(e.target.value, e.target.name)}
            name="participant--pageSize"
            >
            {this.props.types?.map((value) => (
              <option
                value={value}
                key={value}
                name="types"
              >
                {value}
              </option>
            ))}
        </select>
      </div>
      </main>
    );
  }
}

export default ViewParticipant;
